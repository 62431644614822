<div class="content paylater__content bordered-component rounded-bottom-border">
  <strong>How Pay Later Works</strong>
  <ul>
    <li>You can get tested at the lab before you pay.</li>
    <li>You can’t pay at the lab. They just take your samples.</li>
    <li>
      You can make payment after your visit to the lab by logging into your secure online account or by calling us at
      {{ phone }}.
    </li>
    <li>Payment can be made using credit card, PayPal, pre-paid gift cards or Bitcoin.</li>
    <li>Payment is required before results are released.</li>
  </ul>
</div>
