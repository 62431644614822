<app-upsell-card
  htmlTitle="<strong>Important: Replenish</strong> Your Good Bacteria"
  question="We strongly recommend including probiotics in your prescription. Would you like to add it to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    <ng-container [ngSwitch]="treatmentType"
      ><ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Uti">
        Antibiotics, while effective in combating UTI-causing bacteria</ng-template
      ><ng-template [ngSwitchCase]="ConsultationTreatmentTypes.YeastInfection">
        Yeast medication, while effective in combating Yeast-causing bacteria</ng-template
      ><ng-template [ngSwitchCase]="ConsultationTreatmentTypes.BacterialVaginosis">
        Antibiotics, while effective in combating BV-causing bacteria</ng-template
      ><ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Metformin"> Metformin, while effective,</ng-template
      ><ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Hsv">
        HSV medication, while effective in combating Herpes</ng-template
      ><ng-template ngSwitchDefault> Antibiotics, while effective</ng-template></ng-container
    >, can unintentionally disrupt the delicate balance of beneficial bacteria in your gut. However, there's a powerful
    solution: taking extra-strength probiotics.
  </p>
  <p class="mb-4">
    <ng-container [ngSwitch]="treatmentType">
      <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Metformin">
        By incorporating these daily for 30 days, you're not just maintaining but nurturing the essential good bacteria
        your body thrives on. This is your key to supporting gut health, reducing potential side effects, and optimizing
        the benefits of your metformin treatment.
      </ng-template>
      <ng-template ngSwitchDefault>
        By incorporating these daily for 30 days, both during and after your antibiotic course, you're not just
        restoring but nurturing the essential good bacteria your body craves. This is your key to not only recovering
        but thriving with renewed vitality and well-being.
      </ng-template>
    </ng-container>
  </p>
</app-upsell-card>
