<footer class="legacy-footer has-background-primary-dark has-text-white position-relative" data-qa-id="legacy footer">
  <div class="container">
    <div class="columns">
      <div class="column">
        <p>© {{ today | date: 'y' }} HealthLabs.com All rights reserved.</p>
      </div>
      <div class="column is-flex is-flex-direction-row is-align-items-end is-justify-content-end">
        <p>Made with</p>
        <img
          class="alignnone wp-image-50 size-full"
          role="img"
          ngSrc="assets/img/ah_heart.svg"
          alt=""
          width="24"
          height="18"
        />
        <p>by the people at Analyte Health</p>
      </div>
    </div>
  </div>
</footer>
