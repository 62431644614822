<div class="consultation-request" *ngIf="!loadingService.isLoading">
  <div class="primary title">
    <div class="container">
      <div class="columns mx-0">
        <div [ngClass]="headlineClass">
          <h1 itemprop="headline" data-qa-id="Headline Section">{{ headlineCheckout }}</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container consultation-request-container" *ngIf="errorMessage">
    <div class="columns is-centered mx-0">
      <div class="column is-10">
        <div class="my-4">
          <app-error-notification classes="is-fullwidth notification-error p-3 has-text-black mb-5">
            <p class="is-inline" [innerHTML]="errorMessage"></p>
          </app-error-notification>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!errorMessage">
    <app-std-consultation-request-form></app-std-consultation-request-form>
  </ng-container>
</div>
