<div class="container">
  <div class="columns is-centered mx-0">
    <div class="column is-10">
      <div class="my-4">
        <app-error-notification classes="is-fullwidth notification-error p-3 has-text-black mb-5">
          <p class="is-inline">
            Your questionnaire has been submitted successfully. Please contact customer service at
            {{ this.contactEmail }} for the next steps.
          </p>
        </app-error-notification>
      </div>
    </div>
  </div>
</div>
