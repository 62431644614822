<div class="modal" data-qa-id="Find tests modal" [ngClass]="shouldDisplayModal ? 'is-active' : ''">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head has-background-success">
      <p class="modal-card-title has-text-weight-ultrabold has-text-white">Find Tests</p>
      <button class="delete" (click)="toggleFindTestsModal(false)" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title is-4 has-text-primary has-text-weight-ultrabold">Search</h1>
        </div>
        <div class="column is-6">
          <div class="field has-addons">
            <div class="control is-expanded">
              <input class="input" #searchTerm type="text" placeholder="Enter Term or Test Code" />
            </div>
            <div class="control">
              <a (click)="onSearch(searchTerm.value)" data-qa-id="Search btn" class="button is-success"> Search </a>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-mobile">
        <div class="column is-12">
          <h1 class="title is-4 has-text-primary has-text-weight-ultrabold">Or Choose a Test Category</h1>
        </div>
        <div class="column is-12">
          <ul>
            <li *ngFor="let category of testCategories" class="py-1">
              <a href="{{ category.link }}">{{ category.name | titlecase }}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
