import { CurrencyPipe, DatePipe, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { IconAndButtonCardComponent } from '@components/icon-and-button-card/icon-and-button-card.component';
import { Payment } from '@models/payment';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { OrderService } from '@services/order.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-treatment-consultation-request-completed',
  templateUrl: './treatment-consultation-request-completed.component.html',
  styleUrls: ['./treatment-consultation-request-completed.component.scss'],
  standalone: true,
  imports: [IconAndButtonCardComponent, TitleCasePipe, NgIf, NgForOf, CurrencyPipe, DatePipe],
})
export class TreatmentConsultationRequestCompletedComponent implements OnInit {
  loadingButton = false;
  patientName: string;
  transactionId: string;
  hash: string;
  payments: Payment[] = null;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sessionStorageService: SessionStorageService,
    private orderService: OrderService,
    private errorLoggingService: ErrorLoggingService,
    private consultationRequestService: ConsultationRequestService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.patientName = this.sessionStorageService.patient?.name ?? '';
    this.transactionId = this.sessionStorageService.transactionId;
    this.hash = this.sessionStorageService.hash;
    this.getPayments();
    this.consultationRequestService.clearLastConsultationRequestData();
  }

  /**
   * Get the payments history for the order.
   */
  getPayments(): void {
    if (!this.transactionId || !this.hash) {
      this.payments = [];

      return;
    }

    this.orderService.orderPayments(this.transactionId, this.hash).subscribe({
      next: (response) => (this.payments = response),
      error: (error) => {
        this.payments = [];
        this.errorLoggingService.logError(`Loading payments failed: ${error.message}`);
      },
    });
  }

  /**
   * Download order invoice.
   */
  downloadInvoice(): void {
    this.loadingButton = true;
    this.orderService.downloadInvoice(this.transactionId, this.hash).subscribe({
      next: (response) => {
        this.createAndDownloadInvoice(response);
        this.loadingButton = false;
      },
      error: (error) => {
        this.loadingButton = false;
        this.errorLoggingService.logError(`Invoice download failed: ${error.message}`);
      },
    });
  }

  /**
   * Redirect to the given link.
   */
  redirect(): void {
    window.location.href = this.config.siteUrls.home;
  }

  /**
   * Create and download invoice.
   *
   * @param {Blob} invoice - The file data.
   */
  private createAndDownloadInvoice(invoice: Blob): void {
    const url = window.URL.createObjectURL(invoice);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'invoice.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
