import { DatePipe, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardComponent } from '@components/card/card.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Sites } from '@enums/sites';
import { InHomeBookingResult } from '@Medology/ng-findalab';
import { OrderCompleteDetails } from '@models/order-complete-details';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-order-complete-instructions',
  templateUrl: './order-complete-instructions.component.html',
  standalone: true,
  imports: [CardComponent, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, DatePipe],
})
export class OrderCompleteInstructionsComponent implements OnInit {
  @Input() orderCompleteDetails: OrderCompleteDetails;
  @Input() treatmentType: ConsultationTreatmentTypes;
  hasEmail: boolean;
  isFree: boolean;
  sendSms: boolean;
  isPayLater: boolean;
  inHomeBookingResult: InHomeBookingResult;
  assistanceDetails: string = `If you have any questions or need assistance in the meantime, our Patient Services and
  Support teams are available ${this.config.businessHours}.`;
  Sites: typeof Sites = Sites;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public domainService: DomainService,
    public storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.orderCompleteDetails) {
      this.hasEmail = this.orderCompleteDetails.hasEmail;
      this.isFree = this.orderCompleteDetails.free;
      this.sendSms = this.orderCompleteDetails.sendSms;
      this.isPayLater = this.orderCompleteDetails.isPayLater;
      this.inHomeBookingResult = this.orderCompleteDetails.inHomeBookingResult;
    }
  }

  /**
   * Determine if the cancellation policy should be shown.
   */
  get showCancellationPolicy(): boolean {
    return !this.domainService.isTreatMyUtiDomain() && !this.domainService.isStarfishDomain();
  }

  /**
   * Render different headerClass based on domain.
   */
  get headerClasses(): string {
    const baseClasses = 'p-6 is-size-3 is-size-4-mobile has-text-weight-bold card-header-title has-text-white';

    return this.domainService.isSTDCheckDomain() ? `${baseClasses} has-background-green ` : baseClasses;
  }

  /**
   * Gets the consultation page url.
   */
  getConsultationPageUrl(): string {
    const treatmentType =
      this.treatmentType === ConsultationTreatmentTypes.StarfishStd
        ? ConsultationTreatmentTypes.Std
        : this.treatmentType;

    const urlTree = this.router.createUrlTree([`/consultation/${treatmentType.replace(/_/g, '-')}/request`], {
      queryParams: { order_id: this.orderCompleteDetails.transactionId, hash: this.orderCompleteDetails.hash },
    });

    return this.router.serializeUrl(urlTree);
  }
}
