<header>
  <nav
    class="navbar is-hidden-mobile is-fixed-top"
    role="navigation"
    aria-label="main navigation"
    data-qa-id="HealthLabs Navbar"
    data-visual-test="absolute"
  >
    <div class="container">
      <div class="navbar-brand">
        <div class="navbar-brand">
          <div class="navbar-item" *ngIf="restrictedNavigation; else logoLink">
            <img ngSrc="assets/img/healthlabs-logo.svg" alt="HealthLabs.com Logo" width="250" height="52" priority />
          </div>
          <ng-template #logoLink>
            <a *ngIf="!restrictedNavigation" class="navbar-item" href="https://www.healthlabs.com/">
              <img ngSrc="assets/img/healthlabs-logo.svg" alt="HealthLabs.com Logo" width="250" height="52" priority />
            </a>
          </ng-template>
        </div>
      </div>
      <div class="navbar-menu">
        <div class="navbar-center is-fluid"></div>
        <div class="navbar-end">
          <div class="navbar-item">
            <a class="button is-link" href="https://my-account.healthlabs.com/">
              <strong><i class="fa fa-user"></i> My Account</strong>
            </a>
          </div>
          <div class="navbar-item">
            <a class="has-text-success-dark pl-3 button is-light" href="tel:{{ formattedPhoneNumber }}">
              <i class="fa fa-phone mr-1"></i> {{ config.phone }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-header is-hidden-tablet">
    <img
      *ngIf="restrictedNavigation; else mobileLogoLink"
      ngSrc="assets/img/healthlabs-logo.svg"
      alt="HealthLabs.com Logo"
      width="150"
      height="30"
      priority
    />
    <ng-template #mobileLogoLink>
      <a *ngIf="!restrictedNavigation" href="https://www.healthlabs.com/">
        <img ngSrc="assets/img/healthlabs-logo.svg" alt="HealthLabs.com Logo" width="150" height="30" priority />
      </a>
    </ng-template>
  </div>
  <app-hl-steps></app-hl-steps>
</header>
<div class="main-content">
  <ng-content></ng-content>
</div>
<app-hl-footer></app-hl-footer>
