import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { SelectableOption } from '@models/selectable-option';

@Component({
  selector: 'app-dropdown-select-field',
  templateUrl: './dropdown-select-field.component.html',
  styleUrls: ['./dropdown-select-field.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor],
})
export class DropdownSelectFieldComponent extends FormHelper {
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() invalidErrorMsg: string;
  @Input() label: string;
  @Input() options: SelectableOption[] = [];

  constructor() {
    super();
  }

  /**
   * Gets the form control.
   *
   * @returns {FormControl} the form control with the specified name from the form group
   */
  get control(): FormControl {
    return this.form?.get(this.controlName) as FormControl;
  }

  /**
   * Checks if the form control is invalid.
   *
   * @returns {boolean} true if the control is invalid, false otherwise
   */
  isControlInvalid(): boolean {
    return this.isInvalid(this.control);
  }
}
