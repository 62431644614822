import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { GooglePayService } from '@services/external-payments/google-pay.service';
import { ScriptService } from '@services/script.service';
import { delay, Observable, of } from 'rxjs';

@Injectable()
export class GooglePayMockService extends GooglePayService {
  constructor(
    protected braintreeService: BraintreeService,
    protected scriptService: ScriptService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected errorHandlerService: ErrorHandlerService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(braintreeService, scriptService, config, document, errorHandlerService, errorLoggingService);
  }

  /**
   * Mocks the Google Pay session.
   *
   * @param {number} amount the amount to charge the user
   *
   * @returns {Promise<string>} a promise that resolves with a fake nonce
   */
  override pay(amount: number): Promise<string> {
    return Promise.resolve('fake-android-pay-visa-nonce');
  }

  /**
   * Mocks the Google Pay initialization.
   *
   * @param {ElementRef} wrapper the element to render the Google Pay button
   *
   * @returns {Observable<void>} an Observable that emits a void value
   */
  override renderPaymentButton(wrapper: HTMLElement): Observable<void> {
    wrapper.appendChild(this.getGooglePayButton());

    return of(undefined).pipe(delay(3000));
  }
}
