<div class="primary title" *ngIf="domainService.isSTDCheckDomain()">
  <div class="container">
    <div class="columns mx-0">
      <div class="column">
        <h1>We Found <span>A Better Lab!</span></h1>
      </div>
    </div>
  </div>
</div>
<div id="betterlab-container" class="px-0 pt-4 pb-6 main container">
  <h1 class="is-size-1 has-text-centered" *ngIf="domainService.isHealthlabsDomain()">We Found A Better Lab!</h1>

  <div class="container p-0" data-qa-id="Order Place Message Container">
    <div class="colums mx-3 p-0">
      <div class="column p-0">
        <div class="notification p-4" [ngClass]="config.styles.orderBetterLab.orderPlaceNotificationClass">
          <p *ngIf="free || paymentType === 'payLater'">Your <b>order has been placed.</b></p>
          <p *ngIf="!(free || paymentType === 'payLater')">
            Your <b>order has been placed</b> and your card has been charged. The charge will appear on your statement
            as "ANALYTE HEALTH".
          </p>
          <p>
            The lab you have chosen is currently <b>experiencing delays</b> in your area. We
            <b>suggest changing your selected lab</b> to a facility below for faster results.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="center" class="container mt-4 p-0" data-qa-id="Current Test Center">
    <div class="colums mx-3 p-0">
      <div class="column p-0">
        <div class="notification p-4" [ngClass]="config.styles.orderBetterLab.currentTestCenterNotificationClass">
          <div class="columns">
            <div class="column is-6">Currently selected location:</div>
            <div class="column is-6">
              <b>
                {{ center.address }}
                <br />
                {{ center.city }}, {{ center.state }} {{ center.zip_code }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container pt-5" data-qa-id="Find a Better Lab">
    <div class="columns mx-0">
      <div class="column">
        <app-test-center [showHeader]="false" [reEnableButtons]="hasError" [inHomeCollection]="false"></app-test-center>
      </div>
    </div>
    <div class="columns mx-0">
      <div class="column">
        <app-error-notification classes="has-text-centered" *ngIf="betterLabError">
          {{ betterLabError }}
        </app-error-notification>
      </div>
    </div>
  </div>
  <div class="container mt-1">
    <div class="columns mx-0">
      <div class="column is-flex is-justify-content-end">
        <app-animated-button
          data-qa-id="Keep Current Lab Button"
          class="is-fullwidth has-text-white has-text-weight-bold"
          [class]="config.styles.orderBetterLab.keepCurrentLabButtonClass"
          direction="right"
          icon="arrow-right"
          [color]="config.styles.orderBetterLab.keepCurrentLabButtonColor"
          (click)="onKeepCurrentLab()"
        >
          Keep Current Lab
        </app-animated-button>
      </div>
    </div>
  </div>
</div>
