<app-card
  title="Enter Patient Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title numbered-header"
  contentClasses="p-4 card-body-background"
>
  <form [formGroup]="patientInfoForm" data-qa-id="patient-information-form">
    <div class="columns is-multiline">
      <div class="column legacy-full-column">
        <div class="field legacy-field">
          <label
            for="firstName"
            class="label has-text-weight-normal"
            [ngClass]="{ invalid: invalidControl('firstName') }"
          >
            First Name:
          </label>
          <div class="control">
            <input
              data-qa-id="patient-first-name-input"
              id="firstName"
              class="input"
              type="text"
              placeholder="Enter Your First Name"
              formControlName="firstName"
              autocomplete="given-name"
            />
            <div *ngIf="invalidControl('firstName')" class="error" data-qa-id="Patient First Name Error">
              {{ getControlValidationErrorMessage(patientInfoForm.get('firstName'), 'Your first name') }}
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field legacy-field">
          <label
            for="lastName"
            class="label has-text-weight-normal"
            [ngClass]="{ invalid: invalidControl('lastName') }"
          >
            Last Name:
          </label>
          <div class="control">
            <input
              data-qa-id="patient-last-name-input"
              id="lastName"
              class="input"
              type="text"
              placeholder="Enter Your Last Name"
              formControlName="lastName"
              autocomplete="family-name"
            />
            <div *ngIf="invalidControl('lastName')" class="error" data-qa-id="Patient Last Name Error">
              {{ getControlValidationErrorMessage(patientInfoForm.get('lastName'), 'Your last name') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column legacy-field">
        <label class="label has-text-weight-normal" [ngClass]="{ invalid: invalidControl('sex') }">Sex:</label>
        <div class="control">
          <label class="radio">
            <input type="radio" name="sex" value="M" formControlName="sex" data-qa-id="male-selector" />
            Male
          </label>
          <label class="radio">
            <input type="radio" name="sex" value="F" formControlName="sex" data-qa-id="female-selector" />
            Female
          </label>
          <div *ngIf="invalidControl('sex')" class="error">
            <span *ngIf="patientInfoForm.get('sex').errors?.required">Your gender is required</span>
            <span *ngIf="patientInfoForm.get('sex').errors?.invalidGender" data-qa-id="Sex Invalid Error">{{
              sexInvalidError
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column legacy-field" formGroupName="birthday">
        <label class="label has-text-weight-normal date-of-birth" [ngClass]="{ invalid: invalidControl('birthday') }">
          Date of Birth:
        </label>
        <div class="columns is-multiline is-mobile">
          <div class="column is-6-tablet is-12-mobile">
            <div class="select is-fullwidth">
              <select formControlName="month" data-qa-id="birthdate-month" class="select">
                <option [value]="null" selected disabled>Month</option>
                <option *ngFor="let month of months" [value]="month.number">
                  {{ month.number }} - {{ month.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-3-tablet is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="day" data-qa-id="birthdate-day" class="select">
                <option [value]="null" selected disabled>Day</option>
                <option *ngFor="let day of days" [value]="day">{{ day }}</option>
              </select>
            </div>
          </div>
          <div class="column is-3-tablet is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="year" data-qa-id="birthdate-year" class="select">
                <option [value]="null" selected disabled>Year</option>
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="invalidControl('birthday')" class="error ml-4 mt-0">
            <div *ngIf="patientInfoForm.get('birthday')?.errors?.invalidDate">
              {{ patientInfoForm.get('birthday')?.errors?.invalidDate }}
            </div>
            <div *ngIf="patientInfoForm.get('birthday').errors?.invalidAge">
              You must be between {{ minAgeAllowed }} and {{ maxAgeAllowed - 1 }} years old
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div data-qa-id="results-notification" *ngIf="!isReordering && !isRechargePayment">
      <div class="columns" *ngIf="isSTDCheckDomain">
        <div class="column">
          <label for="contact-via" class="label has-text-weight-normal">
            How would you like us to notify you when your results are available?
          </label>
          <div class="select is-fullwidth">
            <select id="contact-via" formControlName="contactVia" class="select">
              <option value="" disabled selected>Select One</option>
              <option value="email">Email Me</option>
              <option value="phone">Text Me (SMS)</option>
              <option value="none">Do not contact me, I will call in to check on my results.</option>
            </select>
          </div>
          <div *ngIf="invalidControl('contactVia')" class="error">Please select your contact type</div>
        </div>
      </div>
      <div class="columns" *ngIf="showPhoneField">
        <div class="column">
          <div class="field">
            <label for="phone" class="label has-text-weight-normal">Phone Number:</label>
            <div class="control">
              <input
                id="phone"
                class="input"
                type="tel"
                placeholder="(xxx) xxx-xxxx"
                formControlName="phone"
                mask="(000) 000-0000"
              />
            </div>
            <div *ngIf="invalidControl('phone')" class="error">
              <div *ngIf="patientInfoForm.get('phone').errors.required">Your phone number is required</div>
              <div *ngIf="patientInfoForm.get('phone').errors.pattern">Please enter a valid 10 digit phone number</div>
            </div>
          </div>
        </div>
        <div class="column" *ngIf="!isTmuDomain && !isStarfishDomain">
          <label class="label has-text-weight-normal">May we leave a voicemail?</label>
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="voicemail_allowed"
                [value]="true"
                formControlName="voicemail_allowed"
                data-qa-id="Allow Voicemail"
              />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                name="voicemail_allowed"
                [value]="false"
                formControlName="voicemail_allowed"
                data-qa-id="Do Not Allow Voicemail"
              />
              No
            </label>
          </div>
        </div>
      </div>
      <div class="columns" *ngIf="patientContactVia === 'email'">
        <div class="column">
          <div class="field legacy-field">
            <label
              for="email"
              class="label has-text-weight-normal your-email"
              [ngClass]="{ invalid: invalidControl('email') }"
            >
              Your E-mail:
            </label>
            <div class="control">
              <input
                id="email"
                class="input"
                type="text"
                placeholder="Enter your email address"
                formControlName="email"
              />
              <div *ngIf="invalidControl('email')" class="error">
                <div *ngIf="patientInfoForm.get('email').errors?.required" class="error">Your e-mail is required</div>
                <div *ngIf="patientInfoForm.get('email').errors?.email" class="error">Invalid email</div>
              </div>
              <span *ngIf="isHealthlabsDomain" class="help is-italic notification-note">
                *We will notify you by email when your results are ready.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showGuardianForm" id="parentConsentForm">
      <div class="columns mt-3">
        <div class="column is-flex is-align-items-center is-flex-direction-column">
          <div class="is-danger">The patient's date of birth indicates that the patient is under 18 years old.</div>
        </div>
      </div>
      <div class="columns">
        <div class="column" formGroupName="parentGuardian">
          <app-card>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label for="p-firstName" class="label has-text-weight-normal">Parent/Guardian First Name</label>
                  <div class="control">
                    <input id="p-firstName" class="input" type="text" formControlName="firstName" />
                  </div>
                  <div *ngIf="invalidControl('parentGuardian.firstName')" class="error">
                    Parent/Guardian first name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label for="p-lastName" class="label has-text-weight-normal">Parent/Guardian Last Name</label>
                  <div class="control">
                    <input id="p-lastName" class="input" type="text" formControlName="lastName" />
                  </div>
                  <div *ngIf="invalidControl('parentGuardian.lastName')" class="error">
                    Parent/Guardian last name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <label for="relation_to_child" class="label has-text-weight-normal">Relationship To Child</label>
                <div class="select">
                  <select id="relation_to_child" formControlName="relationToChild">
                    <option selected disabled>Select</option>
                    <option value="Biological Parent">Biological Parent</option>
                    <option value="Legal Guardian">Legal Guardian</option>
                  </select>
                </div>
                <div *ngIf="invalidControl('parentGuardian.relationToChild')" class="error">
                  Parent/Guardian relationship to child is required
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="is-flex">
                  <div>
                    <input id="guardian_attestation" type="checkbox" class="m-2" formControlName="attestation" />
                  </div>
                  <div>
                    <label for="guardian_attestation" class="checkbox">
                      I ATTEST that I am 18 years of age or older; that I am the legal guardian of the above-named
                      patient and that I consent to the patient having services performed. I understand and agree that
                      {{ consentDomain }} partners with certain Health Professionals and Labs (collectively "Health Care
                      Providers") that are independent, third-parties and I consent to BOTH {{ consentDomain }} and the
                      Health Care Providers providing services to the minor patient.
                    </label>
                  </div>
                </div>
                <div *ngIf="invalidControl('parentGuardian.attestation')" class="error">
                  Parent/Guardian must attest
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isFree && !isBaOrder && isPartnerTestingAllowed">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label for="hasPartner" class="checkbox">
              <input id="hasPartner" type="checkbox" formControlName="hasPartner" (change)="handleHasPartnerChange()" />
              Add the same testing for your partner {{ orAnyoneElseWithHl }}and receive $10.00 off
            </label>
          </div>
        </div>
      </div>
      <div class="columns" *ngIf="showPartnerEmailInput">
        <div class="column">
          <div class="field">
            <label for="partnerEmail" class="label has-text-weight-normal">Partner E-mail:</label>
            <div class="control">
              <input
                id="partnerEmail"
                class="input"
                type="text"
                placeholder="Enter your partner's email address"
                formControlName="partnerEmail"
              />
            </div>
            <div *ngIf="patientInfoForm.get('partnerEmail').errors?.required" class="error">
              Your partner e-mail is required
            </div>
            <div *ngIf="patientInfoForm.get('partnerEmail').errors?.email" class="error">Invalid email</div>
          </div>
          <div>
            <p class="is-warning line-height-1">
              <small>
                Your partner will receive an e-mail with instructions to set up their test. You and your partner will
                not have access to each other's results.
              </small>
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</app-card>
