import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { AddressInputsComponent } from '@components/address-inputs/address-inputs.component';

@Component({
  selector: 'app-consultation-request-address',
  templateUrl: './consultation-request-address.component.html',
  standalone: true,
  imports: [AddressInputsComponent, NgIf],
})
export class ConsultationRequestAddressComponent extends FormHelper implements OnInit {
  @Input({ required: true }) title: string;
  @Input({ required: true }) addressForm: FormGroup;
  @Input()
  set pharmacyState(value: string) {
    this._pharmacyState = value;
    this.updateStateValidators();
  }

  private _pharmacyState: string | null = null;

  constructor() {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.updateStateValidators();
  }

  /**
   * Gets the current pharmacy state value.
   *
   * @returns {string | null} the current pharmacy state
   */
  get pharmacyState(): string | null {
    return this._pharmacyState;
  }

  /**
   * Determines whether to display the address state error.
   *
   * @returns {boolean} true if the state control exists, is invalid, and has a stateMatch error
   */
  get displayAddressStateError(): boolean {
    const stateControl = this.addressForm.get('state');

    if (!stateControl) {
      return false;
    }

    return this.isInvalid(stateControl) && stateControl.errors?.stateMatch;
  }

  /**
   * Updates the validators for the state form control based on the pharmacy state.
   */
  private updateStateValidators(): void {
    if (!this.addressForm) {
      return;
    }

    const stateControl = this.addressForm.get('state');

    if (!stateControl) {
      return;
    }

    stateControl.setValidators(
      this.pharmacyState
        ? [Validators.required, this.matchPharmacyStateValidator(this.pharmacyState)]
        : Validators.required
    );

    stateControl.updateValueAndValidity();
  }

  /**
   * Creates a validator function that checks if the control value matches the pharmacy state.
   *
   * @param {string} pharmacyState the pharmacy state to match against
   *
   * @returns {ValidatorFn} a validator function that returns null if valid or an error object if invalid
   */
  private matchPharmacyStateValidator(pharmacyState: string): ValidatorFn {
    return (control) => {
      if (!control.value) {
        return null;
      }

      return control.value === pharmacyState
        ? null
        : { stateMatch: { required: pharmacyState, actual: control.value } };
    };
  }
}
