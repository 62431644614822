import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-animated-button',
  templateUrl: './animated-button.component.html',
  styleUrls: ['./animated-button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})

/**
 * Standard button with icon on particular side that animates on hover.
 */
export class AnimatedButtonComponent {
  // The icon for the button, all font awesome icons.
  @Input() icon: string;
  // The size of the icon (xs, sm, lg, 2x, 3x, 5x, 7x, 10x)
  @Input() size: string;
  // Color for the button.
  @Input() color: string;
  // Classes for the button.
  @Input() class: string;
  // Side to have the icon on (left, right)
  @Input() direction: string;
  // If the button should be disabled
  @Input() disabled: boolean = false;
  // If the button is a submit button.
  @Input() shouldSubmit: boolean = false;
  // If the button is in loading state
  @Input() loading: boolean = false;
  // The text displayed in the button when loading
  @Input() loadingText: string;
  // The id of the button
  @Input() id: string;

  /**
   * getType returns the button type
   */
  getType(): string {
    return this.shouldSubmit ? 'submit' : 'button';
  }

  /**
   * isDisabled returns the button state (loading or disabled)
   */
  isDisabled(): boolean {
    return this.disabled || this.loading;
  }

  /**
   * Returns the classes that should be assigned based the components inputs
   */
  getButtonClasses(): string {
    let btnClasses = 'animated-' + this.direction;

    btnClasses += this.color ? ' is-' + this.color : ' is-primary';

    if (this.class) {
      btnClasses += ` ${this.class}`;
    }

    return btnClasses;
  }

  /**
   * returns the classes for the margin left or right for text label
   */
  getMarginDirection(): string {
    return `${this.direction}-align-text`;
  }

  /**
   * returns the classes for the icon direction left or right
   */
  getIconClass(): string {
    let classes = 'fa';

    classes += ' fa-' + (this.loading ? 'spinner fa-spin' : this.icon.replace('fa-', ''));

    if (this.size) {
      classes += ' fa-' + this.size;
    }

    return classes;
  }
}
