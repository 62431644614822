<div class="container consultation-request-container">
  <div class="columns is-centered mx-0">
    <div class="column is-10" id="consultation-request-container">
      <div class="my-4">
        <app-consultation-request-personal-information [calendarOptions]="calendarOptions">
        </app-consultation-request-personal-information>
      </div>
      <div class="my-4">
        <app-consultation-request-contact-information></app-consultation-request-contact-information>
      </div>
      <div class="my-4">
        <app-consultation-request-medical-history></app-consultation-request-medical-history>
      </div>
      <div *ngIf="isStdTreatmentType" class="my-4">
        <app-consultation-request-prescription-information [calendarOptions]="calendarOptions">
        </app-consultation-request-prescription-information>
      </div>
      <div class="my-4">
        <app-consultation-request-choose-a-pharmacy
          [pharmacyForm]="pharmacyForm"
        ></app-consultation-request-choose-a-pharmacy>
      </div>
      <div class="my-4">
        <app-consultation-request-payment-info [freeEnabled]="freeEnabled"></app-consultation-request-payment-info>
      </div>
      <div class="my-4">
        <app-consultation-request-terms-of-service></app-consultation-request-terms-of-service>
      </div>
      <div class="my-4">
        <app-place-order
          #placeOrder
          paymentFormPath="payment.payment"
          [orderForm]="consultationRequestService.consultationForm"
          labFormPath="pharmacy"
          [formGroupsToBeValidated]="formGroupsToBeValidated"
          (formSubmitted)="submitConsultationRequest()"
          [chargeAmount]="consultationServiceCostInUsd"
        ></app-place-order>
      </div>
    </div>
  </div>
</div>
