import { NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-dynamic-form-question-description',
  standalone: true,
  templateUrl: './dynamic-form-question-description.component.html',
  imports: [NgIf],
})
export class DynamicFormQuestionDescriptionComponent {
  @Input() questionId: string;
  @Output() showLabFinderModal: EventEmitter<void> = new EventEmitter();

  /**
   * Checks if the urine test upsell description should be displayed.
   */
  get shouldDisplayUrineTestUpsellDescription(): boolean {
    return this.questionId === this.config.urineCultureTestQuestionId;
  }

  /**
   * Checks if the partner treatment test upsell description should be displayed.
   */
  get shouldDisplayPartnerTreatmentTestUpsellDescription(): boolean {
    return this.questionId === this.config.partnerTreatmentTestQuestionId;
  }

  /**
   * Checks if the metformin extended release test upsell description should be displayed.
   */
  get shouldDisplayMetforminERTestUpsellDescription(): boolean {
    return this.questionId === this.config.metforminExtendedReleaseTestQuestionId;
  }

  /**
   * Checks if the HSV test upsell description should be displayed.
   */
  get shouldDisplayHsvBloodTestUpsellDescription(): boolean {
    return this.questionId === this.config.hsvBloodTestQuestionId;
  }

  /**
   * Checks if the Wellness Panel test upsell description should be displayed.
   */
  get shouldDisplayWellnessPanelDescription(): boolean {
    return this.questionId === this.config.wellnessPanelQuestionId;
  }

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}
}
