<div class="columns is-multiline">
  <div *ngIf="isLoading && attachment" class="column is-10 is-offset-1">
    <small data-qa-id="Do not Refresh message">
      Please do not refresh the page while the submission is in progress
    </small>
  </div>
  <div class="column is-10 is-offset-1">
    <button
      type="button"
      (click)="submit()"
      data-qa-id="Submit"
      [disabled]="disabled"
      class="button has-text-white default-button is-rounded px-6"
    >
      <span *ngIf="!isLoading">Submit</span>
      <span *ngIf="isLoading" data-qa-id="Loading spinner submit"><em class="fa fa-spinner fa-spin"></em></span>
    </button>
    <p class="mt-3 error" data-qa-id="Error message" *ngIf="displayError">
      We are very sorry, it seems like there was a problem. <br />
      Please contact support at {{ email }}.
    </p>
  </div>
</div>
