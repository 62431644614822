import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-vitamin-d-supplement',
  templateUrl: './vitamin-d-supplement.component.html',
  styleUrl: './vitamin-d-supplement.component.scss',
  standalone: true,
  imports: [UpsellCardComponent],
})
export class VitaminDSupplementComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.VitaminDSupplement;

  constructor() {}
}
