<section class="section">
  <div class="container">
    <div class="card">
      <div class="card-inner-container" [attr.data-qa-id]="controlName + ' Details'">
        <div *ngIf="title" class="card-title" [innerHTML]="title" [attr.data-qa-id]="controlName + ' Title'"></div>
        <ng-content></ng-content>
        <app-radios-error-field
          [controlName]="controlName"
          [form]="form"
          [label]="question"
          [options]="options"
        ></app-radios-error-field>
        <div class="hint" [attr.data-qa-id]="controlName + ' Hint ' + i" *ngFor="let small of hints; let i = index">
          {{ small }}
        </div>
        <ng-content select="[bottomContent]"></ng-content>
      </div>
    </div>
  </div>
</section>
<div *ngIf="buttonText && displayButton" class="is-flex is-justify-content-center mb-5">
  <button
    (click)="buttonClick.emit()"
    [disabled]="processing"
    class="button is-rounded is-primary"
    [attr.data-qa-id]="controlName + ' Button'"
  >
    <span *ngIf="!processing">{{ buttonText }}</span>
    <i *ngIf="processing" data-qa-id="Loading spinner button" class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
  </button>
</div>
