<app-yes-no-details-field
  yesNoLabel="Are you currently on any medication?"
  [yesNoControlName]="MedicalHistoryQuestionIds.OnMedication"
  [detailsControlName]="MedicalHistoryQuestionIds.MedicationDetails"
  placeholder="Please list all medications"
  [form]="form"
></app-yes-no-details-field>
<app-yes-no-details-field
  yesNoLabel="Do you have any allergies that you are aware of?"
  [yesNoControlName]="MedicalHistoryQuestionIds.HasAllergies"
  [detailsControlName]="MedicalHistoryQuestionIds.AllergiesDetails"
  placeholder="Please list all drug allergies"
  [form]="form"
></app-yes-no-details-field>
<app-yes-no-details-field
  yesNoLabel="Do you have any other conditions?"
  [yesNoControlName]="MedicalHistoryQuestionIds.HasOtherConditions"
  [detailsControlName]="MedicalHistoryQuestionIds.OtherConditionsDetails"
  placeholder="Please list all your conditions"
  [form]="form"
></app-yes-no-details-field>
