import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HlFooterComponent } from '@layout/hl-footer/hl-footer.component';
import { HlStepsComponent } from '@layout/hl-steps/hl-steps.component';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { RestrictedNavigationService } from '@services/restricted-navigation.service';

@Component({
  selector: 'app-healthlabs-layout',
  templateUrl: './healthlabs-layout.component.html',
  styleUrls: ['./healthlabs-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, HlStepsComponent, HlFooterComponent, NgOptimizedImage],
})
export class HealthlabsLayoutComponent implements OnInit {
  restrictedNavigation: boolean = false;

  constructor(
    protected restrictedNavigationService: RestrictedNavigationService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {}

  ngOnInit(): void {
    this.restrictedNavigationService.restrictedNavigationObserver.subscribe((r) => (this.restrictedNavigation = r));
  }

  /**
   * Return the phone number formatted for tel href.
   */
  get formattedPhoneNumber(): string {
    return this.config.phone.replace(/[^0-9]/g, '');
  }
}
