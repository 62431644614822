<app-card
  title="Prescription Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <form [formGroup]="prescriptionInfoForm" data-qa-id="prescription-information-form">
    <p>
      If you are positive for Chlamydia, Gonorrhea and/or Trichomoniasis, it may be possible for the clinician to
      provide treatment for you and your partner by calling in an appropriate antibiotic to your pharmacy of choice. The
      fee for the clinician consultation is {{ ConsultationRequestService.cost | centsCurrency }}. If the clinician
      finds that treatment is appropriate (in most cases it is), a prescription for your partner can also be called in
      for an additional {{ ConsultationRequestService.cost | centsCurrency }}.
    </p>
    <label for="treat-self" class="mt-4 is-block is-clickable">
      <input id="treat-self" type="radio" [value]="Treatment.Self" formControlName="treatment" />
      <span class="ml-3">Prescription for yourself ONLY</span>
    </label>
    <label for="treat-partner" class="mt-4 is-block is-clickable">
      <input id="treat-partner" type="radio" [value]="Treatment.SelfAndPartner" formControlName="treatment" />
      <span class="ml-3">Prescription for yourself AND your partner</span>
    </label>
    <label for="treat-partner-only" class="mt-4 is-block is-clickable">
      <input id="treat-partner-only" type="radio" [value]="Treatment.Partner" formControlName="treatment" />
      <span class="ml-3">Prescription for your partner ONLY</span>
    </label>
    <div *ngIf="isTreatingPartner()" formGroupName="partner" data-qa-id="partner-form-group">
      <hr />

      <div class="columns">
        <div class="column">
          <div class="field">
            <label for="partnerFirstName">Partner's First Name:</label>
            <div class="control">
              <input
                id="partnerFirstName"
                class="input"
                type="text"
                formControlName="firstName"
                data-qa-id="Partner First Name Input"
              />
            </div>
            <div *ngIf="isControlInvalid('partner.firstName')" class="error" data-qa-id="Partner First Name Error">
              {{ getInvalidControlErrorMessage('partner.firstName', "Your partner's first name") }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="partnerLastName">Partner's Last Name:</label>
            <div class="control">
              <input
                id="partnerLastName"
                class="input"
                type="text"
                formControlName="lastName"
                data-qa-id="Partner Last Name Input"
              />
            </div>
            <div *ngIf="isControlInvalid('partner.lastName')" class="error" data-qa-id="Partner Last Name Error">
              {{ getInvalidControlErrorMessage('partner.lastName', "Your partner's last name") }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="is-block">Partner's Gender:</label>
            <label for="partnerMale" class="is-clickable">
              <input id="partnerMale" type="radio" [value]="Gender.Male" formControlName="gender" />
              <span class="ml-3">Male</span>
            </label>
            <label for="partnerFemale" class="ml-4 is-clickable">
              <input id="partnerFemale" type="radio" [value]="Gender.Female" formControlName="gender" />
              <span class="ml-3">Female</span>
            </label>
          </div>
        </div>
      </div>

      <div
        *ngIf="partnerGenderControl?.value === Gender.Female"
        class="columns"
        data-qa-id="prescription-pregnancy-question"
      >
        <div class="column">
          <label class="is-block">Is your partner currently pregnant?</label>
          <div class="select is-fullwidth">
            <select formControlName="pregnant">
              <option [value]="Pregnant.Yes">Yes</option>
              <option [value]="Pregnant.No">No</option>
              <option [value]="Pregnant.Unsure">Unsure</option>
            </select>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column" formGroupName="birthday">
          <label>Partner's Date of Birth:</label>
          <div class="columns is-multiline is-mobile">
            <div class="column is-6-desktop is-12-mobile">
              <div class="select is-fullwidth">
                <select formControlName="month" data-qa-id="partner-birthdate-month">
                  <option [value]="null" selected disabled>Month</option>
                  <option *ngFor="let month of calendarOptions?.months" [value]="month.number">
                    {{ month.number }} - {{ month.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="isControlInvalid('partner.birthday.month')" class="error">
                Your partner's birth month is required
              </div>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <div class="select is-fullwidth">
                <select formControlName="day" data-qa-id="partner-birthdate-day">
                  <option [value]="null" selected disabled>Day</option>
                  <option *ngFor="let day of calendarOptions?.days" [value]="day">{{ day }}</option>
                </select>
              </div>
              <div *ngIf="isControlInvalid('partner.birthday.day')" class="error">
                Your partner's day of birth is required
              </div>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <div class="select is-fullwidth">
                <select formControlName="year" data-qa-id="partner-birthdate-year">
                  <option [value]="null" selected disabled>Year</option>
                  <option *ngFor="let year of calendarOptions?.years" [value]="year">{{ year }}</option>
                </select>
              </div>
              <div *ngIf="isControlInvalid('partner.birthday.year')" class="error">
                Your partner's year of birth is required
              </div>
            </div>
          </div>
          <div *ngIf="isControlInvalid('partner.birthday') && isDateOfBirthFilled()" class="error">
            <div *ngIf="partnerBirthdayControl?.errors?.invalidDate">
              {{ partnerBirthdayControl.errors.invalidDate }}
            </div>
          </div>
        </div>
      </div>
      <app-height-weight-input
        [dob]="dob"
        [form]="prescriptionInfoForm"
        [groupName]="'partner'"
      ></app-height-weight-input>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label for="partnerAllergies">Known Drug Allergies of Partner:</label>
            <div class="control">
              <textarea
                id="partnerAllergies"
                class="input prescription-text-area"
                formControlName="allergies"
              ></textarea>
            </div>
            <div *ngIf="isControlInvalid('partner.allergies')" class="error">
              Your partner's allergies are required. Please enter "None" or "NKDA" if there are none.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="partnerPrescriptions">Prescription Medications of Partner:</label>
            <div class="control">
              <textarea
                id="partnerPrescriptions"
                class="input prescription-text-area"
                formControlName="prescriptions"
              ></textarea>
            </div>
            <div *ngIf="isControlInvalid('partner.prescriptions')" class="error">
              Your partner's current prescriptions are required. Please enter "None" if there are none.
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline pb-4" formGroupName="symptoms">
        <div class="column is-full has-text-weight-bold is-size-5">
          If your partner is experiencing symptoms, please list them here:
        </div>
        <div *ngFor="let symptom of ConsultationRequestService.symptoms" class="column is-half py-1">
          <label [for]="symptom.value + '_id'" class="is-block is-fullwidth is-clickable">
            <input
              [id]="symptom.value + '_id'"
              type="checkbox"
              [formControlName]="symptom.value"
              (change)="onSymptomCheckboxChange($event, symptom.value)"
            />
            <span class="ml-3">{{ symptom.name }}</span>
          </label>
        </div>
        <div *ngIf="isControlInvalid('partner.symptoms')" class="column is-full error py-0 mt-0">
          Please choose an option for your partner symptoms.
        </div>
      </div>
    </div>
  </form>
</app-card>
