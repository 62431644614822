<app-card
  title="Payment Information"
  headerClasses="p-4 is-size-3 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4 card-body-background"
  data-qa-id="Upsell Payment Information Card"
>
  <app-embedded-order-summary [totalInCents]="total" [itemRows]="orderSummaryRows"></app-embedded-order-summary>
  <ng-container *ngIf="paymentForm">
    <div class="tabs is-medium is-boxed" *ngIf="isPayLaterEnabled">
      <ul>
        <li [ngClass]="{ 'is-active': isPayNowTabSelected }">
          <a (click)="setPayNow(true)">Pay Now</a>
        </li>
        <li [ngClass]="{ 'is-active': !isPayNowTabSelected }">
          <a (click)="setPayNow(false)" data-qa-id="Pay Later">Pay Later</a>
        </li>
      </ul>
    </div>
    <div class="tabs-content">
      <app-pay-later-info *ngIf="!isPayNowTabSelected"></app-pay-later-info>
      <div
        *ngIf="isPayNowTabSelected"
        [ngClass]="{
          'bordered-component rounded-bottom-border': isPayLaterEnabled
        }"
      >
        <app-payment-info
          #paymentInfo
          [paymentForm]="paymentForm"
          [disabledPaymentMethods]="disabledPaymentMethods"
        ></app-payment-info>
      </div>
    </div>
  </ng-container>
</app-card>
