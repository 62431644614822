import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Transform the cents amount to a currency string.
 */
@Pipe({
  name: 'centsCurrency',
  standalone: true,
})
export class CentsCurrencyPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  transform(cents: number, showDecimals = true): string {
    return this.currency.transform(cents / 100, 'USD', 'symbol', `1.${showDecimals ? '2' : '0'}-2`);
  }
}
