<form [formGroup]="form">
  <div class="columns">
    <div class="column">
      <div class="field">
        <label *ngIf="label" class="label has-text-weight-normal">{{ label }}</label>
        <div class="control">
          <div class="select is-fullwidth mt-0">
            <select [formControlName]="controlName" class="select" [attr.data-qa-id]="controlName + ' Dropdown'">
              <option *ngIf="label" [value]="null" selected disabled>{{ label }}</option>
              <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
            </select>
          </div>
        </div>
        <div
          *ngIf="isControlInvalid() && invalidErrorMsg"
          class="error"
          [attr.data-qa-id]="controlName + ' Dropdown Error'"
        >
          {{ invalidErrorMsg }}
        </div>
      </div>
    </div>
  </div>
</form>
