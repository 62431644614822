<app-icon-and-button-card cardTitle="Consultation Sent Successfully!" iconClass="fa fa-check-circle">
  <div class="columns my-5">
    <div class="column is-offset-1 is-10">
      <div class="block">{{ patientName | titlecase }}</div>
      <div class="block">Thank you for adding a prescription request for DoxyPEP to your order!</div>
      <div class="block">
        Your Order Number is: <strong>{{ transactionId }}</strong>
      </div>
      <div class="block has-text-weight-bold">WHAT HAPPENS NEXT:</div>
      <div class="block">
        One of our medical professionals will review your request within the next 24 hours. Upon approval, a
        prescription for DoxyPEP will be forwarded to the pharmacy you selected on your form. You will then receive a
        message from the clinician, detailing your treatment plan and additional information about their
        recommendations.
      </div>
      <div class="block">
        If you have any questions in the meantime, please contact our Patient Services team at
        <span class="has-text-weight-bold">{{ phone }}</span
        >. They are available for you {{ businessHours }}.
      </div>
    </div>
  </div>
</app-icon-and-button-card>
