import { DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '@components/card/card.component';
import { ReviewsFeature } from '@models/reviews-feature';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';

@Component({
  selector: 'app-shopper-approved',
  styleUrls: ['./shopper-approved.component.scss'],
  templateUrl: './shopper-approved.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, CardComponent],
})
export class ShopperApprovedComponent implements OnInit {
  @Input() reviewsFeature: ReviewsFeature;
  renderer: Renderer2;
  loading: boolean = true;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private domainService: DomainService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    this.loadShopperApprovedScript();
  }

  /**
   * Set the Shopper Approved configuration variable and load the script from the external url.
   */
  private loadShopperApprovedScript(): void {
    if (!this.reviewsFeature) {
      return;
    }

    const shopperApprovedSettings = this.config.shopperApproved;

    (window as any).sa_values = {
      ...shopperApprovedSettings,
      orderid: this.reviewsFeature.orderId,
      name: this.reviewsFeature.name,
      email: this.reviewsFeature.email,
    };

    const date = new Date();
    const scriptSrc = `//www.shopperapproved.com/thankyou/inline/${
      shopperApprovedSettings.site
    }.js?d=${date.getTime()}`;
    const script = this.loadScript(scriptSrc);
    script.onload = () => {
      this.loading = false;
    };
  }

  /**
   * Append a script to the document body.
   *
   * @param src the URL of an external script file
   *
   * @return { any } The element created
   */
  private loadScript(src: string): any {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);

    return script;
  }
}
