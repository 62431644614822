<div class="card order-summary mb-4">
  <div class="card-header p-0">
    <div class="card-header-title p-0 has-text-white"><i class="fa fa-shopping-cart mr-2"></i> Order Summary</div>
  </div>
  <div class="card-body p-3 pb-5">
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th></th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let itemRow of itemRows">
          <tr *ngIf="itemRow.shouldDisplayRow" [attr.data-qa-id]="itemRow.dataQa">
            <td>{{ itemRow.item }}</td>
            <td>{{ itemRow.amountInCents | centsCurrency }}</td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr data-qa-id="embedded-order-summary-total-row">
          <th>Total</th>
          <th>{{ totalInCents | centsCurrency }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
