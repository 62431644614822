<app-height-weight-input [form]="form"></app-height-weight-input>
<div class="columns" *ngIf="disqualified">
  <div class="column">
    <div class="error" data-qa-id="BMI Calculator warning">
      If you are {{ heightFeet }} feet {{ heightInches }} inches, you need to have a weight of at least
      {{ idealWeight }}
      lbs to qualify for treatment
    </div>
  </div>
</div>
