import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationUpsellGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private consultationRequestService: ConsultationRequestService
  ) {}

  canActivate(): boolean {
    if (!this.sessionStorage.upsell.length) {
      return true;
    }

    const route = this.getQuestionnaireFirstNavigationUrl();
    const queryParams = {
      order_id: this.sessionStorage.transactionId,
      hash: this.sessionStorage.hash,
    };

    this.consultationRequestService.clearLastConsultationRequestData();
    this.router.navigate([route], { queryParams: queryParams });

    return false;
  }

  /**
   * Gets the first navigation URL from the questionnaire structure.
   *
   * @returns {string} the first navigation URL from the questionnaire structure
   */
  private getQuestionnaireFirstNavigationUrl(): string {
    return this.sessionStorage.getQuestionnaireStructure(this.sessionStorage.treatmentType).navigationUrls[0];
  }
}
