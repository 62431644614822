<form [formGroup]="form" [attr.data-qa-id]="controlName + ' Form'">
  <div class="columns">
    <div class="column">
      <label *ngIf="label" class="label has-text-weight-normal">{{ label }}</label>
      <div class="control">
        <label [ngClass]="{ 'wrapped-radio': options.length > 2 }" class="radio" *ngFor="let option of options">
          <input
            type="radio"
            [name]="controlName"
            [value]="option.value"
            [formControlName]="controlName"
            [attr.data-qa-id]="controlName + ' ' + option.label"
          />
          {{ option.label }}
        </label>
        <div *ngIf="hintText" class="hint" [attr.data-qa-id]="controlName + ' Hint'">
          {{ hintText }}
        </div>
        <div *ngIf="invalidByRequired" class="error" [attr.data-qa-id]="controlName + ' Required Error'">
          Please select one of these options
        </div>
        <div
          *ngIf="invalidByForbiddenValue"
          class="error"
          [attr.data-qa-id]="controlName + ' Error'"
          [innerHTML]="errorContent"
        ></div>
      </div>
    </div>
  </div>
</form>
