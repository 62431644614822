<div [formGroup]="form" class="control">
  <div *ngFor="let option of question.options" class="individual-checkbox">
    <label>
      <input
        [attr.data-qa-id]="question.label + ' ' + option.label"
        type="checkbox"
        [value]="option.value"
        (change)="onCheckboxChange($event)"
        [checked]="shouldCheckCheckbox(option.value)"
      />
      {{ option.label }}
    </label>
  </div>
</div>
