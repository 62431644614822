<nav class="navbar is-fixed-bottom cookies-required-banner">
  <div class="container">
    <div class="columns m-0 is-align-items-center p-4">
      <div class="column p-0">
        We use cookies to improve your test ordering experience but we have detected they may be disabled. In your
        browser settings, please enable cookies to continue.
      </div>
      <div class="column p-0 is-narrow has-text-centered">
        <button (click)="reloadPage()" class="button enabled-cookies-button py-2 px-4">
          I HAVE ENABLED MY COOKIES
        </button>
      </div>
    </div>
  </div>
</nav>
