<app-consultation-request-header></app-consultation-request-header>
<section class="payment-section section">
  <div class="container">
    <app-upsell-payment-info
      class="is-block mt-4"
      [upsellTestsForm]="upsellPaymentForm"
      [selectedUpsell]="upsells"
      [enablePayLater]="false"
    ></app-upsell-payment-info>
    <app-place-order
      #placeOrder
      class="is-block mt-4 mb-5"
      [chargeAmount]="upsellTotalPriceInUsd"
      [hideWhatHappensNext]="true"
      [orderForm]="upsellPaymentForm"
      [formGroupsToBeValidated]="[]"
      [placeOrderButtonText]="'Continue'"
      [displayTrustBadge]="false"
      [processing]="processing"
      (formSubmitted)="submitUpsells()"
    ></app-place-order>
  </div>
</section>
