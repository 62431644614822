import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-need-a-doctors-note',
  templateUrl: './need-a-doctors-note.component.html',
  styleUrls: ['./need-a-doctors-note.component.scss'],
  standalone: true,
  imports: [UpsellCardComponent],
})
export class NeedADoctorsNoteComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.DoctorsNote;

  constructor() {}
}
