<div class="columns is-mobile is-centered my-4">
  <button
    *ngIf="!processing"
    (click)="onButtonClicked()"
    class="venmo-button"
    data-qa-id="Pay With Venmo Button"
  ></button>
  <div *ngIf="processing" class="has-text-centered">
    <h3><em class="fa fa-spinner fa-spin"></em> Processing Venmo payment, please wait...</h3>
  </div>
</div>
