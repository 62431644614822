<app-upsell-card
  htmlTitle="<strong>Important: Prevent</strong> Vitamin B12 Deficiency"
  question="Would you like to add a Vitamin B12 supplement to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    GLP-1 can reduce your body's absorption of <strong>Vitamin B12</strong>, which is essential for energy, brain
    function, and overall well-being. Many patients experience <strong>fatigue, weakness, and brain fog</strong> due to
    low B12 levels.
  </p>
  <p class="mb-4">
    By adding a <strong>high-quality Vitamin B12 supplement</strong> to your routine while taking GLP-1, you can help
    maintain optimal energy and prevent deficiency.
  </p>
</app-upsell-card>
