<ng-container [formGroup]="form">
  <label
    *ngFor="let option of question.options"
    class="radio"
    [ngClass]="{ 'individual-radio': question.options?.length > 2 }"
  >
    <input
      [attr.data-qa-id]="question.label + ' ' + option.label"
      [formControlName]="question.id"
      [value]="option.value"
      type="radio"
    />
    {{ option.label }}
  </label>
</ng-container>
