import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon-and-button-card',
  templateUrl: './icon-and-button-card.component.html',
  styleUrls: ['./icon-and-button-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass],
})
export class IconAndButtonCardComponent {
  @Input() cardTitle: string;
  @Input() iconClass: string;
  @Input() imagePath: string;
  @Input() htmlContent: string;

  constructor() {}
}
