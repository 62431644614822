import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '@components/card/card.component';
import { YesNoDetailsFieldComponent } from '@components/yes-no-details-field/yes-no-details-field.component';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { FormService } from '@services/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-medical-history',
  templateUrl: './order-medical-history.component.html',
  styleUrls: ['./order-medical-history.component.scss'],
  standalone: true,
  imports: [CardComponent, YesNoDetailsFieldComponent],
})
export class OrderMedicalHistoryComponent implements OnInit, OnDestroy {
  medicalHistoryForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private formService: FormService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Unsubscribes from all subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the medical history form, by setting the form group and adding the required validators.
   */
  private initializeForm(): void {
    this.medicalHistoryForm = this.formService.checkout.get('medicalHistory') as FormGroup;
    this.addRequiredValidators();
    this.subscriptions.push(
      this.addConditionalValidators('onMedication', 'medicationDetails', 1000),
      this.addConditionalValidators('hasAllergies', 'allergiesDetails', 1000),
      this.addConditionalValidators('hasOtherConditions', 'otherConditionsDetails', 397)
    );
  }

  /**
   * Adds the required validators to the form controls.
   */
  private addRequiredValidators(): void {
    ['onMedication', 'hasAllergies', 'hasOtherConditions'].forEach((controlName) => {
      const control = this.medicalHistoryForm.get(controlName);
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    });
  }

  /**
   * Adds conditional validators to the form controls, making the details form control required if the yes/no form
   * control is true (yes).
   *
   * @param {string} controlName the name of the yes/no form control
   * @param {string} detailsControlName the name of the details form control
   * @param {number} detailsMaxLength the maximum length of the details form control value
   *
   * @returns {Subscription} a subscription to the value changes of the yes/no form control
   */
  private addConditionalValidators(
    controlName: string,
    detailsControlName: string,
    detailsMaxLength: number
  ): Subscription {
    const detailsControl = this.medicalHistoryForm.get(detailsControlName);

    return this.medicalHistoryForm.get(controlName).valueChanges.subscribe((value) => {
      detailsControl.setValue(null);
      if (value === QuestionnaireAnswers.Yes) {
        detailsControl.setValidators([Validators.required, Validators.maxLength(detailsMaxLength)]);
      } else {
        detailsControl.clearValidators();
      }

      detailsControl.updateValueAndValidity();
    });
  }
}
