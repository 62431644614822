import { NgIf } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-phone-order-user-banner',
  templateUrl: './phone-order-user-banner.component.html',
  styleUrls: ['./phone-order-user-banner.component.scss'],
  standalone: true,
  imports: [NgIf],
  encapsulation: ViewEncapsulation.None,
})
export class PhoneOrderUserBannerComponent {
  constructor(private storageService: StorageService) {}

  get isPhoneOrderUser(): boolean {
    return this.storageService.userId !== null;
  }
}
