import { HttpErrorResponse } from '@angular/common/http';

/**
 * Class for standardizing error handling for consistent logging and debugging.
 *
 * This class is required to properly parse errors so they can be emitted to parent projects that log to Sentry,
 * allowing for correct debugging of issues. It handles different error types including string errors, HttpErrorResponse
 * objects, and standard Error instances.
 */
export class AppError {
  title: string;
  body: any;

  /**
   * Creates a new application error instance.
   *
   * @param {any} error the original error object
   */
  constructor(error: any) {
    this.title = this.extractErrorTitle(error);
    this.body = error instanceof HttpErrorResponse ? { ...error, error: this.stringifyHttpError(error.error) } : error;
  }

  /**
   * Extracts a meaningful error title from an error object or string.
   *
   * @param {any} error the error object or string to extract the title from
   *
   * @returns {string} the error title, or 'Unknown error' if no title can be extracted
   */
  private extractErrorTitle(error: any): string {
    const defaultErrorTitle = 'Unknown error';

    if (!error) {
      return defaultErrorTitle;
    }

    try {
      if (typeof error === 'string') {
        return error;
      }

      return (
        (typeof error.message === 'string' && error.message) ||
        (typeof error.error === 'string' && error.error) ||
        (typeof error.error?.message === 'string' && error.error.message) ||
        (typeof error.detail === 'string' && error.detail) ||
        (typeof error.detail?.message === 'string' && error.detail.message) ||
        defaultErrorTitle
      );
    } catch {
      return defaultErrorTitle;
    }
  }

  /**
   * Converts an HTTP error object into a readable string representation.
   *
   * @param {any} errorObj the error object to stringify
   *
   * @returns {string} a string representation of the error object
   */
  private stringifyHttpError(errorObj: any): string {
    if (errorObj === null) {
      return 'null';
    }

    if (typeof errorObj === 'string') {
      return errorObj;
    }

    if (errorObj instanceof Error) {
      return `${errorObj.name}: ${errorObj.message}`;
    }

    try {
      return JSON.stringify(errorObj, this.handleCircularRefs(errorObj));
    } catch {
      try {
        return String(errorObj);
      } catch {
        return 'Error object could not be converted to string';
      }
    }
  }

  /**
   * Creates a replacer function for JSON.stringify that handles circular references and special types.
   *
   * @param {any} originalObject the original object being stringified, used to detect circular references
   *
   * @returns {(key: string, value: any) => string | any} a replacer function for JSON.stringify
   */
  private handleCircularRefs(originalObject: any): (key: string, value: any) => string | any {
    return (key: string, value: any) => {
      if (typeof value === 'function' || typeof value === 'symbol') {
        return value.toString();
      }

      if (key && value === originalObject) {
        return '[Circular]';
      }

      return value;
    };
  }
}
