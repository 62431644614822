<app-card headerClasses="is-hidden" contentClasses="p-0">
  <div class="p-6 healthlabs-coupon-container">
    <img ngSrc="assets/img/healthlabs-logo.png" alt="HealthLabs Logo" height="84" width="549" priority />
    <div id="healthlabs-coupon-content">
      <h1 class="mb-4 has-text-weight-bold is-uppercase">
        Check out our sister site and get 15% off any lab test now!
      </h1>
      <p class="is-italic mb-4">Fast, Private &amp; Affordable Lab Testing<br />With More Than 500 Lab Tests Online.</p>
      <app-animated-button
        class="has-text-white has-text-weight-bold mb-4 mt-4"
        direction="right"
        [color]="config.styles.healthLabsCoupon.buttonColor"
        icon="fa-arrow-right"
        (click)="redirectToHL()"
      >
        <span class="is-hidden-mobile">Go to </span>HealthLabs.com
      </app-animated-button>
    </div>
  </div>
</app-card>
