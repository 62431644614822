<app-consultation-request-header></app-consultation-request-header>

<app-radio-buttons-question-card
  buttonText="Continue"
  [controlName]="controlName"
  [form]="form"
  [hints]="hints"
  [options]="options"
  [processing]="processing"
  [question]="question"
  [title]="htmlTitle"
  (buttonClick)="continue()"
>
  <ng-content></ng-content>
  <ng-content bottomContent select="[bottomContent]"></ng-content>
</app-radio-buttons-question-card>
