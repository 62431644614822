import { APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { GlobalErrorHandler } from '@error-handlers/global-error-handler';
import { MapMarkerService, NgFindalabModule } from '@Medology/ng-findalab';
import { ApplePayService } from '@services/external-payments/apple-pay.service';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { GooglePayService } from '@services/external-payments/google-pay.service';
import { PaypalService } from '@services/external-payments/paypal.service';
import { VenmoService } from '@services/external-payments/venmo.service';
import { ApplePayMockService } from '@tests/services/apple-pay-mock.service';
import { BitPayMockService } from '@tests/services/bitpay-mock.service';
import { GooglePayMockService } from '@tests/services/google-pay-mock.service';
import { PaypalMockService } from '@tests/services/paypal-mock.service';
import { VenmoMockService } from '@tests/services/venmo-mock.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { FakeBackendInterceptor } from './app/interceptors/fake-backend.interceptor';
import { ConfigModule } from './app/modules/config/config.module';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      NgFindalabModule,
      ReactiveFormsModule,
      ConfigModule.forRoot(),
      NgxMaskDirective,
      NgxMaskPipe
    ),
    provideNgxMask(),
    MapMarkerService,
    CurrencyPipe,
    {
      provide: PaypalService,
      useClass: (window as any).Cypress ? PaypalMockService : PaypalService,
    },
    {
      provide: BitPayService,
      useClass: environment.production ? BitPayService : BitPayMockService,
    },
    {
      provide: ApplePayService,
      useClass: environment.production ? ApplePayService : ApplePayMockService,
    },
    {
      provide: GooglePayService,
      useClass: environment.production ? GooglePayService : GooglePayMockService,
    },
    {
      provide: VenmoService,
      useClass: environment.production ? VenmoService : VenmoMockService,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
}).catch((err) => console.error(err));
