<div class="main container">
  <app-card
    class="my-4 partialResultsDelivery-content"
    data-qa-id="Main Content PartialResultsDelivery"
    contentClasses="p-4 px-6"
    headerClasses="is-hidden"
    *ngIf="partialResultsDeliveryContent"
  >
    <div class="columns">
      <div class="column has-text-centered">
        <h3 class="is-size-3 my-4 has-text-weight-semibold has-text-black">
          Would you like to receive <span class="has-text-orange">individual results</span> as they come in?
        </h3>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="columns is-multiline">
          <div class="column pt-0 px-0 has-text-centered">
            <img
              class="partialResultsDelivery-img"
              *ngIf="
                partialResultsDeliveryContent.image_url
                  | imageFallback: 'assets/img/partial-results-delivery.png'
                  | async as imageUrl
              "
              [ngSrc]="imageUrl"
              width="352"
              height="347"
            />
          </div>
        </div>
      </div>

      <div class="column pl-4 pr-6 padding-mobile-content is-6">
        <div class="columns is-multiline is-mobile">
          <div class="column pb-0 is-12">
            <p>
              {{ partialResultsDeliveryContent.content.first_content }}
            </p>
          </div>
          <div class="column is-12">
            <span class="has-text-weight-ultrabold has-text-info">
              {{ partialResultsDeliveryContent.content.second_content }}
            </span>
          </div>
          <div class="column is-12" data-qa-id="Medication PartialResultsDelivery">
            <h3 class="is-size-6 mb-5 has-text-weight-bold has-text-black">
              {{ partialResultsDeliveryContent.content.first_title_for_list }}
            </h3>
            <ul>
              <li class="mt-4" *ngFor="let item of partialResultsDeliveryContent.content.items_for_first_list">
                <img class="mr-1 list-image" ngSrc="assets/img/check.png" height="20" width="20" /> {{ item.item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column px-5 pb-5 is-12">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12" data-qa-id="Medication PartialResultsDelivery">
            <h3 class="is-size-6 mb-5 has-text-weight-bold has-text-black">
              {{ partialResultsDeliveryContent.content.second_title_for_list }}
            </h3>
            <ul class="mt-4">
              <li class="mt-4" *ngFor="let item of partialResultsDeliveryContent.content.items_for_second_list">
                <img class="mr-1 list-image" ngSrc="assets/img/check.png" height="20" width="20" /> {{ item.item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-4" *ngIf="partialResultsDeliveryContent">
    <div class="columns is-multiline has-text-centered">
      <div class="column is-offset-1 is-5">
        <button
          class="button is-fullwidth has-text-weight-semibold same-width-btn"
          (click)="redirectToDoxyPepTreatment()"
          data-qa-id="Deny PartialResultsDelivery"
          [disabled]="processing"
        >
          No, thanks
        </button>
      </div>
      <div class="column is-5">
        <button
          class="button is-fullwidth has-text-white has-text-weight-semibold is-orange same-width-btn doxy-pep-add-to-order-button"
          data-qa-id="Recharge PartialResultsDelivery"
          (click)="submit()"
          [disabled]="processing || rechargeAttempt"
        >
          Add partial results delivery for ${{ partialResultsDeliveryPrice }} <i class="ml-2 fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="mt-4" *ngIf="showPaymentInformation">
    <app-upsell-payment-info
      class="is-block mt-4"
      [upsellTestsForm]="partialResultsDeliveryForm"
      [selectedUpsell]="[partialResultsDeliveryTest]"
      [enablePayLater]="false"
    ></app-upsell-payment-info>
    <app-place-order
      #placeOrder
      class="is-block mt-4 mb-5"
      [chargeAmount]="partialResultsDeliveryPrice"
      [hideWhatHappensNext]="true"
      [orderForm]="partialResultsDeliveryForm"
      [formGroupsToBeValidated]="[]"
      [placeOrderButtonText]="'Finish Placing My Order'"
      (formSubmitted)="submit()"
    ></app-place-order>
    <app-error-notification
      title="Error:"
      classes="has-text-black mb-5"
      *ngIf="submissionErrors.length > 0"
      data-qa-id="submission errors"
    >
      <div *ngFor="let errorMessage of submissionErrors">
        <p [innerHTML]="errorMessage"></p>
      </div>
    </app-error-notification>
  </div>
</div>
