import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import braintree from 'braintree-web';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplePayService extends ExternalPaymentMethodService {
  protected readonly genericAppleErrorMessage =
    'Apple Pay failed, please try again later or use a different payment method.';
  private window: Window & typeof globalThis;

  constructor(
    protected braintreeService: BraintreeService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected errorHandlerService: ErrorHandlerService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(errorHandlerService, errorLoggingService);
    this.window = this.document.defaultView;
  }

  /**
   * Gets whether Apple Pay is available.
   */
  override get isAvailable(): boolean {
    const appleSession = (this.window as any).ApplePaySession;

    return appleSession && appleSession.supportsVersion(3) && appleSession.canMakePayments();
  }

  /**
   * Begins an Apple Pay session, which will prompt the user to authorize the payment.
   *
   * @param {number} amount the amount to charge the user
   *
   * @returns {Promise<string>} a promise that resolves with the nonce when the payment is authorized or rejects
   * if the payment fails
   */
  override pay(amount: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const paymentRequest = this.paymentMethodInstance.createPaymentRequest({
        total: { label: this.config.applePayLabel, amount: amount.toFixed(2) },
        requiredBillingContactFields: ['postalAddress'],
      });

      const session = new (this.window as any).ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = (event: any) => {
        this.paymentMethodInstance
          .performValidation({
            validationURL: event.validationURL,
            displayName: this.config.applePayLabel,
          })
          .then((merchantSession: any) => {
            session.completeMerchantValidation(merchantSession);
          })
          .catch((validationErr: any) => {
            if (validationErr?.name === 'InvalidAccessError') {
              return reject(this.genericAppleErrorMessage);
            }

            session.abort();
            reject(validationErr);
          });
      };

      session.onpaymentauthorized = (event: any) => {
        this.paymentMethodInstance
          .tokenize({
            token: event.payment.token,
          })
          .then((payload: any) => {
            session.completePayment((this.window as any).ApplePaySession.STATUS_SUCCESS);
            resolve(payload.nonce);
          })
          .catch((tokenizeErr: any) => {
            session.completePayment((this.window as any).ApplePaySession.STATUS_FAILURE);
            reject(tokenizeErr);
          });
      };

      session.oncancel = () => reject({ statusCode: 'CANCELED' });

      session.begin();
    });
  }

  /**
   * Render the Apple Pay button.
   *
   * @returns {Observable<void>} an Observable that emits a void value when Apple Pay is successfully initialized
   */
  override renderPaymentButton(): Observable<void> {
    return new Observable<void>((observer) => {
      this.braintreeService
        .getClient()
        .then((client: any) => braintree.applePay.create({ client }))
        .then((applePay: any) => {
          this.paymentMethodInstance = applePay;
          observer.next();
        })
        .catch((err: any) => observer.error(err));
    });
  }
}
