import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-vitamin-b12',
  templateUrl: './vitamin-b12.component.html',
  standalone: true,
  imports: [UpsellCardComponent],
})
export class VitaminB12Component {
  readonly slug: UpsellSlugs = UpsellSlugs.VitaminB12Supplement;

  constructor() {}
}
