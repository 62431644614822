<div class="card only-stdcheck" *ngIf="upgrade" data-qa-id="Upgrade Widget">
  <div class="card-content">
    <div *ngIf="upgrade.slug === '10-test-panel-with-hiv-rna-early-detection'" class="has-text-centered">
      <strong class="has-text-primary-dark">Worried about recent exposure?</strong>
      <div *ngIf="hasHivRnaTest()">
        <h2 class="subtitle">Upgrade to the 10 Test Panel</h2>
        <strong class="has-text-primary-dark">with HIV RNA Early Detection</strong>
      </div>
      <div *ngIf="!hasHivRnaTest()">
        <p>Upgrade with the</p>
        <h2 class="subtitle">HIV RNA Early Detection</h2>
      </div>
    </div>

    <div *ngIf="upgrade.slug === '10-test-panel'">
      <div class="has-text-centered">
        <strong class="has-text-primary-dark">Save by testing for common STDs</strong>
        <p>Upgrade to the</p>
        <h2 class="subtitle">{{ upgrade.name }}</h2>
      </div>
      <hr class="separator" />
      <div class="columns is-mobile is-multiline">
        <div *ngFor="let test of childTests" class="column is-6 child-test">
          <i class="fa fa-check"></i><span>{{ test }}</span>
        </div>
      </div>
    </div>

    <hr class="separator" />

    <div class="columns is-vcentered">
      <div class="column is-7 upgrade__button_container">
        <button
          type="button"
          data-qa-id="Upgrade Submit"
          class="button is-large is-fullwidth has-text-white is-orange action"
          (click)="onUpgrade()"
        >
          <span>Upgrade Now</span>
        </button>
      </div>
      <div class="column is-5 has-text-right upgrade__price_container">
        <span class="subtitle is-text-secondary-green" data-qa-id="Upgrade Additional Cost">
          +{{ upgradeTotal - currentTotal | centsCurrency }}
        </span>
      </div>
    </div>
  </div>
</div>
