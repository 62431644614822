<app-upsell-card
  htmlTitle="<strong>Enhance Your Recovery</strong> Switch to a Vaginal Cream"
  [options]="options"
  question="Would you like to update your treatment plan to include a vaginal cream?"
  [slug]="slug"
  (continueButtonPressed)="continueButtonPressed($event)"
>
  <p class="mb-4">
    A more targeted approach to treating vaginal infections can be achieved with a vaginal cream instead of an oral
    antibiotic. Studies indicate that a <b>vaginal cream is often more effective</b> and has fewer systemic side effects
    because it treats the infection directly at the site. Additionally, the cream treatment is usually shorter, lasting
    <b>only 5 days</b>.
  </p>
  <p class="mb-4">We offer two options for vaginal creams:</p>
  <ul class="mb-4">
    <li>
      <b>Metronidazole Vaginal Gel 0.75% (Metrogel)</b>: Recommended for its effectiveness; however, it should not be
      used if you consume alcohol.
    </li>
    <li><b>Clindamycin Vaginal Cream 2% (Cleocin)</b>: Another effective option for treating vaginal infections.</li>
  </ul>
</app-upsell-card>
