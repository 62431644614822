<div
  appFileDragDrop
  class="dropzone p-4 is-flex is-align-items-center"
  (click)="triggerFileInput()"
  (filesChangeEmitter)="handleFileChange($event)"
>
  <input
    #fileInput
    [attr.data-qa-id]="question.label"
    [id]="question.id"
    class="is-hidden"
    (change)="handleFileChange($event.target.files)"
    type="file"
  />
  <button type="button" class="button is-primary" [attr.data-qa-id]="question.label + ' Upload Button'">Upload</button>
  <div *ngIf="!control.value; else uploadedFile" [attr.data-qa-id]="question.label + ' Drag File'">
    or drag a file here.
  </div>
  <ng-template #uploadedFile>
    <div class="uploaded-file-name" [attr.data-qa-id]="question.label + ' File Name'">{{ control.value.name }}</div>
    <button
      [attr.data-qa-id]="question.label + ' Remove File Button'"
      class="delete"
      (click)="removeFile($event)"
    ></button>
  </ng-template>
</div>
