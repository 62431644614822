import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { IconAndButtonCardComponent } from '@components/icon-and-button-card/icon-and-button-card.component';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-std-prevention-consultation-request-completed',
  templateUrl: './std-prevention-consultation-request-completed.component.html',
  styleUrls: ['../../consultation-request/consultation-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IconAndButtonCardComponent, TitleCasePipe],
})
export class StdPreventionConsultationRequestCompletedComponent implements OnInit {
  patientName: string;
  transactionId: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sessionStorage: SessionStorageService,
    private consultationRequestService: ConsultationRequestService
  ) {}

  ngOnInit(): void {
    this.patientName = this.sessionStorage.patient?.name ?? '';
    this.transactionId = this.sessionStorage.transactionId;
    this.consultationRequestService.clearLastConsultationRequestData();
  }

  /**
   * Gets the phone number from the config.
   *
   * @returns {string} the site phone number
   */
  get phone(): string {
    return this.config.phone;
  }

  /**
   * Gets the business hours from the config.
   */
  get businessHours(): string {
    return this.config.businessHours;
  }
}
