import { NgFor, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PaymentMethodIcon } from '@models/payments/payment-method-icon';

@Component({
  selector: 'app-payment-method-icons',
  templateUrl: './payment-method-icons.component.html',
  styleUrls: ['./payment-method-icons.component.scss'],
  standalone: true,
  imports: [NgFor, NgOptimizedImage],
})
export class PaymentMethodIconsComponent {
  @Input() icons: PaymentMethodIcon[] = [];
}
