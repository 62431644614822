<div class="columns is-mobile is-centered my-4">
  <div
    *ngIf="!processing && buttonReady"
    (click)="onButtonClicked()"
    class="apple-pay-button-with-text apple-pay-button-black-with-text"
  ></div>
  <div *ngIf="!processing && !buttonReady" class="has-text-centered">
    <h3>
      <ng-container *ngIf="applePayFailedToInitialize; else loadingMessage"> Apple Pay failed to load. </ng-container>
      <ng-template #loadingMessage>
        <em class="fa fa-spinner fa-spin"></em> Loading Apple Pay, please wait...
      </ng-template>
    </h3>
  </div>
  <div *ngIf="processing" class="has-text-centered">
    <h3><em class="fa fa-spinner fa-spin"></em> Processing Apple Pay payment, please wait...</h3>
  </div>
</div>
