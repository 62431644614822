import { DatePipe, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-hl-footer',
  templateUrl: './hl-footer.component.html',
  styleUrls: ['./hl-footer.component.scss'],
  standalone: true,
  imports: [DatePipe, NgOptimizedImage],
})
export class HlFooterComponent {
  today: Date = new Date();

  constructor() {}
}
