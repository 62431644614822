import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';

@Component({
  selector: 'app-order-service-agreement',
  templateUrl: './order-service-agreement.component.html',
  styleUrls: ['./order-service-agreement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
})
export class OrderServiceAgreementComponent extends FormHelper implements OnInit {
  serviceAgreementForm: FormGroup;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private formService: FormService) {
    super();
  }

  /**
   * Gets the current site terms of service URL.
   */
  get termsOfServiceUrl(): string {
    return this.config.siteUrls.termsOfService;
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Checks if the form control is invalid.
   *
   * @param {string} controlName the name of the form control to check for validity
   *
   * @returns {boolean} true if the form control is invalid, false otherwise
   */
  isFormControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.serviceAgreementForm?.get(controlName));
  }

  /**
   * Initializes the service agreement form, by setting the form group and adding the required true validator.
   */
  private initializeForm(): void {
    this.serviceAgreementForm = this.formService.checkout.get('serviceAgreement') as FormGroup;
    const control = this.serviceAgreementForm.get('agreed');
    control.setValidators([Validators.requiredTrue]);
    control.updateValueAndValidity();
  }
}
