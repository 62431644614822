<app-upsell-card
  #upsellCard
  htmlTitle="<strong>Medication Pickup</strong> or Delivery?"
  question="Instead of picking up your medication from your selected pharmacy, would you like us to overnight (FedEx) your medication to you?"
  [hints]="hints"
  [options]="options"
  [slug]="slug"
  [additionalFormsToValidate]="additionalFormsToValidate"
  (optionSelected)="onOptionSelected($event)"
  (continueButtonPressed)="onContinueButtonPressed($event)"
>
  <div bottomContent *ngIf="addressForm">
    <div class="mt-4">
      <app-address-inputs [form]="addressForm" [multiregion]="false"></app-address-inputs>
    </div>
  </div>
</app-upsell-card>
