import { HttpClient } from '@angular/common/http';
import { Sites } from '@enums/sites';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FileService } from '@services/file.service';
import { FormService } from '@services/form.service';
import { IndexedDbService } from '@services/indexed-db.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StdConsultationRequestService } from '@services/std-consultation-request.service';
import { TreatmentConsultationRequestService } from '@services/treatment-consultation-request.service';

const consultationServiceFactory = (
  config: AppConfig,
  http: HttpClient,
  formService: FormService,
  domainService: DomainService,
  sessionStorageService: SessionStorageService,
  fileService: FileService,
  indexedDbService: IndexedDbService,
  errorLoggingService: ErrorLoggingService
) => {
  let serviceInstance;

  switch (domainService.getSiteDomain()) {
    default:
    case Sites.Stdcheck:
    case Sites.Healthlabs:
      serviceInstance = domainService.isConsultationStdPreventionUrl()
        ? TreatmentConsultationRequestService
        : StdConsultationRequestService;
      break;

    case Sites.Treatmyuti:
    case Sites.Starfish:
      serviceInstance = TreatmentConsultationRequestService;
      break;
  }

  return new serviceInstance(
    config,
    http,
    formService,
    sessionStorageService,
    fileService,
    indexedDbService,
    errorLoggingService
  );
};

export const ConsultationRequestServiceProvider = {
  provide: ConsultationRequestService,
  useFactory: consultationServiceFactory,
  deps: [
    APP_CONFIG,
    HttpClient,
    FormService,
    DomainService,
    SessionStorageService,
    FileService,
    IndexedDbService,
    ErrorLoggingService,
  ],
};
