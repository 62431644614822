<div>
  <div class="main container">
    <div
      *ngIf="orderPageHeader.title || orderPageHeader.subtitle"
      class="mb-4 py-5 has-text-centered white-background-header"
    >
      <div
        *ngIf="orderPageHeader.title"
        class="is-size-3 has-text-weight-bold order-header-title"
        [innerHTML]="orderPageHeader.title"
      ></div>
      <div
        *ngIf="orderPageHeader.subtitle"
        class="is-size-6 order-header-subtitle"
        [innerHTML]="orderPageHeader.subtitle"
      ></div>
    </div>
    <div class="columns is-desktop is-reverse-desktop" data-qa-id="order columns" *ngIf="!isLoading">
      <div class="column mt-4 is-4-desktop" data-qa-id="order summary column">
        <div class="sticky" data-visual-test="relative">
          <div class="block">
            <app-order-summary></app-order-summary>
          </div>
          <div *ngIf="!isFree && isOrderUpgradeEnabled" class="block">
            <app-upgrade></app-upgrade>
          </div>
        </div>
      </div>
      <div class="column is-8-desktop" data-qa-id="order sections column">
        <div *ngIf="!isShippedTreatment" class="my-4">
          <app-test-center
            [inHomeCollection]="isInHomeCollectionAllowed"
            [ihcProviderIds]="ihcProviderIds"
          ></app-test-center>
        </div>
        <div class="my-4">
          <app-patient-info [enableGuardianForm]="shouldEnableParentConsentForm"></app-patient-info>
        </div>
        <div class="my-4" *ngIf="isOrderMedicalHistoryEnabled">
          <app-order-medical-history></app-order-medical-history>
        </div>
        <div class="my-4" *ngIf="isFree || isShippedTreatment">
          <app-card
            [title]="addressTitle"
            headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title numbered-header"
            contentClasses="p-4"
          >
            <app-address-inputs [form]="addressForm" class="order" [multiregion]="isFree"></app-address-inputs>
            <ng-container *ngIf="isFree">
              <p class="mt-2 mb-4">
                Upon a positive result, if we are unable to reach you via email, we will contact you by phone.
              </p>
              <app-order-phone-inputs class="order"></app-order-phone-inputs>
            </ng-container>
          </app-card>
        </div>
        <div class="my-4" *ngIf="!isFree && !isBaOrder">
          <app-payment-step-tabs [hasPartner]="hasPartner"></app-payment-step-tabs>
        </div>
        <div class="my-4" *ngIf="isTosOnOrderPageEnabled">
          <app-order-service-agreement></app-order-service-agreement>
        </div>
        <div class="my-4">
          <app-place-order
            #placeOrder
            [orderForm]="orderForm"
            [useSecondaryPaymentForm]="orderService.isSecondaryPaymentNeeded"
            [addressContactInformation]="addressContactInformation"
            (formSubmitted)="submitOrder()"
          ></app-place-order>
        </div>
      </div>
    </div>
  </div>
</div>

<app-find-tests-modal *ngIf="isHealthlabsDomain"></app-find-tests-modal>
