<app-card
  title="Medical History"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title numbered-header"
  contentClasses="p-4 card-body-background"
  data-qa-id="Order Medical History"
>
  <app-yes-no-details-field
    yesNoLabel="Are you currently on any medication?"
    yesNoControlName="onMedication"
    detailsControlName="medicationDetails"
    [form]="medicalHistoryForm"
  ></app-yes-no-details-field>
  <app-yes-no-details-field
    yesNoLabel="Do you have any allergies that you are aware of?"
    yesNoControlName="hasAllergies"
    detailsControlName="allergiesDetails"
    [form]="medicalHistoryForm"
  ></app-yes-no-details-field>
  <app-yes-no-details-field
    yesNoLabel="Do you have any other conditions?"
    yesNoControlName="hasOtherConditions"
    detailsControlName="otherConditionsDetails"
    [form]="medicalHistoryForm"
  ></app-yes-no-details-field>
</app-card>
