import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CardComponent } from '@components/card/card.component';
import { DropdownSelectFieldComponent } from '@components/dropdown-select-field/dropdown-select-field.component';
import { OrderTest } from '@models/order-test';
import { SelectableOption } from '@models/selectable-option';
import { Test } from '@models/test';
import { TestVisibilities } from '@models/test-visibilities';
import { ErrorLoggingService } from '@services/error-logging.service';
import { SessionStorageService } from '@services/session-storage.service';
import { SitesService } from '@services/sites.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-consultation-request-treatment-preferences-card',
  templateUrl: './consultation-request-treatment-preferences-card.component.html',
  styleUrls: ['./consultation-request-treatment-preferences-card.component.scss'],
  standalone: true,
  imports: [CardComponent, DropdownSelectFieldComponent],
})
export class ConsultationRequestTreatmentPreferencesCardComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() tests: OrderTest[] = [];
  treatmentPreferencesOptions: SelectableOption[] = [];

  private initialTreatmentPreferences: string;

  constructor(
    private sitesService: SitesService,
    private sessionStorageService: SessionStorageService,
    private errorLoggingService: ErrorLoggingService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setTreatmentPreferencesOptions();
  }

  /**
   * Gets whether the initial treatment preferences is an option in the treatment preferences options.
   *
   * @returns {boolean} whether the initial treatment preferences is an option
   */
  private get isInitialTreatmentPreferencesAnOption(): boolean {
    return this.treatmentPreferencesOptions.some((option) => option.value === this.initialTreatmentPreferences);
  }

  /**
   * Sets treatment preferences options by fetching test visibilities from the sites service.
   */
  private setTreatmentPreferencesOptions(): void {
    // Always use TMU site ID to avoid loading medications for treatments other than UTI
    this.sitesService
      .getTestVisibilities('17')
      .pipe(
        finalize(() => {
          this.ensureThereIsAtLeastOneOption();
          this.prefillTreatmentPreferences();
        })
      )
      .subscribe({
        next: (testVisibilities) => this.handleTestVisibilities(testVisibilities),
        error: (err) => this.handleGetTestVisibilitiesError(err),
      });
  }

  /**
   * Handles test visibilities received from the sites service by mapping the data to dropdown options and setting the
   * initial treatment preferences value from the order tests.
   *
   * @param {TestVisibilities} testVisibilities the test visibilities data
   */
  private handleTestVisibilities(testVisibilities: TestVisibilities): void {
    const tests: Test[] = [];
    this.treatmentPreferencesOptions = [];
    testVisibilities.data.forEach((data) => {
      this.treatmentPreferencesOptions.push(new SelectableOption(data.test.name));
      tests.push(data.test);
    });
    this.sessionStorageService.treatmentPreferenceTests = tests;
    this.initialTreatmentPreferences = this.sessionStorageService.treatmentPreferences;
  }

  /**
   * Handles errors that occur during the retrieval of test visibilities by logging them to the console.
   *
   * @param {any} error the error that occurred during the site visibilities request
   */
  private handleGetTestVisibilitiesError(error: any): void {
    try {
      this.errorLoggingService.logError(error);
    } catch (err) {}
  }

  /**
   * Prefills the treatment preferences value in the medical questionnaire form if available.
   */
  private prefillTreatmentPreferences(): void {
    if (!this.isInitialTreatmentPreferencesAnOption) {
      return;
    }

    this.form?.patchValue({
      treatmentPreferences: this.initialTreatmentPreferences,
    });
  }

  /**
   * Ensures that there is at least one option available for treatment preferences. If there are no options available, it
   * initializes the options with a default value. This can happen if either the test visibilities request failed or
   * returned an empty array.
   */
  private ensureThereIsAtLeastOneOption(): void {
    if (this.treatmentPreferencesOptions.length > 0) {
      return;
    }

    this.initialTreatmentPreferences = 'Let the doctor decide (UTI)';
    this.treatmentPreferencesOptions = [new SelectableOption(this.initialTreatmentPreferences)];
  }
}
