import { NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { CardComponent } from '@components/card/card.component';
import { SelectedCenterCardComponent } from '@components/selected-center-card/selected-center-card.component';
import { Map, NgFindalabModule, Result, ResultService } from '@Medology/ng-findalab';
import { Address } from '@models/address';
import { ConsultationRequestPharmacy } from '@models/consultation-request/consultation-request-pharmacy';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { MapsLoadService } from '@services/maps-load.service';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'app-consultation-request-choose-a-pharmacy',
  templateUrl: './choose-a-pharmacy.component.html',
  styleUrls: ['../../test-center/test-center.component.scss', './choose-a-pharmacy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CardComponent, NgIf, NgFindalabModule, SelectedCenterCardComponent],
})
export class ChooseAPharmacyComponent extends FormHelper implements OnInit {
  @Input() apiUrl: string;
  @Input() showHeader: boolean = true;
  @Input() pharmacyForm: FormGroup;
  @Input() customPharmacyForm: FormGroup;
  @Input() zipcode: string = null;
  @Input() pharmacy: ConsultationRequestPharmacy;

  map: Map = null;
  changingLab: boolean = false;
  selectedPharmacy: Result | ConsultationRequestPharmacy;
  states = Address.statesUS;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private resultService: ResultService,
    private formService: FormService,
    private mapsLoadService: MapsLoadService,
    private orderService: OrderService
  ) {
    super();
  }

  /**
   * Configures the map.
   */
  ngOnInit(): void {
    this.orderService.labResultSubscription.unsubscribe();
    this.mapsLoadService.getGoogleMapApi(this.config.googleMapApiKey).subscribe(() => {
      this.map = new Map(this.getMapConfig());
    });

    this.initializeSubscriptions();
    this.preSelectPharmacy();
  }

  /**
   * Gets map config object.
   */
  getMapConfig(): Map {
    let map = {
      searchUrl: {
        hostname: `${this.config.analyteCareApi}/api/v1`,
        path: '/pharmacies/labs/nearPostalCode',
      },
      apiKey: this.config.googleMapApiKey,
      testing: !this.config.production,
      searchFunction: {
        onlyNetworks: [],
        onlyStates: null,
        filterByOrder: '',
      },
      inputGroup: {
        container: 'field has-addons',
        field: 'input control',
        button: 'control',
      },
      lab: {
        phone: {
          show: true,
          text: 'Phone:',
        },
        button: {
          show: true,
          text: this.config.ngFindalab.labButtonText,
          class: `${this.config.styles.chooseAPharmacy.labButtonClass} button animated-right`,
        },
        icon: {
          show: true,
          class: 'fa fa-arrow-right ml-3 is-pulled-right',
        },
        recommended: {},
      },
      search: {
        title: this.config.ngFindalab.choosePharmacySearchTitle,
        description: this.config.ngFindalab.pharmacySearchDescription,
        button: {
          class: `button ${this.config.styles.chooseAPharmacy.searchButtonClass}`,
          loadingText: '',
          text: this.config.ngFindalab.searchButtonText,
          icon: {
            show: this.config.ngFindalab.showSearchIcon,
            class: 'fa fa-search',
            loadingClass: 'fa fa-spinner fa-spin',
          },
        },
        placeholder: 'Enter your zip',
        inputType: 'tel',
        showDescription: true,
        messageNoResults:
          'Oops! Sorry, we could not find any pharmacies near that location. ' +
          'Please try your search again with a different or less specific address.',
      },
      userLocation: {
        showOption: true,
        icon: 'fa fa-map-marker',
        msg: this.config.ngFindalab.locateMsg,
        loading: {
          icon: 'fa fa-spin fa-spinner',
          msg: 'Searching current location...',
        },
      },
      mobileBreakpoint: 848,
      googleMaps: {
        defaultLat: 39.97712,
        defaultLong: -99.587403,
        geoCoder: null,
        infoWindow: null,
        initialZoom: 4,
        map: null,
        markers: [],
        resultsZoom: 12,
        labMarkerFillColor: this.config.styles.chooseAPharmacy.googleMapsLabMarkerFillColor,
        recommendedMarkerFillColor: this.config.styles.chooseAPharmacy.googleMapsRecommendedMarkerFillColor,
        markerHoverFillColor: this.config.styles.chooseAPharmacy.googleMapsMarkerHoverFillColor,
      },
      postalCode: this.zipcode,
      mapId: this.config.findalabMapId,
    };

    return map as Map;
  }

  /**
   * Initialize subscriptions
   */
  initializeSubscriptions(): void {
    this.resultService.resultSelected$.subscribe((result: Result) => {
      this.selectPharmacy(result);
    });
  }

  /**
   * Pre-select the pharmacy if it exists
   */
  private preSelectPharmacy(): void {
    if (this.pharmacy) {
      this.selectPharmacy(this.pharmacy);
    }
  }

  /**
   * Select a provide pharmacy
   *
   * @param {Result | ConsultationRequestPharmacy} pharmacy The pharmacy to select
   */
  private selectPharmacy(pharmacy: Result | ConsultationRequestPharmacy): void {
    this.changingLab = false;
    this.selectedPharmacy = pharmacy;
    this.pharmacyForm.patchValue({
      address: pharmacy.address,
      city: pharmacy.city,
      id: pharmacy.id,
      title: pharmacy.title,
      name: pharmacy.title,
      zip_code: pharmacy.zip_code,
      phone: pharmacy.phone,
      state: pharmacy.state,
    });
  }
}
