<div class="container consultation-request-container">
  <div class="columns is-multiline is-centered m-0">
    <app-consultation-request-header></app-consultation-request-header>
    <div class="column is-10 pt-0">
      <div class="my-4">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <div class="columns is-multiline">
                <div class="column is-10 is-offset-1" *ngIf="question && form">
                  <label [attr.for]="question.id" class="label">{{ question.label }}</label>
                  <app-file-input [form]="form" [question]="question"></app-file-input>
                  <div *ngFor="let error of errors" class="error" [attr.data-qa-id]="question.label + ' Error'">
                    {{ error }}
                  </div>
                  <div class="hint">State issued Driver's License, ID, or Passport is acceptable.</div>
                </div>
                <div class="column is-10 is-offset-1 has-text-centered" *ngIf="form">
                  <app-treatment-consultation-submit
                    [disabled]="form.invalid"
                    [attachment]="file"
                  ></app-treatment-consultation-submit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
