<app-card
  title="Enter Payment Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title numbered-header"
  contentClasses="p-4 card-body-background"
>
  <div class="tabs is-medium is-boxed" *ngIf="isPayLaterEnabled">
    <ul>
      <li [ngClass]="{ 'is-active': isPayNowTabSelected }">
        <a (click)="setPayNow(true)">Pay Now</a>
      </li>
      <li *ngIf="!hasPartner && !orderService.giftCardApplied" [ngClass]="{ 'is-active': !isPayNowTabSelected }">
        <a (click)="setPayNow(false)" data-qa-id="Pay Later">Pay Later</a>
      </li>
    </ul>
  </div>

  <div class="tabs-content" data-qa-id="Payment method">
    <app-pay-later-info *ngIf="!isPayNowTabSelected"></app-pay-later-info>

    <div
      *ngIf="isPayNowTabSelected"
      class="bordered-component"
      [ngClass]="{
        'rounded-bottom-border': !orderService.isSecondaryPaymentNeeded,
        'bordered-component rounded-bottom-border': isPayLaterEnabled
      }"
    >
      <app-payment-info
        #paymentInfo
        [paymentForm]="paymentForm"
        [disabledPaymentMethods]="disabledPaymentMethods"
        [recharge]="this.recharge"
        (giftCardApplied)="onGiftCardApplied()"
        (giftCardRemoved)="onGiftCardRemoved()"
      ></app-payment-info>
    </div>

    <div
      *ngIf="isPayNowTabSelected && orderService.isSecondaryPaymentNeeded"
      class="bordered-component rounded-bottom-border rounded-top-border mt-1"
    >
      <app-payment-info
        #secondaryPaymentInfo
        [paymentForm]="secondaryPaymentForm"
        [isSecondary]="true"
        [recharge]="this.recharge"
      ></app-payment-info>
    </div>
  </div>
</app-card>
