import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { UpsellSlugs } from '@enums/upsell-slugs';

/**
 * Environment variables for use on the TreatMyUTI.com site, both in production and local environment.
 */
export const treatmyutiCommonEnvironment = {
  domain: 'TreatMyUTI.com',
  ngFindalab: {
    searchEndpoint: '/pharmacies/labs/nearPostalCode',
    filterByStates: null,
    showPhone: true,
    filterByTestAndSite: false,
    searchTitle: 'Enter Your Location',
    searchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    pharmacySearchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    locateMsg: 'Use my current location',
    labButtonText: 'CHOOSE THIS PHARMACY',
    searchButtonText: 'Find',
    showSearchIcon: false,
    labIconClass: 'fa fa-arrow-right',
    searchMessageNoResults:
      'Oops! Sorry, we could not find any pharmacies near that location. Please try your search again with a different or less specific address.',
    inputGroup: {
      container: '',
      field: null,
      button: '',
    },
    resultsZoom: 12,
    choosePharmacySearchTitle: 'Enter Your Location',
    showDayOfWeekFilter: false,
  },
  titles: {
    order: 'UTI Checkout - TreatMyUTI.com',
    consultationRequest: 'Questionnaire - TreatMyUTI.com',
    headlineCheckoutScheduled: '',
    step1Title: 'Find a Pharmacy',
    selectedCenterLabel: 'Pharmacy',
    orderPageHeader: {
      title: "You're almost <strong>Done!</strong>",
      subtitle:
        'Thank you for selecting a pharmacy. We just need a few more details before determining your diagnosis.',
    },
    scheduledConsultationRequest: {
      title: 'Clinician Phone Consultation Needed',
      calendarDetails:
        'To ensure compliance with Telehealth regulations in your state, we must arrange a brief phone consultation with one of our clinicians before prescribing medication. This consultation is provided at no additional cost to you.',
      phoneNumberDetails: 'A Clinician will call you at',
      availabilityDetails:
        "Typically, our clinicians call earlier than the anticipated window, so there's no need to be concerned if your appointment appears to be scheduled further than expected.",
    },
  },
  buttons: {
    checkout: 'Place Order',
    selectedCenterChange: 'Change Pharmacy',
  },
  order: {
    minAgeAllowed: 18,
  },
  styles: {
    chooseAPharmacy: {
      labButtonClass: 'is-secondary-orange has-text-white',
      searchButtonClass: 'is-green has-text-white',
      googleMapsLabMarkerFillColor: '#b46397',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#cfb0c8',
    },
    consultationRequest: {
      headlineClass: 'column is-flex is-justify-content-center pt-5 pb-0 has-text-weight-semibold',
    },
    healthLabsCoupon: {
      buttonColor: 'secondary-orange',
    },
    orderCompleteInstructions: {
      cardContentClass: 'has-background-green-light',
    },
    orderBetterLab: {
      orderPlaceNotificationClass: 'is-warning',
      currentTestCenterNotificationClass: 'is-info',
      keepCurrentLabButtonClass: 'is-uppercase',
      keepCurrentLabButtonColor: 'secondary-orange',
    },
    testCenter: {
      labButtonClass: '',
      searchButtonClass: 'is-secondary-orange has-text-white',
      googleMapsLabMarkerFillColor: '#b46397',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#cfb0c8',
    },
  },
  shopperApproved: {
    site: 25125,
    token: '2dbCcwg6',
  },
  orderCompleteSummary: {
    testOrderedWording: {
      single: 'Preferred Medication/Treatment',
      plural: 'Preferred Medications/Treatments',
    },
  },
  enablePayLater: false,
  enableBitPay: false,
  enableOrderUpgrade: false,
  enableOrderMedicalHistory: true,
  enableTosOnOrderPage: true,
  forceEmailResults: true,
  faviconFile: 'treatmyuti-favicon.ico',
  inHomeCollectionAllowed: false,
  displayFindTestsModal: false,
  displaySisterSiteBanner: false,
  phone: '1-866-511-3060',
  email: 'support@treatmyuti.com',
  validateSelectedLabNetwork: false,
  getNetworksFromTests: false,
  verifyTestsCanBeOrdered: false,
  isPartnerTestingAllowed: false,
  orderSummary: {
    displayCartAdditionalItems: true,
    allowEditingCart: true,
    editCartButtonText: 'Edit',
    displayCartSummaryTableHeaders: false,
    testsPrefix: 'UTI Antibiotics Online',
  },
  allowedRoutes: [
    '/order',
    '/order-complete.php',
    '/null',
    '/consultation/request',
    '/consultation/upload-consult-attachments',
    '/consultation/yeast-infection-prevention',
    '/consultation/alleviate-nausea-and-vomiting',
    '/consultation/replenish-your-good-bacteria',
    '/consultation/mens-intimate-wash',
    '/consultation/need-a-doctors-note',
    '/consultation/be-prepared-for-the-unexpected',
    '/consultation/medication-pickup-or-delivery',
    '/consultation/payment-info',
    '/consultation/schedule-consultation',
    '/consultation/std/request',
    '/consultation/std-prevention/request',
    '/consultation/uti/request',
    '/consultation/vaginal-dryness/request',
    '/uti/consultation-request-completed',
  ],
  showCouponCodeInSummary: false,
  consultationRequestUpsells: {
    [UpsellSlugs.Pyridium]: {
      price: 20,
      id: 2718,
    },
    [UpsellSlugs.FollowUpUrineTest]: {
      price: 29,
      id: 2836,
    },
    [UpsellSlugs.Fluconazole]: {
      price: 20,
      id: 2719,
    },
    [UpsellSlugs.Probiotics]: {
      price: 10,
      id: 2720,
    },
    [UpsellSlugs.DoctorsNote]: {
      price: 10,
      id: 2721,
    },
    [UpsellSlugs.Levonorgestrel]: {
      price: 10,
      id: 2722,
    },
    [UpsellSlugs.DeliverMedication]: {
      price: 39,
      id: 2723,
    },
    [UpsellSlugs.OndansetronPill]: {
      price: 20,
      id: 2834,
    },
    [UpsellSlugs.OndansetronDissolvableTablet]: {
      price: 20,
      id: 2835,
    },
    [UpsellSlugs.MensIntimateWash]: {
      price: 29,
      id: 2902,
    },
  },
  findalabMapId: '8f1673661122dda0',
  maxAgeAllowed: 81,
  treatmentFormSettings: {
    [ConsultationTreatmentTypes.Uti]: {
      id: '1',
      gendersDisallowed: [Gender.Male],
      genderError: 'UTI treatments are only available for female patients.',
    },
  },
};
