import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-replenish-your-good-bacteria',
  templateUrl: './replenish-your-good-bacteria.component.html',
  styleUrls: ['./replenish-your-good-bacteria.component.scss'],
  standalone: true,
  imports: [UpsellCardComponent, NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class ReplenishYourGoodBacteriaComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }

  /**
   * Retrieves the upsell slug based on the treatment type.
   */
  get slug(): UpsellSlugs {
    return this.sessionStorageService.treatmentType === ConsultationTreatmentTypes.YeastInfection
      ? UpsellSlugs.ShippedProbiotics
      : UpsellSlugs.Probiotics;
  }
}
