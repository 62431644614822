<div class="test-center" [ngClass]="{ 'in-home-height': inHomeScheduling }">
  <app-card
    [title]="step1Title"
    headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title numbered-header {{
      showHeader ? '' : 'is-hidden'
    }}"
    contentClasses="p-0 card-body-background"
  >
    <div class="p-3" data-qa-id="selected-lab-error" *ngIf="pscError">
      <p class="has-text-weight-bold">{{ pscError }}</p>
    </div>
    <app-selected-center-card
      *ngIf="!inHomeScheduling && showHeader"
      [label]="selectedCenterLabel"
      [center]="lab"
      [displayChangeLocationBtn]="map && !shouldDisplayMap"
      [inHomeResult]="inHomeResult"
      (changeCenterEvent)="changeLab()"
    >
    </app-selected-center-card>
    <div class="pb-3" *ngIf="shouldDisplayMap">
      <findalab-map
        *ngIf="map"
        [ngClass]="inHomeScheduling ? 'is-in-home' : ''"
        [mapConfigInput]="map"
        [(allButtonsDisabled)]="allButtonsDisabled"
      ></findalab-map>
    </div>
  </app-card>
</div>
