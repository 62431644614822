<app-upsell-card
  htmlTitle="<strong>Important: Prevent</strong> Yeast infections"
  question="We highly recommend including a single dose of Fluconazole in your prescription. Would you like to add it to your prescription?"
  [slug]="slug"
>
  <p class="mb-4">
    Taking antibiotics for UTIs or other infections can disrupt the natural balance of bacteria in the vagina,
    potentially leading to an overgrowth of yeast and thus a yeast infection. This is common scenario where a UTI
    treatment indirectly leads to a yeast infection.
  </p>
  <p class="mb-4">
    <strong>A single dose of Fluconazole (150mg)</strong> taken upon completion of your course of antibiotics can
    prevent the onset of a yeast infection.
  </p>
</app-upsell-card>
