<header class="starfish-header">
  <div
    class="container is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center"
  >
    <img
      *ngIf="restrictedNavigation"
      class="header-img"
      ngSrc="assets/img/starfish-logo.png"
      alt="Starfish Logo"
      data-qa-id="non-clickable-logo"
      width="230"
      height="40"
    />
    <a *ngIf="!restrictedNavigation" class="is-flex" href="https://www.starfish.com/" data-qa-id="clickable-logo">
      <img
        class="header-img"
        ngSrc="assets/img/starfish-logo.png"
        width="230"
        height="40"
        alt="Starfish Logo"
        priority
      />
    </a>
    <a class="phone-container" href="tel:+{{ config.phone }}" data-qa-id="starfish-phone-number">
      <span class="phone-icon animated"></span>
      <span class="phone-number">{{ config.phone }}</span>
    </a>
  </div>
</header>
<div class="main-content">
  <ng-content></ng-content>
</div>
<footer class="starfish-footer">
  <div class="container">
    <div class="columns is-align-items-center">
      <div class="column logo-column">
        <img
          class="footer-img"
          ngSrc="assets/img/starfish-logo-white.png"
          alt="Starfish Logo"
          data-qa-id="starfish-footer-img"
          height="50"
          width="270"
          priority
        />
      </div>
      <div class="column has-text-right-tablet">
        <div>Copyright &copy; {{ today | date: 'y' }} Starfish.com</div>
        <div>
          Made with <img ngSrc="assets/img/ah_heart.svg" alt="Heart Icon" width="17" height="16" /> by the people at
          Analyte Health
        </div>
      </div>
    </div>
  </div>
</footer>
