<div *ngIf="status || payPalFailedToInitialize" class="has-text-centered">
  <h3>
    <ng-container *ngIf="payPalFailedToInitialize; else loadingMessage"> PayPal failed to load. </ng-container>
    <ng-template #loadingMessage> <em class="fa fa-spinner fa-spin"></em> {{ status }} </ng-template>
  </h3>
</div>
<div [ngClass]="{ 'is-hidden': status }" class="p-4">
  <div class="columns">
    <div class="column is-8-mobile is-offset-2-mobile is-4-tablet is-offset-4-tablet is-one-third is-offset-one-third">
      <div id="paypal-button" class="columns"></div>
    </div>
  </div>
  <div class="columns is-centered is-mobile">
    <p class="is-size-7">The safer, easier way to pay</p>
  </div>
</div>
