<div class="main container py-6">
  <div class="columns mx-0">
    <div class="column is-full-mobile is-two-thirds-tablet">
      <app-order-complete-instructions
        class="is-block"
        [orderCompleteDetails]="orderCompleteDetails"
        [treatmentType]="treatmentType"
      >
      </app-order-complete-instructions>
      <div id="shopperApprovedContainer">
        <app-shopper-approved
          *ngIf="orderCompleteDetails && shouldDisplayShopperApproved"
          [ngStyle]="{
            visibility: displayShopperApprovedCard ? 'visible' : 'hidden',
            height: displayShopperApprovedCard ? 'auto' : '0'
          }"
          class="is-block mt-4"
          [reviewsFeature]="orderCompleteDetails.reviewsFeature"
        >
        </app-shopper-approved>
      </div>
    </div>
    <div class="column is-full-mobile is-one-third-tablet">
      <app-order-complete-summary
        *ngIf="orderCompleteDetails"
        class="is-block"
        [orderId]="orderCompleteDetails.transactionId"
        [orderTotal]="orderCompleteDetails.summary?.total"
        [paymentIds]="orderCompleteDetails.summary?.payments"
        [testsOrdered]="orderCompleteDetails.summary?.tests"
      >
      </app-order-complete-summary>
    </div>
  </div>
  <div
    *ngIf="config.displaySisterSiteBanner"
    class="columns mx-0"
    [ngClass]="displayShopperApprovedCard ? 'mt-6' : 'mt-5'"
  >
    <div class="column">
      <app-health-labs-coupon></app-health-labs-coupon>
    </div>
  </div>
</div>
