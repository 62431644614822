import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ConsultationRequestAttachmentType } from '@enums/consultation-request-attachment-type';
import { ConsultationRequestOrderStatus } from '@enums/consultation-request-order-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { CustomerTestsTypes } from '@enums/customer-tests-types';
import { PaymentTypes } from '@enums/payment-types';
import { Symptom } from '@enums/symptoms';
import { Treatment } from '@enums/treatment';
import { ConsultationRequestOrderCanceledError } from '@errors/consultation-request-order-canceled-error';
import { ConsultationRequest } from '@models/consultation-request/consultation-request';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { NewConsultationRequestResponse } from '@models/consultation-request/new-consultation-request-response';
import { StdConsultationRequestRequest } from '@models/consultation-request/std-consultation-request-request';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FileService } from '@services/file.service';
import { FormService } from '@services/form.service';
import { IndexedDbService } from '@services/indexed-db.service';
import { SessionStorageService } from '@services/session-storage.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestService {
  static cost = 9500;
  static symptoms = Object.keys(Symptom).map((key) => ({ name: key, value: Symptom[key] }));
  static partnerTreatmentSTDs = ['chlamydia', 'gonorrhea', 'trichomoniasis'];

  private apiUrl = `${this.config.analyteCareApi}/api/v1`;
  private apiConsultationUrl = `${this.apiUrl}/consultation`;
  protected consultationRequestOrderDetail: ConsultationRequestOrderDetail;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected http: HttpClient,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService,
    protected fileService: FileService,
    protected indexedDbService: IndexedDbService,
    protected errorLoggingService: ErrorLoggingService
  ) {}

  /**
   * Get consultation request form group from form service.
   */
  get consultationForm(): FormGroup {
    return this.formService.consultationRequest;
  }

  /**
   * Get the consultation form treatment control.
   */
  get treatmentControl(): AbstractControl {
    return this.consultationForm.get('prescription').get('treatment');
  }

  /**
   * Get the consultation order detail.
   */
  get consultationOrderDetail(): ConsultationRequestOrderDetail {
    return this.consultationRequestOrderDetail;
  }

  /**
   * Clears all data associated with the last consultation request.
   */
  clearLastConsultationRequestData(): void {
    this.indexedDbService.deleteDatabase(`${this.sessionStorageService.transactionId}-questionnaire`).subscribe();
    this.sessionStorageService.removeConsultationRequestCompletedData();
  }

  /**
   * Deletes all previous consultation databases if they exist.
   *
   * @param {string} transactionId the transaction ID of the current consultation order
   *
   * @returns {Observable<any[]>} an observable that emits the result of the deletion
   */
  deletePreviousConsultationDbs(transactionId: string): Observable<any[]> {
    return this.indexedDbService.getAllDatabaseNames().pipe(
      switchMap((names) => {
        const dbsToDelete = names
          .filter((name) => name.endsWith('-questionnaire') && !name.startsWith(transactionId))
          .map((name) => this.indexedDbService.deleteDatabase(name));

        return forkJoin(dbsToDelete);
      }),
      catchError((error) => {
        this.errorLoggingService.logError(error);

        return of([]);
      })
    );
  }

  /**
   * Get the public manager user info.
   *
   * @param {number} userId Manager user id
   */
  getManageUser(userId: number): Observable<{ id: number }> {
    return this.http.get<{ id: number }>(`${this.config.analyteCareApi}/api/v1/users/${userId}`);
  }

  /**
   * Get the details of the requested order.
   *
   * TODO: the treatmentType is a temporary fix, we need to consolidate DoxyPEP and remove it in the future.
   * Issue: https://github.com/Medology/ng-checkout/issues/2445
   *
   * @param {string}                     orderId       Order Id
   * @param {string}                     hash          The hash of the order
   * @param {ConsultationTreatmentTypes} treatmentType The treatment type (only children classes could use it)
   */
  getConsultationOrderDetails(
    orderId: string,
    hash: string,
    treatmentType: ConsultationTreatmentTypes
  ): Observable<ConsultationRequestOrderDetail> {
    return this.http
      .get<ConsultationRequestOrderDetail>(`${this.apiConsultationUrl}/order/${orderId}`, {
        headers: this.createAuthorizationHeader(hash, orderId),
      })
      .pipe(
        map((orderDetails) => {
          if (orderDetails.status === ConsultationRequestOrderStatus.Canceled) {
            throw new ConsultationRequestOrderCanceledError(this.config.siteUrls.home, this.config.domain);
          }

          return orderDetails;
        })
      );
  }

  /**
   * Get consultation request discount.
   *
   * @returns the consultation request discount amount (cents)
   */
  getDiscount(): number {
    const discount = this.formService.consultationRequest.get('payment').get('discount').value;
    if (isNaN(discount) || discount <= 0) {
      return 0;
    }

    return discount * 100;
  }

  /**
   * Get the subtotal to pay for the consultation request by multiplying the number of treatments
   * by the cost of each consultation request.
   *
   * @returns the subtotal amount (cents) to be paid
   */
  getSubtotal(): number {
    const treatments = this.treatmentControl.value === Treatment.SelfAndPartner ? 2 : 1;

    return treatments * ConsultationRequestService.cost;
  }

  /**
   * Get consultation request total, by subtracting the discount from the subtotal.
   *
   * @returns the amount (cents) to be paid for the consultation request
   */
  getTotal(): number {
    const paymentMethod = this.consultationForm.get('payment').get('payment').get('method').value;
    if (paymentMethod === PaymentTypes.Free) {
      return 0;
    }

    const total = this.getSubtotal() - this.getDiscount();

    return total > 0 ? total : 0;
  }

  /**
   * Call to postConsultationRequest using variables from this service.
   *
   * @param userId             the manager user id
   * @param deviceData         the device data from Braintree
   */
  submitConsultationRequest(userId: number, deviceData: string): Observable<NewConsultationRequestResponse> {
    const requestBody = new StdConsultationRequestRequest(
      this.consultationForm,
      this.consultationOrderDetail,
      userId,
      this.getSubtotal(),
      deviceData,
      this.sessionStorageService.treatmentType
    );

    // Be careful changing this order. The consultation request fails if the
    // request does not have the expected order.
    const expectedRequestObjectKeysOrder = [
      'order_id',
      'dob',
      'positive',
      'symptoms',
      'race',
      'payment',
      'additional_info',
      'user_id',
      'first_name',
      'last_name',
      'email',
      'phone',
      'gender',
      'sex',
      'ethnicity',
      'pregnant',
      'sex_with',
      'recharge',
      'allergies',
      'prescriptions',
      'health_info',
      'pharmacy_id',
      'terms',
      'date_of_birth',
      'num_treatments',
      'due_date',
      'last_menstrual_date',
      'type',
    ];

    return this.postConsultationRequest(
      this.getSortedKeysObject(requestBody, expectedRequestObjectKeysOrder) as StdConsultationRequestRequest,
      this.consultationOrderDetail.hash,
      this.consultationOrderDetail.transaction_id
    );
  }

  /**
   * Check if the treatment preference has changed.
   */
  hasTreatmentPreferenceChanged(): boolean {
    return this.sessionStorageService.treatmentPreferences !== this.getOrderedTreatmentPreferences();
  }

  /**
   * Get the ordered treatment preferences.
   */
  getOrderedTreatmentPreferences(): string {
    return this.sessionStorageService.treatmentType === ConsultationTreatmentTypes.StdPrevention
      ? this.config.doxycyclineName
      : this.consultationOrderDetail?.tests.find(
          ({ customer_tests_type }) => customer_tests_type === CustomerTestsTypes.Individual
        ).customer_tests_name;
  }

  /**
   * Sorts the keys of an object according to the array of keys in the expected order.
   * Object keys that are not in the array will be added to the end of the object.
   *
   * @param object the original object
   * @param expectedOrder an array of the keys in the order they should appear in the object
   * @returns the object with the keys in order
   */
  private getSortedKeysObject(object: StdConsultationRequestRequest, expectedOrder: string[]): Object {
    const sortedKeysObject = {};

    expectedOrder.forEach((key) => {
      sortedKeysObject[key] = object[key];
      delete object[key];
    });

    Object.keys(object).forEach((key) => (sortedKeysObject[key] = object[key]));

    return sortedKeysObject;
  }

  /**
   * Makes a post request to store a new consultation request.
   *
   * @param requestBody consultation request request body
   * @param hash hash id from the order
   * @param orderId transaction id from the order
   * @returns
   */
  postConsultationRequest(
    requestBody: ConsultationRequestRequest,
    hash: string,
    orderId: string
  ): Observable<NewConsultationRequestResponse> {
    return this.http.post<NewConsultationRequestResponse>(`${this.apiConsultationUrl}/request`, requestBody, {
      headers: this.createAuthorizationHeader(hash, orderId),
    });
  }

  /**
   * Generate the Http Headers that enable us to authenticate into stdcheck
   *
   * @param hash        the hash of the order
   * @param customerId  the id of the order
   */
  createAuthorizationHeader(hash: string, customerId: string): HttpHeaders {
    return new HttpHeaders({ 'X-API-Hash': hash, 'X-API-Id': customerId });
  }

  /**
   * Request to retrieve a Consultation Request
   *
   * @param consultationRequestId the consultation request id
   * @param customerId            the order id
   * @param hash                  the hash of the order
   */
  getConsultationRequest(
    consultationRequestId: string,
    customerId: string,
    hash: string
  ): Observable<ConsultationRequest> {
    return this.http.get<any>(`${this.apiConsultationUrl}/request/${consultationRequestId}`, {
      headers: this.createAuthorizationHeader(hash, customerId),
    });
  }

  /**
   * Request to update a Consultation Request
   *
   * @param consultationRequestId the consultation request id
   * @param customerId            the order id
   * @param hash                  the hash of the order
   * @param body                  the body to the request
   */
  update(consultationRequestId: string, customerId: string, hash: string, body: {}): Observable<any> {
    return this.http.put<any>(`${this.apiConsultationUrl}/request/${consultationRequestId}`, body, {
      headers: this.createAuthorizationHeader(hash, customerId),
    });
  }

  /**
   * Get the consultations of the requested order.
   *
   * @param {string} orderId Order Id
   * @param {string} hash    The hash of the order
   * @param {string} filters The filters to apply to the request
   */
  getConsultations(orderId: string, hash: string, filters: string): Observable<{ data: ConsultationRequest[] }> {
    return this.http.get<any>(`${this.apiUrl}/order/${orderId}/consultations`, {
      headers: this.createAuthorizationHeader(hash, orderId),
      params: new HttpParams().append('filters', filters),
    });
  }

  /**
   * Stores an attachment for a specific consultation in an order.
   *
   * @param {string} transactionId the ID of the order
   * @param {string} hash the hash of the order
   * @param {string} consultationId the ID of the consultation request
   * @param {File} file the file to be attached to the consultation request
   * @param {string} type the type of the attachment
   *
   * @returns {Observable<any>} an observable that emits the server's response
   */
  storeAttachment(
    transactionId: string,
    hash: string,
    consultationId: string,
    file: File,
    type: ConsultationRequestAttachmentType = ConsultationRequestAttachmentType.Others
  ): Observable<any> {
    return from(this.fileService.getCompressedFile(file)).pipe(
      switchMap((compressedFile) => {
        const formData = new FormData();
        formData.append('attachment', compressedFile, compressedFile.name);
        formData.append('type', type);

        return this.http.post(
          `${this.apiUrl}/order/${transactionId}/consultation/${consultationId}/attachment`,
          formData,
          {
            headers: this.createAuthorizationHeader(hash, transactionId),
          }
        );
      })
    );
  }

  /**
   * Get an array of ordered upsells names from the consultation order detail.
   *
   * @returns an array of ordered upsells names
   */
  getOrderedUpsells(): string[] {
    return this.consultationOrderDetail?.tests
      .filter((customerTest) => customerTest.customer_tests_type === CustomerTestsTypes.UpsellIndividual)
      .map((customerTest) => customerTest.customer_tests_name);
  }

  /**
   * Stores the ordered upsells name list on session storage.
   */
  storeOrderedUpsellsOnSessionStorage(): void {
    this.sessionStorageService.orderedUpsells = this.getOrderedUpsells();
  }
}
