import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { ScriptService } from '@services/script.service';

@Injectable()
export class BitPayMockService extends BitPayService {
  constructor(
    protected domainService: DomainService,
    protected scriptService: ScriptService,
    protected http: HttpClient,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected errorHandlerService: ErrorHandlerService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(domainService, scriptService, http, document, config, errorHandlerService, errorLoggingService);
  }

  /**
   * Handles the success of BitPay invoice creation by resolving the promise with the invoice ID. This will skip opening
   * the BitPay modal.
   *
   * @param {string} invoiceId the ID of the created invoice
   * @param {Function} resolve the function to call when the invoice is successfully handled
   * @param {Function} reject the function to call when the invoice handling is rejected
   */
  protected override handleBitPayInvoiceCreationSuccess(invoiceId: string, resolve: Function, reject: Function): void {
    this.bitpayStatusSubject.next(null);
    resolve(invoiceId);
  }
}
