<app-embedded-order-summary
  [itemRows]="orderSummaryRows"
  [totalInCents]="consultationRequestService.getTotal()"
></app-embedded-order-summary>
<form *ngIf="!isOrderFree() && enableDiscountInput" [formGroup]="consultationRequestPaymentForm" class="mb-4">
  <div class="columns">
    <div class="column is-12">
      <div class="field">
        <label for="consultation-discount" class="label">Discount:</label>
        <input id="consultation-discount" formControlName="discount" class="input" type="number" />
      </div>
    </div>
  </div>
</form>
