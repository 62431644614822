import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { SelectableOption } from '@models/selectable-option';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radios-error-field',
  templateUrl: './radios-error-field.component.html',
  styleUrls: ['./radios-error-field.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass],
})
export class RadiosErrorFieldComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() options: SelectableOption[];
  @Input() errorContents: string[];
  @Input() index: number;
  @Input() disqualifiedAnswers: string[] = [];
  @Input() validatorsWhenVisible: ValidatorFn | ValidatorFn[];
  @Input() hintText: string;
  @Output() disqualifiedEvent: EventEmitter<DisqualifiedFieldEvent> = new EventEmitter<DisqualifiedFieldEvent>();

  private valueChangesSubscription: Subscription;

  ngOnInit(): void {
    this.valueChangesSubscription = this.control?.valueChanges.subscribe(() => {
      this.disqualifiedEvent.emit({
        controlName: this.controlName,
        isDisqualified: this.invalidByForbiddenValue,
      });
    });

    this.initValidatorsWhenVisible();
  }

  ngOnDestroy(): void {
    this.removeValidatorsWhenHidden();
    if (!this.control?.hasValidator(Validators.required)) {
      this.control?.reset();
    }
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = undefined;
  }

  /**
   * Gets the form control.
   *
   * @returns {AbstractControl} the form control with the specified name from the form group
   */
  get control(): AbstractControl {
    return this.form?.get(this.controlName);
  }

  /**
   * Checks if the form control is invalid by required validation.
   */
  get invalidByRequired(): boolean {
    return this.control?.errors?.['required'] && (this.control?.dirty || this.control?.touched);
  }

  /**
   * Checks if the form control is invalid by forbiddenValues validation.
   */
  get invalidByForbiddenValue(): boolean {
    return this.disqualifiedAnswers.includes(this.control?.value);
  }

  /**
   * Get the content of the custom error message.
   */
  get errorContent(): string {
    return this.errorContents[this.disqualifiedAnswers.indexOf(this.control?.value)];
  }

  /**
   * Adds the validators when the control is visible, without affecting other validators.
   */
  private initValidatorsWhenVisible(): void {
    this.control?.addValidators(this.validatorsWhenVisible || []);
    this.control?.updateValueAndValidity();
  }

  /**
   * Removes the visible only validators, without affecting other validators.
   */
  private removeValidatorsWhenHidden(): void {
    this.control?.removeValidators(this.validatorsWhenVisible || []);
    this.control?.updateValueAndValidity();
  }
}
