import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddressInputsComponent } from '@components/address-inputs/address-inputs.component';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-mens-intimate-wash',
  templateUrl: './mens-intimate-wash.component.html',
  styleUrl: './mens-intimate-wash.component.scss',
  standalone: true,
  imports: [UpsellCardComponent, NgSwitch, NgSwitchCase, NgSwitchDefault, AddressInputsComponent, NgIf],
})
export class MensIntimateWashComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  readonly slug: UpsellSlugs = UpsellSlugs.MensIntimateWash;
  addressForm: FormGroup | null = null;

  constructor(private sessionStorageService: SessionStorageService, private formService: FormService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }

  /**
   * Gets the question text based on the treatment type.
   *
   * @returns {string} the question text to display
   */
  get question(): string {
    return this.treatmentType === ConsultationTreatmentTypes.Hsv
      ? 'We highly recommend including the Penile Wash to support healing and reduce the risk of spread or outbreaks. Would you like to add it to your order?'
      : 'We highly recommend including the Mens Intimate Wash to prevent reinfection. Would you like to add it to your order?';
  }

  /**
   * Gets the HTML title based on the treatment type.
   *
   * @returns {string} the HTML title to display
   */
  get htmlTitle(): string {
    return this.treatmentType === ConsultationTreatmentTypes.Hsv
      ? '<strong>Essential: Support Healing</strong> and Manage<br>Outbreaks Effectively'
      : '<strong>Important: Prevent Reinfection</strong> and Ensure<br>Lasting Relief';
  }

  /**
   * Retrieves the additional forms to validate if the address form is enabled.
   */
  get additionalFormsToValidate(): FormGroup[] {
    return this.addressForm ? [this.addressForm] : [];
  }

  /**
   * Store the address form in the session storage service using the current upsell option value.
   */
  onContinueButtonPressed(optionSelected: string): void {
    this.sessionStorageService.setUpsellAddress(
      this.slug,
      optionSelected === QuestionnaireAnswers.No ? null : this.addressForm.value
    );
  }

  /**
   * Configures the address form based on the current upsell option value.
   */
  onOptionSelected(optionSelected: string) {
    optionSelected == this.slug ? this.setAddressForm() : (this.addressForm = null);
  }

  /**
   * Configures the address form
   */
  private setAddressForm(): void {
    this.addressForm = this.formService.address;
    this.addressForm.addControl('streetAddress2', new FormControl(null));
    this.addressForm.patchValue(this.sessionStorageService.address);
  }
}
