<div *ngIf="domainService.isHealthlabsDomain()">
  <app-healthlabs-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-healthlabs-layout>
</div>
<div *ngIf="domainService.isSTDCheckDomain()">
  <app-stdcheck-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-stdcheck-layout>
</div>
<div *ngIf="domainService.isTreatMyUtiDomain()">
  <app-treatmyuti-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-treatmyuti-layout>
</div>
<div *ngIf="domainService.isStarfishDomain()">
  <app-starfish-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-starfish-layout>
</div>

<ng-template #content>
  <app-loading *ngIf="isLoading"></app-loading>
  <ng-container *ngIf="!isLoading">
    <app-phone-order-user-banner></app-phone-order-user-banner>
    <ng-content></ng-content>
  </ng-container>
</ng-template>
