<app-upsell-card
  htmlTitle="<strong>Important: Alleviate</strong> Nausea and Vomiting"
  [options]="options"
  question="We strongly recommend including Zofran in your prescription. Would you like to add it to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    One common and distressing side effect of many medications and treatments<ng-container
      *ngIf="treatmentType === ConsultationTreatmentTypes.Uti"
      >, including antibiotics for UTIs,</ng-container
    >
    is nausea and vomiting. Fortunately, there's a trusted solution: <strong>Zofran (Ondansetron)</strong>.
  </p>
  <p class="mb-4">
    By adding <strong>Zofran</strong> to your regimen, you can ensure a smoother, more comfortable recovery if the
    antibiotics you are prescribed cause nausea. This option includes a supply of <strong>15 pills</strong>. Zofran is
    <strong>shelf-stable for up to 2 years and can be stored in your medicine cabinet</strong>.
  </p>
</app-upsell-card>
