import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaypalService } from '@services/external-payments/paypal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class PaypalButtonComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  payPalFailedToInitialize: boolean = false;
  private subscriptions: Subscription[] = [];
  @Input() processing: boolean = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private paypalService: PaypalService, private elementRef: ElementRef) {}

  /**
   * Gets loading text.
   */
  get status(): string {
    if (this.loading) {
      return 'Loading PayPal, please wait...';
    }

    if (this.processing) {
      return 'Processing PayPal payment, please wait...';
    }

    return '';
  }

  /**
   * initializes the component.
   */
  ngOnInit() {
    this.subscriptions.push(this.listenForPaypalApproved());
    this.displayPaypalButton();
  }

  /**
   * Destroys the component.
   */
  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  /**
   * Listen to the PayPal approved event.
   */
  private listenForPaypalApproved() {
    return this.paypalService.paypalApproved$.subscribe(() => this.buttonClick.emit());
  }

  /**
   * Unsubscribe to all subscriptions.
   */
  private unsubscribeAll() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes and renders the PayPal button.
   */
  private displayPaypalButton() {
    const wrapper = this.elementRef.nativeElement.querySelector('#paypal-button');

    this.paypalService.renderPaymentButton(wrapper).subscribe({
      next: () => (this.loading = false),
      error: () => (this.payPalFailedToInitialize = true),
    });
  }
}
