import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Sites } from '@enums/sites';
import { InHomeCollectionService, ResultService } from '@Medology/ng-findalab';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { DomainService } from '@services/domain.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FormService } from '@services/form.service';
import { HealthLabsOrderService } from '@services/healthlabs-order.service';
import { OrderService } from '@services/order.service';
import { OrderConsultationRequestService } from '@services/order-consultation-request.service';
import { SessionStorageService } from '@services/session-storage.service';
import { STDcheckOrderService } from '@services/stdcheck-order.service';
import { StorageService } from '@services/storage.service';

const orderServiceFactory = (
  config: AppConfig,
  document: Document,
  http: HttpClient,
  router: Router,
  storage: StorageService,
  results: ResultService,
  formService: FormService,
  domainService: DomainService,
  authService: AuthorizationService,
  inHomeService: InHomeCollectionService,
  dataLayerService: DataLayerService,
  sessionStorageService: SessionStorageService,
  errorLoggingService: ErrorLoggingService
) => {
  let SiteOrderService;

  switch (domainService.getSiteDomain()) {
    default:
    case Sites.Stdcheck:
      SiteOrderService = STDcheckOrderService;
      break;

    case Sites.Healthlabs:
      SiteOrderService = HealthLabsOrderService;
      break;

    case Sites.Treatmyuti:
    case Sites.Starfish:
      SiteOrderService = OrderConsultationRequestService;
      break;
  }

  return new SiteOrderService(
    config,
    document,
    http,
    router,
    storage,
    results,
    formService,
    domainService,
    authService,
    inHomeService,
    dataLayerService,
    sessionStorageService,
    errorLoggingService
  );
};

export const OrderServiceProvider = {
  provide: OrderService,
  useFactory: orderServiceFactory,
  deps: [
    APP_CONFIG,
    DOCUMENT,
    HttpClient,
    Router,
    StorageService,
    ResultService,
    FormService,
    DomainService,
    AuthorizationService,
    InHomeCollectionService,
    DataLayerService,
    SessionStorageService,
    ErrorLoggingService,
  ],
};
