import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BmiCalculatorComponent } from '@components/bmi-calculator/bmi-calculator.component';
import { ChooseAPharmacyComponent } from '@components/consultation-request/choose-a-pharmacy/choose-a-pharmacy.component';
import { ConsultationRequestFormComponent } from '@components/consultation-request/consultation-request-form/consultation-request-form.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { InputTypes } from '@enums/input-types';
import { QuestionnaireStorageKeys } from '@enums/questionnaire-storage-keys';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { UpdatePharmacyError } from '@errors/update-pharmacy-error';
import { UploadAttachmentError } from '@errors/upload-attachment-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { DateData } from '@models/date-data';
import { DateSelection } from '@models/date-selection';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { Test } from '@models/test';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FormService } from '@services/form.service';
import { GenderService } from '@services/gender.service';
import { LoadingService } from '@services/loading.service';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  template: '',
})
export abstract class BaseDynamicConsultationRequestFormComponent
  extends ConsultationRequestFormComponent
  implements OnInit
{
  /**
   * This code listens for browser 'pageshow' events that occur when navigating using back/forward.
   *
   * If the page was loaded from browser cache (bfcache) the event.persisted is true, so this method updates
   * session storage with the current treatment type and reinitializes the component to ensure data consistency.
   *
   * bfcache docs: https://developer.mozilla.org/en-US/docs/Glossary/bfcache
   */
  @HostListener('window:pageshow', ['$event'])
  onPageShow(event: PageTransitionEvent): void {
    if (event.persisted) {
      this.sessionStorageService.treatmentType = this.consultationRequestTreatmentType;
      this.ngOnInit();
    }
  }

  @ViewChild('mentalQuestionsForm', { static: false }) private mentalQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('upsellQuestionsForm', { static: false }) private upsellQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('chooseAPharmacyComponent', { static: false }) private chooseAPharmacyComponent: ChooseAPharmacyComponent;
  @ViewChild('stdPreventionTermsComponent', { static: false })
  private stdPreventionTermsComponent: DynamicQuestionnaireFormComponent;
  @ViewChild('bmiCalculatorComponent', { static: false }) private bmiCalculatorComponent: BmiCalculatorComponent;

  QuestionnaireStorageKeys: typeof QuestionnaireStorageKeys = QuestionnaireStorageKeys;
  responseError: string | null = null;
  upsellIds: string[] = [];
  mentalQuestionIds: string[] = [];
  showLabFinderModal: boolean = false;
  displayChooseAPharmacyComponent: boolean = true;
  continueErrorMessage: string = null;

  /**
   * Determines whether the bottom error message should be displayed.
   */
  get shouldDisplayBottomError(): boolean {
    return this.submittedDataIsIncomplete || !!this.responseError;
  }

  /**
   * Gets the treatment preferences
   */
  get treatmentPreferences(): string {
    return this.treatmentPreferencesControl.value ?? this.sessionStorageService.treatmentPreferences;
  }

  /**
   * Checks if the submitted data is incomplete.
   */
  get submittedDataIsIncomplete(): boolean {
    return (
      (this.consultationRequestForm.invalid ||
        this.treatmentPreferencesForm.invalid ||
        (this.upsellForm && this.upsellForm.invalid) ||
        (this.mentalHealthForm && this.mentalHealthForm.invalid) ||
        this.medicalQuestionsForm?.form?.invalid ||
        (this.isStdPrevention && this.stdPreventionTermsComponent?.form?.invalid) ||
        this.medicalHistoryQuestionsForm?.form?.invalid) &&
      this.submissionError !== null
    );
  }

  /**
   * Determines if the consultation request is std prevention type.
   */
  get isStdPrevention(): boolean {
    return this.consultationRequestTreatmentType === ConsultationTreatmentTypes.StdPrevention;
  }

  /**
   * Gets the std prevention terms form group.
   */
  get stdPreventionTermsForm(): FormGroup {
    return this.stdPreventionTermsComponent?.form;
  }

  constructor(
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected consultationRequestService: ConsultationRequestService,
    protected formService: FormService,
    protected storageService: StorageService,
    protected sessionStorageService: SessionStorageService,
    protected title: Title,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandlerService: ErrorHandlerService,
    protected navigationService: NavigationService,
    protected domainService: DomainService,
    protected genderService: GenderService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(
      consultationRequestService,
      dynamicFormsService,
      errorHandlerService,
      loadingService,
      activatedRoute,
      sessionStorageService
    );
  }

  /**
   * Gets the patient address form group.
   */
  get addressForm(): FormGroup {
    return this.consultationRequestForm.get('address') as FormGroup;
  }

  /**
   * Gets the current consultation request.
   */
  get consultationRequest(): any {
    return this.consultationRequestOrder?.consultationRequest;
  }

  /**
   * Gets the consultation request form group.
   */
  get consultationRequestForm(): FormGroup {
    return this.formService.consultationRequestForm;
  }

  /**
   * Gets the treatment preferences form group.
   */
  get treatmentPreferencesForm(): FormGroup {
    return this.formService.treatmentPreferencesForm;
  }

  /**
   * Gets whether the current consultation request is a UTI consultation request.
   */
  get isUtiTreatmentType(): boolean {
    return this.consultationRequestTreatmentType === ConsultationTreatmentTypes.Uti;
  }

  /**
   * Gets the maximum age allowed for the consultation.
   */
  get maxAge(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Gets the minimum age allowed for the consultation.
   */
  get minAge(): number {
    return this.config.order.minAgeAllowed;
  }

  /**
   * Gets the questionnaire form.
   */
  get questionnaireForm(): FormGroup {
    return this.medicalQuestionsForm.form;
  }

  /**
   * Gets the upsell questions form.
   */
  get upsellForm(): FormGroup {
    return this.upsellQuestionsForm?.form;
  }

  /**
   * Gets the mental health questions form.
   */
  get mentalHealthForm(): FormGroup {
    return this.mentalQuestionsForm?.form;
  }

  /**
   * Gets the external validation data for the dynamic questionnaire form.
   */
  get externalData(): ExternalData {
    return {
      age: this.patientAge,
      gender: this.patientInfoForm.value.gender,
      siteName: `${this.domainService.getSiteDomain()}.com`,
      treatmentName: this.sessionStorageService.getTreatmentPreferenceName(),
      pharmacyState: this.pharmacyForm.value.state,
      orderIncludesAcyclovirCream: this.isTestInOrder(this.config.acyclovirCreamTestId),
    };
  }

  /**
   * Gets the treatment preferences form control.
   */
  get treatmentPreferencesControl(): FormControl {
    return this.treatmentPreferencesForm.get('treatmentPreferences') as FormControl;
  }

  /**
   * Gets the patient's age.
   */
  get patientAge(): number {
    const { day, month, year } = this.patientInfoForm.get('birthday').value;

    return new DateSelection(+month, +day, +year).getAge();
  }

  /**
   * Gets the name of the indexed DB.
   */
  get indexedDbName(): string {
    return `${this.consultationRequestOrder.transaction_id}-questionnaire`;
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.title.setTitle(this.config.titles.consultationRequest);
    this.consultationRequestService.storeOrderedUpsellsOnSessionStorage();
    this.handleConsultationOrderDetails();
    this.handleStdPreventionConsultation();
    this.defineTreatmentPreferenceTests();
  }

  /**
   * Stores the consultation request data and navigates to the next page.
   */
  continue(): void {
    this.submissionError = null;
    this.responseError = null;

    if (!this.shouldContinue()) {
      this.markAllFormControlsAsTouched();
      this.submissionError = new MissingFormFieldsError().message;

      return;
    }

    this.isProcessing = true;

    if (this.shouldUpdatePharmacy()) {
      return this.updatePharmacy();
    }

    this.handleSuccessWorkflow();
  }

  /**
   * Shows the lab finder modal.
   */
  presentLabFinderModal(): void {
    this.chooseAPharmacyComponent.changingLab = false;
    this.showLabFinderModal = true;
  }

  /**
   * Checks if a test with the specified ID is included in the consultation request order.
   *
   * @param {number} testId the ID of the test to check
   *
   * @returns {boolean} true if the test ID exists in the consultation request order, otherwise false
   */
  private isTestInOrder(testId: number): boolean {
    return this.consultationRequestOrder.tests.some(({ test_id }) => test_id === testId);
  }

  /**
   * Calculates the difference in days between the current date and the provided date.
   *
   * @param {Date} date the date to compare with the current date
   *
   * @returns {number} the absolute difference in days between the current date and the provided date
   */
  private getDateDiffInDays(date: Date): number {
    const timeDiff = new Date().getTime() - date.getTime();

    return Math.abs(Math.floor(timeDiff / (1000 * 3600 * 24)));
  }

  /**
   * Handles the success of the consultation order details resolver.
   *
   * @param {ConsultationRequestOrderDetail} consultationOrderDetails The consultation order details
   */
  protected handleConsultationDetailsSuccess(consultationOrderDetails: ConsultationRequestOrderDetail): void {
    this.addTreatmentPreferencesValidator();
    this.defineTreatmentPreferences();
    this.setQuestions().then((questionnaire) => {
      this.upsellIds = questionnaire['upsellIds'];
      this.mentalQuestionIds = questionnaire['mentalQuestionIds'];
      this.loadingService.toggleLoader(false);
    });
  }

  /**
   * Gets the title for the consultation request address component.
   */
  protected get consultationRequestAddressTitle(): string {
    return 'Patient Address';
  }

  /**
   * Perform specific actions for the STD prevention consultation request.
   */
  private handleStdPreventionConsultation(): void {
    if (!this.isStdPrevention) {
      return;
    }

    this.pharmacyForm.valueChanges.subscribe(() => this.consultationRequestForm.updateValueAndValidity());
  }

  /**
   * Hide the Choose a Pharmacy component. Because the component won't be rendered, the pharmacy form will not be
   * updated. So we need to manually set the pharmacy id (the only field required) to continue. Later we have a check
   * to see if the pharmacy should be updated (which should always be false in this case).
   */
  protected hidePharmacyAndSetDefault(): void {
    this.displayChooseAPharmacyComponent = false;
    this.pharmacyForm.patchValue({ id: this.consultationRequest.pharmacy.id });
  }

  /**
   * Prefill the address form with the patient's address if available
   */
  protected prefillPatientAddress(): void {
    const address = this.consultationRequestService.consultationOrderDetail?.address;

    this.addressForm.patchValue({
      streetAddress: address.customer_address_street,
      streetAddress2: address.customer_address_street_2 ?? '',
      city: address.customer_address_city,
      state: address.customer_address_state,
      zipcode: address.customer_address_zip,
    });
  }

  /**
   * Sets the treatment preferences form control as required if the consultation request is a UTI consultation request.
   */
  private addTreatmentPreferencesValidator(): void {
    if (this.isUtiTreatmentType) {
      this.treatmentPreferencesControl?.addValidators(Validators.required);
      this.treatmentPreferencesControl?.updateValueAndValidity();
    }
  }

  /**
   * Define the treatment preferences with the first ordered test.
   */
  private defineTreatmentPreferences(): void {
    this.sessionStorageService.treatmentPreferences = this.consultationRequestService.getOrderedTreatmentPreferences();
  }

  /**
   * Sets the treatment preference tests based on the consultation request treatment type.
   *
   * Only specific treatment types (BacterialVaginosis, Metformin) are eligible for setting treatment preferences.
   * UTI orders have a separate component for treatment preferences and are excluded from this logic.
   */
  private defineTreatmentPreferenceTests(): void {
    if (
      !this.config.treatmentFormSettings[this.consultationRequestTreatmentType]?.hasOwnProperty(
        'eligibleTreatmentTypes'
      )
    ) {
      return;
    }

    this.sessionStorageService.treatmentPreferenceTests =
      (this.config.treatmentFormSettings[this.consultationRequestTreatmentType].eligibleTreatmentTypes as Test[]) ?? [];
  }

  /**
   * Handles the success workflow for the consultation request continue button.
   */
  private handleSuccessWorkflow(): void {
    this.storeConsultationRequestCompletedData();
    this.uploadAttachments().subscribe({
      next: () => {
        const treatmentType = this.sessionStorageService.treatmentType;
        if (!this.isTreatmentTypeValid(treatmentType)) {
          return;
        }

        this.isProcessing = false;
        this.navigationService.navigateToNextConsultationRequestPage(treatmentType);
      },
      error: () => {
        this.isProcessing = false;
        this.responseError = new UploadAttachmentError().message;
      },
    });
  }

  /**
   * Returns true when the treatment is still valid. Otherwise, it will return false.
   *
   * This is a temporary method that will be removed when the cause of treatment type losing data in iOS is found.
   * We have created a custom stack tracker in global window method that will be reverted too.
   *
   * Meanwhile, if the treatment type is null or undefined, we will display a generic error message, so patient can
   * refresh the page and the questionnaire will be pre-filled with the selected options.
   */
  private isTreatmentTypeValid(treatmentType): boolean {
    if (!!treatmentType) {
      return true;
    }

    this.continueErrorMessage =
      'Unable to process your request. Please refresh the page. ' +
      `If you continue experiencing issues, contact ${this.config.email} for assistance.`;

    this.errorLoggingService.logError({
      message: 'Treatment type not found in session storage',
      treatmentType: {
        localStorage: this.storageService.treatmentType,
        sessionStorage: this.sessionStorageService.treatmentType,
      },
      stackTracking: (window as any).stackTracking,
    });

    return false;
  }

  /**
   * Uploads attachments from the medical questions form.
   *
   * This method collects file inputs from the form and uploads them. If there are no files to upload, it returns an
   * empty observable.
   *
   * @returns {Observable<any[]>} an observable that emits an array of responses for each uploaded file
   */
  private uploadAttachments(): Observable<any[]> {
    const observables = this.medicalQuestionsForm.questions
      .filter(({ type }) => type === InputTypes.File)
      .map(({ id }) => {
        const file = this.medicalQuestionsForm.form.get(id).value;
        if (!file) {
          return null;
        }
        return this.createAttachmentObservable(file);
      })
      .filter(Boolean); // Remove null values
    return observables.length ? forkJoin(observables) : of([]);
  }
  /**
   * Creates an observable for uploading a file attachment.
   *
   * @param {File} file the file to be uploaded
   *
   * @returns {Observable<any>} an observable that emits the response of the upload operation
   */
  private createAttachmentObservable(file: File): Observable<any> {
    return this.consultationRequestService.storeAttachment(
      this.sessionStorageService.transactionId,
      this.sessionStorageService.hash,
      this.sessionStorageService.consultationId,
      file
    );
  }

  /**
   * Determines if it has been less than 28 days since the last treatment was prescribed.
   *
   * @returns {boolean} true if it has been less than 28 days, otherwise false
   */
  private isLessThan28DaysSinceLastTreatment(): boolean {
    const lastPrescribedAt = this.consultationRequestOrder.last_prescribed_at;
    if (!lastPrescribedAt) {
      return false;
    }

    return this.getDateDiffInDays(new Date(lastPrescribedAt)) < 28;
  }

  /**
   * Determines if a patient's age makes them eligible for a consultation request.
   *
   * @param {DateData} patientBirthday the patient's date of birth
   *
   * @returns {boolean} true if the patient's age is within the valid range, otherwise false
   */
  private isPatientAgeValid(patientBirthday: DateData): boolean {
    const { day, month, year } = patientBirthday;

    return new DateSelection(+month, +day, +year).isAgeValid(this.minAge, this.maxAge);
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.patientInfoForm.markAllAsTouched();
    this.pharmacyForm.markAllAsTouched();
    this.treatmentPreferencesControl.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    this.questionnaireForm.markAllAsTouched();
    this.upsellForm?.markAllAsTouched();
    this.mentalHealthForm?.markAllAsTouched();
    this.medicalHistoryForm.markAllAsTouched();
    this.stdPreventionTermsForm?.markAllAsTouched();
    this.bmiCalculatorComponent?.form.markAllAsTouched();
  }

  /**
   * Sets the consultation status to disqualified if the patient is not eligible for the consultation.
   */
  protected getConsultationStatus(): ConsultationStatus {
    return this.isLessThan28DaysSinceLastTreatment() ||
      this.genderService.getGenderInvalidError(
        this.sessionStorageService,
        this.sessionStorageService.patient.gender
      ) !== null ||
      !this.isPatientAgeValid(this.sessionStorageService.patient.birthday) ||
      this.bmiCalculatorComponent?.disqualified ||
      this.hasDisqualifyingAnswers()
      ? ConsultationStatus.Disqualified
      : ConsultationStatus.Pending;
  }

  /**
   * Checks if the forms displayed on the page are valid.
   *
   * @returns {boolean} true if all forms are valid, otherwise false
   */
  private shouldContinue(): boolean {
    return (
      this.medicalHistoryForm.valid &&
      this.questionnaireForm.valid &&
      this.patientInfoForm.valid &&
      this.pharmacyForm.valid &&
      this.treatmentPreferencesControl.valid &&
      this.addressForm.valid &&
      (!this.upsellForm || this.upsellForm.valid) &&
      (!this.mentalHealthForm || this.mentalHealthForm.valid) &&
      (!this.stdPreventionTermsForm || this.stdPreventionTermsForm.valid)
    );
  }

  /**
   * Checks if the pharmacy should be updated.
   *
   * @returns {boolean} true if the pharmacy should be updated, otherwise false
   */
  private shouldUpdatePharmacy(): boolean {
    return this.consultationRequest.pharmacy?.id !== this.pharmacyForm.value.id;
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  protected storeConsultationRequestCompletedData(): void {
    super.storeConsultationRequestCompletedData();
    this.sessionStorageService.address = this.addressForm.value;
    this.sessionStorageService.upsells = this.getUpsells();

    if (!this.sessionStorageService.treatmentPreferences) {
      this.defineTreatmentPreferences();
    }
  }

  /**
   * Gets the upsells selected by the patient.
   */
  private getUpsells(): string[] {
    const upsellAnswers = this.sessionStorageService.getQuestionnaireAnswers(QuestionnaireStorageKeys.Upsells);
    if (!upsellAnswers) {
      return [];
    }
    const availableUpsells = Object.values(UpsellSlugs) as string[];

    return Object.values(upsellAnswers).filter((value: string) => availableUpsells.includes(value));
  }

  /**
   * Updates the consultation request pharmacy.
   */
  private updatePharmacy(): void {
    this.consultationRequestService
      .update(
        this.consultationRequest.id,
        this.consultationRequestOrder.transaction_id,
        this.consultationRequestOrder.hash,
        {
          pharmacy_id: this.pharmacyForm.value.id,
        }
      )
      .subscribe({
        next: () => this.handleSuccessWorkflow(),
        error: () => {
          this.isProcessing = false;
          this.responseError = new UpdatePharmacyError().message;
        },
      });
  }
}
