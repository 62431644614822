import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import braintree from 'braintree-web';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaypalService extends ExternalPaymentMethodService {
  private approveData: any;

  protected paypalApproved = new Subject<boolean>();
  paypalApproved$ = this.paypalApproved.asObservable();

  constructor(
    protected braintreeService: BraintreeService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected errorHandlerService: ErrorHandlerService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(errorHandlerService, errorLoggingService);
  }

  /**
   * Checks if the PayPal is available.
   */
  override get isAvailable(): boolean {
    return !!this.globalObject;
  }

  /**
   * Gets the PayPal object from the window object. Injected by the loadPayPalSDK call.
   */
  protected override get globalObject(): any {
    return (window as any).paypal;
  }

  /**
   * Uses the PayPal to charge the user.
   */
  override pay(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.paymentMethodInstance
        .tokenizePayment(this.approveData)
        .then((payload) => resolve(payload.nonce))
        .catch((err: any) => reject(err));
    });
  }

  /**
   * Render the PayPal button.
   *
   * @param {HTMLElement} wrapper the wrapper element to render the PayPal button
   *
   * @returns {Observable<void>} an observable that emits void when the PayPal instance is initialized
   */
  override renderPaymentButton(wrapper: HTMLElement): Observable<void> {
    return new Observable<void>((observer) => {
      this.braintreeService
        .getClient()
        .then((client) => braintree.paypalCheckout.create({ client }))
        .then((paypalCheckout) => paypalCheckout.loadPayPalSDK({ vault: true }))
        .then((paypalInstance) => (this.paymentMethodInstance = paypalInstance))
        .then(() =>
          this.render(`#${wrapper.id}`).subscribe({
            next: () => observer.next(),
            error: (err: any) => observer.error(err),
          })
        )
        .catch((err: any) => observer.error(err));
    });
  }

  /**
   * Renders the PayPal button.
   *
   * @param {string} selector the selector to render the PayPal button
   */
  protected render(selector: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.globalObject
        .Buttons({
          env: this.config.production ? 'production' : 'sandbox',
          style: { label: 'checkout', size: 'small' },
          fundingSource: this.globalObject.FUNDING.PAYPAL,
          createBillingAgreement: () =>
            this.paymentMethodInstance.createPayment({
              flow: 'vault',
              billingAgreementDescription:
                'This is a PayPal billing agreement for charges related to the purchase of lab testing services.',
              enableShippingAddress: false,
            }),
          onApprove: (data) => {
            this.approveData = data;
            this.paypalApproved.next(true);
          },
        })
        .render(selector)
        .then(() => observer.next());
    });
  }
}
