import { Injectable, NgZone } from '@angular/core';
import { AppError } from '@models/app-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  constructor(private zone: NgZone) {}

  /**
   * Logs an error by dispatching a custom event outside Angular's change detection zone.
   *
   * @param {any} error the error to be logged
   */
  logError(error: any): void {
    this.zone.runOutsideAngular(() =>
      window.dispatchEvent(
        new CustomEvent('ng-checkout-error', {
          detail: new AppError(error),
          bubbles: true,
        })
      )
    );
  }
}
