<form [formGroup]="paymentForm">
  <div class="field legacy-field">
    <label class="label choose-payment-method">Choose Payment Method:</label>
    <div class="control mb-4">
      <div class="select is-fullwidth">
        <select
          formControlName="method"
          [id]="!isSecondary ? 'paymentMethod' : 'secondaryPaymentMethod'"
          class="select"
        >
          <option
            *ngFor="let option of paymentMethodOptions"
            [value]="option.value"
            [attr.data-qa-id]="option.label + ' Payment Method'"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <app-payment-method-icons [icons]="paymentMethodIcons"></app-payment-method-icons>
  <div *ngIf="method.value === 'Credit Card'" formGroupName="creditCard">
    <hr />
    <div class="columns is-multiline">
      <div class="column legacy-full-column">
        <div class="field legacy-field">
          <label class="label credit-card-number" [ngClass]="{ invalid: isInvalid(cardNumber) }"
            >Credit Card Number:</label
          >
          <div class="control has-icons-right">
            <input
              formControlName="cardNumber"
              class="input"
              type="tel"
              mask="0000 0000 0000 0999"
              placeholder="xxxx xxxx xxxx xxxx"
              data-qa-id="card-number"
              autocomplete="cc-number"
              (ngModelChange)="setCvvValidator()"
            />
            <span
              class="icon is-right"
              [ngClass]="{
                'has-text-green': !isInvalid(cardNumber) && cardNumber.touched
              }"
              ><i data-qa-id="card-brand-icon" class="{{ cardIcon }}"></i
            ></span>
            <div *ngIf="isInvalid(cardNumber)" class="error">The card number is invalid</div>
            <span class="help is-italic notification-note"> *Charges will appear as <b>ANALYTE HEALTH</b>. </span>
          </div>
        </div>
      </div>

      <div class="column legacy-field">
        <label class="label">Card Expiration:</label>
        <div class="columns is-multiline">
          <div class="column is-8-tablet">
            <div class="select is-fullwidth">
              <select
                formControlName="cardMonth"
                data-qa-id="card-month"
                class="select"
                [ngClass]="{ 'expired-card': isCardExpired() }"
              >
                <option value="">Month</option>
                <option *ngFor="let month of months" [value]="month.number">
                  {{ month.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-4-tablet">
            <div class="select is-fullwidth">
              <select
                formControlName="cardYear"
                data-qa-id="card-year"
                class="select"
                [ngClass]="{ 'expired-card': isCardExpired() }"
              >
                <option value="">Year</option>
                <option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="isInvalid(cardMonth) || isInvalid(cardYear) || isCardExpired()" class="error ml-4 mt-0">
            The expiration date is invalid
          </div>
        </div>
      </div>
    </div>
    <div class="columns" *ngIf="!isInvalid(cardNumber) && cardNumber.touched">
      <div class="column legacy-field">
        <label class="label" [ngClass]="{ invalid: isInvalid(cvv) }">Security Code (CVV):</label>
        <div class="columns is-multiline is-mobile">
          <div class="column is-6">
            <input
              formControlName="cvv"
              class="input"
              placeholder="cvv"
              maxlength="{{ creditCardEntity()?.cvv_length }}"
              data-qa-id="cvv"
              autocomplete="off"
              type="text"
            />
            <div *ngIf="isInvalid(cvv)">
              <div *ngIf="cvv.errors?.required" class="error">The cvv is required</div>
              <div *ngIf="cvv.errors?.minlength || cvv.errors?.pattern" class="error">
                The cvv should be {{ creditCardEntity()?.cvv_length }} digits
              </div>
            </div>
          </div>
          <div
            class="column is-1 is-relative is-flex is-align-items-center"
            *ngIf="!isInvalid(cardNumber) && cardNumber.touched"
          >
            <i class="cvv-info pointer fa fa-info-circle has-text-primary" [ngClass]="{ invalid: isInvalid(cvv) }"></i>
            <div class="cvv-info-container cvv-info-container-arrow">
              <picture>
                <img
                  class="px-1"
                  ngSrc="assets/img/credit-cards/cvv-{{ creditCardCvvImage() }}.png"
                  width="174"
                  height="153"
                  alt="Credit card cvv"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns is-multiline">
      <div class="column legacy-full-column">
        <div class="field legacy-field">
          <label class="label billing-country">Billing Country:</label>
          <div class="control is-expanded">
            <div class="select is-fullwidth">
              <select formControlName="billingCountry" data-qa-id="billing-country" class="select">
                <option *ngFor="let country of countryZipTypes | keyvalue" [ngValue]="country.value.value">
                  {{ country.value.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column" *ngIf="billingCountry.value !== countryZipTypes.other.value">
        <div class="field legacy-field">
          <label class="label" [ngClass]="{ invalid: isInvalid(billingZipCode) }">
            Billing {{ countryZipTypes[billingCountry.value].label }}:
          </label>
          <div class="control">
            <input
              formControlName="billingZipCode"
              class="input"
              data-qa-id="billing-zip-code"
              [placeholder]="countryZipTypes[billingCountry.value].label"
              [type]="billingCountry.value === countryZipTypes.US.value ? 'tel' : 'text'"
            />
            <div *ngIf="isInvalid(billingZipCode)">
              <div *ngIf="billingZipCode.errors?.required" class="error">
                The billing
                {{ countryZipTypes[billingCountry.value].label | lowercase }} is required
              </div>
              <div *ngIf="billingZipCode.errors?.minlength || billingZipCode.errors?.pattern" class="error">
                {{ billingZipCodePatternErrorMessage() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="method.value === 'giftCard'">
    <hr />
    <div class="columns">
      <div class="column is-6 pr-0">
        <div class="field">
          <label class="label"> {{ siteName }} Gift Card Code </label>
          <input
            class="input"
            data-qa-id="gift-card-code"
            type="text"
            placeholder="000000"
            formControlName="giftCardCode"
            [attr.disabled]="giftCardBalance"
          />
          <div *ngIf="isInvalid(giftCardCode)" data-qa-id="gift-card-error">
            <div *ngIf="giftCardCode.errors?.required" class="error">The gift card code is required</div>
            <div *ngIf="giftCardCode.errors?.doesNotExist" class="error">The gift card does not exist</div>
            <div *ngIf="giftCardCode.errors?.noBalance" class="error">The gift card has no balance</div>
            <div *ngIf="giftCardCode.errors?.serverError" class="error">An error occurred. Please try again later.</div>
          </div>
        </div>
      </div>
      <div *ngIf="giftCardBalance && !isGiftCardApplied" data-qa-id="gift-card-balance-row" class="column is-6 pt-6">
        <div class="columns">
          <div class="column is-7 has-text-right">Current Balance:</div>
          <div class="column is-5 has-text-right gift-card-balance">${{ giftCardBalance }}</div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6 pr-0">
        <button
          *ngIf="!giftCardBalance"
          data-qa-id="check-balance-button"
          (click)="checkGiftCardBalance()"
          [disabled]="checkBalanceLoading"
          class="button py-2 is-fullwidth has-text-white gift-card-balance-button has-text-weight-semibold"
        >
          Check Balance
        </button>

        <button
          *ngIf="giftCardBalance"
          data-qa-id="apply-balance-button"
          (click)="applyGiftCard()"
          class="button py-2 is-fullwidth apply-gift-card-button has-text-weight-semibold"
          [ngClass]="{
            'grey-background': isGiftCardApplied,
            'orange-background': !isGiftCardApplied
          }"
          [disabled]="isGiftCardApplied"
        >
          {{ isGiftCardApplied ? 'Applied' : 'Apply to Cart' }}
        </button>
        <button
          *ngIf="!isGiftCardApplied && giftCardBalance"
          class="button is-ghost different-gift-card-button"
          (click)="removeGiftCard()"
        >
          Use a different gift card
        </button>
      </div>
    </div>
  </div>
</form>
