<div
  [formGroup]="formGroup"
  class="columns"
  *ngIf="patientRequiresHeightAndWeight"
  [attr.data-qa-id]="getDynamicDataQaAttribute('height-and-weight-fields')"
>
  <div class="column is-6-desktop is-12-mobile">
    <label class="label has-text-weight-normal">Height:</label>
    <div class="columns is-multiline is-mobile">
      <div class="column is-6-desktop is-6-mobile">
        <div class="field">
          <div class="control suffixed-input">
            <input
              id="height_feet"
              class="input"
              type="number"
              min="1"
              max="8"
              placeholder="Feet"
              formControlName="height_feet"
              [attr.data-qa-id]="getDynamicDataQaAttribute('height-feet')"
            />
            <span>ft</span>
          </div>
          <div
            *ngIf="isControlInvalid('height_feet')"
            class="error"
            [attr.data-qa-id]="getDynamicDataQaAttribute('height-feet-error')"
          >
            {{ generateErrorMessages('height_feet', 'height (feet)') }}
          </div>
        </div>
      </div>
      <div class="column is-6-desktop is-6-mobile">
        <div class="field">
          <div class="control suffixed-input">
            <input
              id="height_inches"
              class="input"
              type="number"
              min="0"
              max="11.99"
              placeholder="Inches"
              formControlName="height_inches"
              [attr.data-qa-id]="getDynamicDataQaAttribute('height-inches')"
            />
            <span>inches</span>
          </div>
          <div
            *ngIf="isControlInvalid('height_inches')"
            class="error"
            [attr.data-qa-id]="getDynamicDataQaAttribute('height-inches-error')"
          >
            {{ generateErrorMessages('height_inches', 'height (inches)') }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column is-6-desktop is-12-mobile">
    <div class="field">
      <label class="label has-text-weight-normal">Weight:</label>
      <div class="control suffixed-input">
        <input
          id="weight"
          class="input"
          type="number"
          min="50"
          max="800"
          placeholder="Weight in lbs"
          formControlName="weight"
          [attr.data-qa-id]="getDynamicDataQaAttribute('weight')"
        />
        <span>lbs</span>
      </div>
      <div
        *ngIf="isControlInvalid('weight')"
        class="error"
        [attr.data-qa-id]="getDynamicDataQaAttribute('weight-error')"
      >
        {{ generateErrorMessages('weight', 'weight (lbs)') }}
      </div>
    </div>
  </div>
</div>
