import { Component, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-pay-later-info',
  templateUrl: './pay-later-info.component.html',
  styleUrls: ['./pay-later-info.component.scss'],
  standalone: true,
})
export class PayLaterInfoComponent {
  phone: string = this.config.phone;

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}
}
