<div *ngIf="!hide" class="columns" [attr.data-qa-id]="question.label + ' Form'">
  <div class="column">
    <app-dynamic-form-question-description (showLabFinderModal)="showLabFinderModal.emit()" [questionId]="question.id">
    </app-dynamic-form-question-description>

    <label *ngIf="question.label" [attr.for]="question.id" class="label">
      {{ question.label }}
    </label>

    <ng-container [ngSwitch]="question.type">
      <app-text-input *ngSwitchCase="InputTypes.Text" [form]="form" [question]="question"></app-text-input>
      <app-textarea-input *ngSwitchCase="InputTypes.Textarea" [form]="form" [question]="question"></app-textarea-input>
      <app-radio-input *ngSwitchCase="InputTypes.Radio" [form]="form" [question]="question"></app-radio-input>
      <app-checkbox-input *ngSwitchCase="InputTypes.Checkbox" [form]="form" [question]="question"></app-checkbox-input>
      <app-select-input *ngSwitchCase="InputTypes.Select" [form]="form" [question]="question"></app-select-input>
      <app-file-input *ngSwitchCase="InputTypes.File" [form]="form" [question]="question"></app-file-input>
    </ng-container>

    <div *ngFor="let error of errors" class="error" [attr.data-qa-id]="question.label + ' Error'">
      {{ error }}
    </div>

    <div
      *ngFor="let warning of warnings"
      class="error"
      [innerHTML]="warning"
      [attr.data-qa-id]="question.label + ' Warning'"
    ></div>

    <div
      *ngIf="question.hint"
      class="hint"
      [ngClass]="{ emphasized: question.hint.emphasized }"
      [attr.data-qa-id]="question.label + ' Hint'"
      [innerHTML]="question.hint.message"
    ></div>
  </div>
</div>
