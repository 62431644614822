<div class="main container">
  <div class="columns">
    <div class="column is-10 is-offset-1">
      <div class="notification finish-notification has-text-centered is-success">
        <h5 class="title is-5">YOU MUST FINISH THE FORM BELOW TO PROCEED WITH YOUR ORDER.</h5>
      </div>
      <app-card
        title="Required Contact Information"
        headerClasses="p-4 is-size-3 has-text-weight-bold is-size-4-mobile card-header-title"
        contentClasses="p-4 card-body-background"
      >
        <div class="columns" *ngIf="!isPhoneOrder">
          <div class="column">
            <h6 class="title is-6">Why do we ask for this?</h6>
            <p>
              If we are unable to reach you via email to notify you of a positive result, a phone number is required as
              secondary contact method.
            </p>
          </div>
        </div>
        <hr />
        <div id="addressPhoneContainer" *ngIf="!isPhoneOrder">
          <h3 class="title">Phone</h3>
          <app-order-phone-inputs></app-order-phone-inputs>
          <hr />
        </div>
        <div>
          <h3 class="title">Address</h3>
          <p class="pb-3">
            <small> Federal law requires a street address. <strong>You will never be mailed anything</strong>. </small>
          </p>
          <app-address-inputs [form]="addressForm"></app-address-inputs>
        </div>
        <hr />
        <div>
          <form [formGroup]="addressContactInformation">
            <div class="columns">
              <div class="column is-12">
                <label for="tos" class="checkbox">
                  <input id="tos" type="checkbox" formControlName="tos" />
                  I agree to {{ domain }}'s
                  <a href="{{ tosLinkAddress }}" target="_blank" data-qa-id="ToS Link"
                    ><strong>Terms of Service</strong></a
                  >
                </label>
                <div class="error" *ngIf="isInvalid(addressContactInformation.get('tos'))">
                  You must agree to terms of service
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-card>
      <app-additional-recommended-testing
        *ngIf="upsellTests?.length > 0 && upsellTestsForm"
        class="is-block mt-4"
        [upsellTests]="upsellTests"
        [upsellTestsForm]="upsellTestsForm"
        (upsellSelectedEvent)="handleUpsellSelectedEvent($event)"
      >
      </app-additional-recommended-testing>
      <ng-container *ngIf="displayPaymentInfoCard">
        <app-upsell-payment-info
          class="is-block mt-4"
          [upsellTestsForm]="upsellTestsForm"
          [selectedUpsell]="[upsellSelected]"
        ></app-upsell-payment-info>
        <app-place-order
          #placeOrder
          class="is-block mt-4 mb-5"
          [chargeAmount]="(upsellSelected.upsell_price || upsellSelected.price) / 100"
          [hideWhatHappensNext]="true"
          [orderForm]="upsellTestsForm"
          [formGroupsToBeValidated]="[]"
          [placeOrderButtonText]="'Finish Placing My Order'"
          (formSubmitted)="submitUpsellPayment()"
        ></app-place-order>
      </ng-container>
      <div class="columns mt-1" *ngIf="!displayPaymentInfoCard">
        <div class="column is-12">
          <app-card contentClasses="p-3 card-body-background" headerClasses="is-hidden">
            <button
              class="button is-fullwidth is-success has-background-green action is-large"
              id="submit-address-button"
              [disabled]="processing"
              (click)="submit()"
            >
              Finish Placing My Order
              <i
                [ngClass]="processing ? 'fa-spinner fa-pulse' : 'fa-arrow-right'"
                class="fa ml-3"
                aria-hidden="true"
              ></i>
            </button>
          </app-card>
        </div>
      </div>
      <div class="columns" *ngIf="isInvalid(addressContactInformation) && submittedForm">
        <div class="column is-12">
          <app-error-notification classes="is-fullwidth notification-error p-3 has-text-black mb-5">
            {{ incompleteFormErrorMessage }}
          </app-error-notification>
        </div>
      </div>
      <app-error-notification
        title="Error:"
        classes="has-text-black mb-5"
        *ngIf="submissionErrors.length > 0"
        data-qa-id="submission errors"
      >
        <div *ngFor="let errorMessage of submissionErrors">
          <p [innerHTML]="errorMessage"></p>
        </div>
      </app-error-notification>
    </div>
  </div>
</div>
