<div class="columns is-mobile is-centered my-1">
  <img
    *ngIf="displayButton"
    id="bitpay-button"
    ngSrc="assets/img/bp-btn-pay-currencies.svg"
    alt="Pay with BitPay"
    class="is-clickable"
    width="250"
    height="80"
    headerRowOutlet
    (click)="onButtonClicked($event)"
  />
  <div *ngIf="!displayButton" class="has-text-centered">
    <h3><em class="fa fa-spinner fa-spin"></em> {{ status || 'Loading BitPay, please wait....' }}</h3>
  </div>
</div>
