import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { RestrictedNavigationService } from '@services/restricted-navigation.service';

@Component({
  selector: 'app-treatmyuti-layout',
  templateUrl: './treatmyuti-layout.component.html',
  styleUrls: ['./treatmyuti-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, DatePipe, NgOptimizedImage],
})
export class TreatmyutiLayoutComponent implements OnInit {
  restrictedNavigation: boolean = false;
  today: Date = new Date();

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private restrictedNavigationService: RestrictedNavigationService
  ) {}

  ngOnInit(): void {
    this.restrictedNavigationService.restrictedNavigationObserver.subscribe((r) => (this.restrictedNavigation = r));
  }
}
