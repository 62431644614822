<form [formGroup]="orderPhoneForm">
  <div class="columns">
    <div class="column is-12-mobile is-6-desktop">
      <label class="label" for="phoneNumber">Phone Number:</label>
      <input
        id="phoneNumber"
        formControlName="number"
        type="tel"
        class="input"
        placeholder="(xxx) xxx-xxxx"
        mask="(000) 000-0000"
        autocomplete="tel"
      />
      <div *ngIf="isInvalid('number')" class="error">
        <span *ngIf="hasSpecificError('required', 'number'); else phonePatternError">
          Your phone number is required
        </span>

        <ng-template #phonePatternError>
          <ng-container *ngIf="hasSpecificError('pattern', 'number'); else inactivePhoneError">
            Please enter a valid 10-digit phone number
          </ng-container>
        </ng-template>

        <ng-template #inactivePhoneError> Please enter an active phone number </ng-template>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column is-4 is-flex is-flex-direction-column">
      <label class="label">May we leave you a voicemail?</label>
      <div>
        <label for="vmAllowed" class="radio">
          <input
            id="vmAllowed"
            type="radio"
            [value]="true"
            formControlName="voicemail_allowed"
            name="voicemail_allowed"
          />
          Yes
        </label>
        <label for="vmNotAllowed" class="radio">
          <input
            id="vmNotAllowed"
            type="radio"
            [value]="false"
            formControlName="voicemail_allowed"
            name="voicemail_allowed"
          />
          No
        </label>
      </div>
    </div>
    <div class="column is-8 is-flex is-flex-direction-column">
      <label class="label">May we send you order updates through text message (SMS)?</label>
      <div>
        <label for="smsAllowed" class="radio">
          <input id="smsAllowed" type="radio" [value]="true" formControlName="sms_allowed" name="sms_allowed" />
          Yes
        </label>
        <label for="smsNotAllowed" class="radio">
          <input id="smsNotAllowed" type="radio" [value]="false" formControlName="sms_allowed" name="sms_allowed" />
          No
        </label>
      </div>
    </div>
  </div>
</form>
