<app-upsell-card
  [htmlTitle]="htmlTitle"
  question="Would you like to add this follow-up STI test to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    After completing your course of antibiotics, our physicians highly recommend an STI test to ensure the bacteria
    causing your STI has been fully eradicated.
    <strong
      >Failing to take this test may result in the recurrence of the STI, potentially in an antibiotic-resistant
      form.</strong
    >
  </p>
  <p>
    Taking this extra step will provide <strong>reassurance</strong> that you're completely free of infection. We have
    partnered with thousands of
    <span data-qa-id="Labs Across the Country Button" class="modalLink" (click)="toggleLabFinderModal()"
      >labs across the country</span
    >
    that can conduct this urine test and provide results within 24 hours.
  </p>
</app-upsell-card>

<app-lab-finder-modal
  *ngIf="showLabFinderModal"
  [(active)]="showLabFinderModal"
  searchDescription="To find the nearest lab for your follow-up STI test, please enter your zip code below. These labs do not require an appointment."
></app-lab-finder-modal>
