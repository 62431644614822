<app-upsell-card
  htmlTitle="<strong>Relieve Outbreak Pain</strong> with Lidocaine Ointment"
  question="Would you like to add it to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    Outbreak pain can be distressing, but you don't have to endure it. <strong>Lidocaine Ointment</strong> offers fast,
    targeted relief by <strong>numbing the area and reducing discomfort</strong>, so you can focus on recovery without
    unnecessary pain.
  </p>
</app-upsell-card>
