<app-card
  title="Contact Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <form [formGroup]="contactInfoForm" data-qa-id="contact-information-form">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label for="email" class="label has-text-weight-normal">E-mail:</label>
          <div class="control">
            <input
              id="email"
              class="input"
              type="text"
              placeholder="Enter your email address"
              formControlName="email"
              required="required"
            />
          </div>
          <div *ngIf="invalidControl('email')" class="error">
            <div *ngIf="contactInfoForm.get('email').errors.required">E-mail is required</div>
            <div *ngIf="contactInfoForm.get('email').errors.email">Invalid e-mail</div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label for="phone" class="label has-text-weight-normal">Phone Number:</label>
          <div class="control">
            <input
              id="phone"
              class="input"
              type="tel"
              placeholder="(xxx) xxx-xxxx"
              formControlName="phone"
              mask="(000) 000-0000"
            />
          </div>
          <div *ngIf="invalidControl('phone')" class="error">
            <div data-qa-id="contact-information-phone-required" *ngIf="contactInfoForm.get('phone').errors.required">
              Your phone number is required
            </div>
            <div *ngIf="contactInfoForm.get('phone').errors.pattern">Please enter a valid 10 digit phone number</div>
          </div>
        </div>
        <p
          *ngIf="showContactedByPhoneMessage()"
          data-qa-id="contact-information-contacted-by-phone"
          class="help is-size-7 is-italic"
        >
          *You will be contacted by phone.
        </p>
      </div>
    </div>
  </form>
</app-card>
