import { Inject, Injectable } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private domainService: DomainService) {}

  /**
   * Gets the invalid gender error message if the provided gender is disallowed for the current treatment type.
   *
   * @param {StorageService} storageService The storage service used to get the treatment type.
   * @param {Gender} userGender The gender to check against the disallowed list.
   *
   * @returns {string | null} The invalid gender error message if disallowed, otherwise null.
   */
  getGenderInvalidError(storageService: StorageService, userGender: Gender): string | null {
    const treatmentType = storageService.treatmentType;
    if (!treatmentType || !this.isTreatmentGenderDisallowed(treatmentType, userGender)) {
      return null;
    }

    return (
      this.config.treatmentFormSettings?.[treatmentType]?.genderError ||
      'This treatment is not available for the selected gender.'
    );
  }

  /**
   * Checks if the provided gender is disallowed for the given treatment type based on configuration.
   *
   * @param {ConsultationTreatmentTypes} treatmentType The treatment type to check against.
   * @param {Gender} userGender The gender to check.
   */
  private isTreatmentGenderDisallowed(treatmentType: ConsultationTreatmentTypes, userGender: Gender): boolean {
    const treatmentSettings = this.config.treatmentFormSettings?.[treatmentType];

    return !!treatmentSettings?.gendersDisallowed?.length && treatmentSettings.gendersDisallowed.includes(userGender);
  }
}
