<div class="choose-a-pharmacy test-center">
  <app-card
    title="Choose a Pharmacy"
    headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
    contentClasses="p-0"
  >
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="pb-3" *ngIf="!selectedPharmacy || changingLab">
            <findalab-map *ngIf="map" [mapConfigInput]="map"></findalab-map>
          </div>
          <app-selected-center-card
            *ngIf="selectedPharmacy && !changingLab"
            [label]="'Pharmacy'"
            [center]="selectedPharmacy"
            (changeCenterEvent)="changingLab = !changingLab"
          >
          </app-selected-center-card>
        </div>
      </div>
    </div>
  </app-card>
</div>
