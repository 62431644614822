<div class="consultation-request" *ngIf="!loadingService.isLoading">
  <div class="container consultation-request-container" *ngIf="errorMessage">
    <div class="columns is-centered mx-0">
      <div class="column is-10">
        <div class="my-4">
          <app-error-notification classes="is-fullwidth notification-error p-3 has-text-black mb-5">
            <p class="is-inline" [innerHTML]="errorMessage"></p>
          </app-error-notification>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!errorMessage">
    <app-consultation-request-header></app-consultation-request-header>
    <div class="container consultation-request-container mt-2">
      <div class="columns is-centered mx-0">
        <div class="column is-10 pt-0" id="consultation-request-container">
          <div class="my-4">
            <app-consultation-request-patient-info
              [patientInfoForm]="patientInfoForm"
            ></app-consultation-request-patient-info>
          </div>
          <div class="my-4">
            <app-card
              title="Medical Questionnaire"
              headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
              contentClasses="p-4"
            >
              <app-dynamic-questionnaire-form
                data-qa-id="Medical Questions Form"
                #medicalQuestionsForm
                *ngIf="medicalQuestionIds.length"
                [sessionKey]="consultationRequestTreatmentType"
                [indexedDbName]="indexedDbName"
                [questionIds]="medicalQuestionIds"
                [externalData]="externalData"
              ></app-dynamic-questionnaire-form>
              <app-bmi-calculator
                #bmiCalculatorComponent
                [form]="patientInfoForm"
                data-qa-id="BMI Calculator"
              ></app-bmi-calculator>
              <ng-container *ngIf="mentalQuestionIds.length">
                <div class="questionnaire-section-title">
                  Over the last 2 weeks, how often have you been bothered by the following problems?
                </div>
                <app-dynamic-questionnaire-form
                  data-qa-id="Mental Questions Form"
                  #mentalQuestionsForm
                  [sessionKey]="QuestionnaireStorageKeys.MentalQuestions"
                  [questionIds]="mentalQuestionIds"
                  [externalData]="externalData"
                ></app-dynamic-questionnaire-form>
              </ng-container>
              <app-medical-history-questions
                #medicalHistoryQuestionsForm
                [allergiesDetailsInitialValue]="consultationRequest?.allergies"
                [medicationDetailsInitialValue]="consultationRequest?.prescriptions"
                [otherConditionsDetailsInitialValue]="consultationRequest?.health_info"
              ></app-medical-history-questions>
              <app-consultation-request-address
                [title]="consultationRequestAddressTitle"
                [addressForm]="addressForm"
              ></app-consultation-request-address>
              <app-dynamic-questionnaire-form
                data-qa-id="Upsell Questions Form"
                #upsellQuestionsForm
                *ngIf="upsellIds.length"
                [sessionKey]="QuestionnaireStorageKeys.Upsells"
                [questionIds]="upsellIds"
                [externalData]="externalData"
                (showLabFinderModal)="presentLabFinderModal()"
              ></app-dynamic-questionnaire-form>
            </app-card>
          </div>
          <div class="my-4">
            <p
              *ngIf="shouldDisplayBottomError"
              data-qa-id="Questionnaire Continue Button Error"
              class="has-text-centered has-text-weight-ultrabold mb-3 has-text-danger"
            >
              {{ submissionError || responseError }}
            </p>
            <button
              (click)="continue()"
              class="continue-button button is-rounded is-primary"
              data-qa-id="Questionnaire Continue Button"
              [disabled]="isProcessing || continueErrorMessage"
            >
              <i *ngIf="isProcessing" class="fa fa-spin fa-spinner"></i>
              <span *ngIf="!isProcessing">Continue</span>
            </button>
            <p
              data-qa-id="Continue Button Error Message"
              *ngIf="continueErrorMessage"
              class="has-text-danger has-text-centered mt-3"
            >
              {{ continueErrorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
