import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { StorageService } from '@services/storage.service';

/**
 * Guard that controls access to the order page based on domain-specific conditions and the current state of the
 * application.
 */
@Injectable({
  providedIn: 'root',
})
export class OrderGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private domainService: DomainService,
    private router: Router,
    private storageService: StorageService,
    private errorLoggingService: ErrorLoggingService
  ) {}

  /**
   * Determines if the order page can be activated based on domain-specific rules and the current application state.
   */
  canActivate(): boolean {
    if (this.shouldRedirectToTests()) {
      this.document.location.href = this.config.siteUrls.tests;

      return false;
    }

    if (this.shouldRedirectToOrderAddress()) {
      this.router.navigateByUrl('/order-address.php');

      return false;
    }

    return true;
  }

  /**
   * Checks if the user should be redirected to the tests page based on domain-specific conditions.
   */
  private shouldRedirectToTests(): boolean {
    if (
      (this.domainService.isTreatMyUtiDomain() || this.domainService.isStarfishDomain()) &&
      !this.storageService.tests?.length
    ) {
      this.errorLoggingService.logError('Redirect to tests: No tests in storage');

      return true;
    }

    if (this.domainService.isSTDCheckDomain() && !this.hasTests() && !this.hasReorder()) {
      this.errorLoggingService.logError('Redirect to tests: No tests or reorder data');

      return true;
    }

    return false;
  }

  /**
   * Checks if the user should be redirected to the order address page.
   */
  private shouldRedirectToOrderAddress(): boolean {
    return (
      !this.domainService.isTreatMyUtiDomain() &&
      !this.domainService.isStarfishDomain() &&
      this.storageService.transactionId &&
      !this.hasReorder()
    );
  }

  /**
   * Checks if tests are selected either through URL parameters or storage.
   */
  private hasTests(): boolean {
    const url = new URL(this.document.location.href);

    return (
      ['panel', 'tests', 'tests[]', 'panel[]'].some((param) => !!url.searchParams.get(param)) ||
      !!this.storageService.tests?.length
    );
  }

  /**
   * Checks if the current request is a reorder.
   */
  private hasReorder(): boolean {
    const url = new URL(this.document.location.href);

    return !!url.searchParams.get('reorderId') && !!this.storageService.getCookie('account_token');
  }
}
