<form [formGroup]="form">
  <div class="columns">
    <div class="column mb-0" [ngClass]="shouldDisplayStreetAddress2 ? 'is-6' : 'is-12'">
      <label class="label" for="streetAddress">Street Address:</label>
      <input
        id="streetAddress"
        type="text"
        formControlName="streetAddress"
        class="input"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="off"
        placeholder="Enter Your Street Address"
        #streetAddress
        (focusin)="showGoogleMapsAddressAutocomplete()"
        (focusout)="hideGoogleMapsAddressAutocomplete()"
      />
      <div class="error" *ngIf="isInvalid('streetAddress')">Your street address is required</div>
    </div>
    <div class="column is-6 mb-0" *ngIf="shouldDisplayStreetAddress2">
      <label class="label" for="streetAddress">Address Line 2:</label>
      <input
        id="streetAddress2"
        type="text"
        formControlName="streetAddress2"
        class="input"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="off"
        placeholder="Enter Your Address Line 2"
      />
    </div>
  </div>
  <div class="columns">
    <div class="column is-4">
      <label class="label" for="city">City:</label>
      <input id="city" type="text" formControlName="city" class="input" placeholder="City" />
      <div *ngIf="isInvalid('city')" class="error">Your city is required</div>
    </div>
    <div class="column is-4">
      <label class="label" for="state">State <span *ngIf="multiregion"> / Province</span>:</label>
      <div class="select is-fullwidth">
        <select id="state" formControlName="state">
          <option [value]="null" selected disabled>Choose your state</option>
          <ng-container *ngIf="!multiregion">
            <option *ngFor="let state of Address.statesUS" [value]="state.abbv">{{ state.name }}</option>
          </ng-container>
          <ng-container *ngIf="multiregion">
            <optgroup label="United States">
              <option *ngFor="let state of Address.statesUS" [value]="state.abbv">{{ state.name }}</option>
            </optgroup>
            <optgroup label="Canada">
              <option *ngFor="let state of Address.statesCA" [value]="state.abbv">{{ state.name }}</option>
            </optgroup>
          </ng-container>
        </select>
      </div>
      <div class="error" *ngIf="isInvalid('state') && hasSpecificError('required', 'state')">
        Your state is required
      </div>
    </div>
    <div class="column is-4">
      <label class="label" for="zipcode">Zip Code<span *ngIf="multiregion"> / Postal Code</span>:</label>
      <input
        id="zipcode"
        [type]="zipCodeInputType"
        formControlName="zipcode"
        class="input"
        [placeholder]="zipCodePlaceholder"
      />
      <div class="error" *ngIf="isInvalid('zipcode')">
        <span *ngIf="hasSpecificError('required', 'zipcode'); else zipcodePatternError">
          Your zip code is required
        </span>
        <ng-template #zipcodePatternError>
          A valid US 5-digit zip code<span *ngIf="multiregion"> or Canadian postal code </span> is required
        </ng-template>
      </div>
    </div>
  </div>
</form>
