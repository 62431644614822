import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-alleviate-nausea-and-vomiting',
  templateUrl: './alleviate-nausea-and-vomiting.component.html',
  styleUrls: ['./alleviate-nausea-and-vomiting.component.scss'],
  standalone: true,
  imports: [UpsellCardComponent, NgIf],
})
export class AlleviateNauseaAndVomitingComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  readonly slug: UpsellSlugs = UpsellSlugs.OndansetronPill;
  readonly options: SelectableOption[] = [
    new SelectableOption('Yes, add Zofran in pill form', this.slug),
    new SelectableOption('Yes, add Zofran in dissolvable tablet form', UpsellSlugs.OndansetronDissolvableTablet),
    new SelectableOption("No, I don't want to include Zofran", QuestionnaireAnswers.No),
  ];

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }
}
