<section class="section">
  <div class="container">
    <div class="card">
      <div class="card-inner-container">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <ng-container *ngIf="iconClass || imagePath">
            <div *ngIf="iconClass; else imageIcon">
              <i class="card-icon" [ngClass]="iconClass" data-qa-id="Card Icon"></i>
            </div>
            <ng-template #imageIcon>
              <img class="card-image" [src]="imagePath" data-qa-id="Card Image" />
            </ng-template>
          </ng-container>
          <div *ngIf="cardTitle" class="card-title" data-qa-id="Card Title">{{ cardTitle }}</div>
        </div>
        <div data-qa-id="Icon And Button Card Content">
          <ng-content></ng-content>
        </div>
        <ng-content select="[button-container]"></ng-content>
      </div>
    </div>
  </div>
</section>
