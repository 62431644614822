import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { LoadingComponent } from '@components/loading/loading.component';
import { PhoneOrderUserBannerComponent } from '@components/phone-order-user-banner/phone-order-user-banner.component';
import { HealthlabsLayoutComponent } from '@layout/healthlabs-layout/healthlabs-layout.component';
import { StarfishLayoutComponent } from '@layout/starfish-layout/starfish-layout.component';
import { StdcheckLayoutComponent } from '@layout/stdcheck-layout/stdcheck-layout.component';
import { TreatmyutiLayoutComponent } from '@layout/treatmyuti-layout/treatmyuti-layout.component';
import { DomainService } from '@services/domain.service';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    HealthlabsLayoutComponent,
    LoadingComponent,
    NgTemplateOutlet,
    PhoneOrderUserBannerComponent,
    StdcheckLayoutComponent,
    TreatmyutiLayoutComponent,
    StarfishLayoutComponent,
  ],
})
export class SiteLayoutComponent {
  /** Loading status */
  isLoading: boolean = false;

  constructor(public domainService: DomainService, private loadingService: LoadingService) {
    this.loadingService.loading$.subscribe((loadingStatus) => (this.isLoading = loadingStatus));
  }
}
