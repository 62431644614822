<app-card
  title="Personal Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <form [formGroup]="personalInfoForm" data-qa-id="personal-information-form">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label for="firstName" class="label has-text-weight-normal">First Name:</label>
          <div class="control">
            <input
              id="firstName"
              class="input"
              type="text"
              placeholder="Enter Your First Name"
              formControlName="firstName"
              required="required"
              data-qa-id="Personal First Name Input"
            />
          </div>
          <div *ngIf="invalidControl('firstName')" class="error" data-qa-id="Personal First Name Error">
            {{ getInvalidControlErrorMessage('firstName', 'Your first name') }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label for="lastName" class="label has-text-weight-normal">Last Name:</label>
          <div class="control">
            <input
              id="lastName"
              class="input"
              type="text"
              placeholder="Enter Your Last Name"
              formControlName="lastName"
              required="required"
              data-qa-id="Personal Last Name Input"
            />
          </div>
          <div *ngIf="invalidControl('lastName')" class="error" data-qa-id="Personal Last Name Error">
            {{ getInvalidControlErrorMessage('lastName', 'Your last name') }}
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column" formGroupName="birthday">
        <label class="label has-text-weight-normal">Date of Birth:</label>
        <div class="columns is-multiline is-mobile">
          <div class="column is-6-desktop is-12-mobile">
            <div class="select is-fullwidth">
              <select
                formControlName="month"
                [ngClass]="{
                  error: invalidControl('birthday.month')
                }"
                data-qa-id="birthdate-month"
                class="select"
              >
                <option [value]="null" selected disabled>Month</option>
                <option *ngFor="let month of calendarOptions?.months" [value]="month.number">
                  {{ month.number }} - {{ month.name }}
                </option>
              </select>
            </div>
            <div *ngIf="invalidControl('birthday.month')" class="error">Your birth month is required</div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select
                formControlName="day"
                [ngClass]="{
                  error: invalidControl('birthday.day')
                }"
                data-qa-id="birthdate-day"
                class="select"
              >
                <option [value]="null" selected disabled>Day</option>
                <option *ngFor="let day of calendarOptions?.days" [value]="day">{{ day }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('birthday.day')" class="error">Your day of birth is required</div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select
                formControlName="year"
                [ngClass]="{
                  error: invalidControl('birthday.year')
                }"
                data-qa-id="birthdate-year"
                class="select"
              >
                <option [value]="null" selected disabled>Year</option>
                <option *ngFor="let year of calendarOptions?.years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('birthday.year')" class="error">Your year of birth is required</div>
          </div>
        </div>
        <div *ngIf="invalidControl('birthday') && birthdateFilled()" class="error" data-qa-id="Personal DOB Error">
          <div *ngIf="personalInfoForm.get('birthday')?.errors?.invalidDate">
            {{ personalInfoForm.get('birthday').errors.invalidDate }}
          </div>
        </div>
      </div>
    </div>
    <app-height-weight-input [dob]="dob" [form]="personalInfoForm"></app-height-weight-input>
  </form>
</app-card>
