import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cookies-disabled',
  templateUrl: './cookies-disabled.component.html',
  styleUrls: ['./cookies-disabled.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class CookiesDisabledComponent {
  private window: Window & typeof globalThis;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  /**
   * Reloads the page.
   */
  reloadPage(): void {
    this.window.location.reload();
  }
}
