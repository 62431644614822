<app-upsell-card
  htmlTitle="<strong>Rapid Relief</strong> for Yeast Infections"
  question="We highly recommend including Clotrimazole Vaginal Cream in your prescription. Would you like to add it to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    Yeast infections can cause significant discomfort and disruption to your daily life. However, there's a quick and
    effective solution: <strong>Clotrimazole Vaginal Cream.</strong>
  </p>
  <p class="mb-4">
    This powerful antifungal treatment works to alleviate the symptoms of yeast infections, providing you with rapid
    relief. By choosing <strong>Clotrimazole</strong>, you're not just alleviating the symptoms but also ensuring a
    swift return to your regular activities and comfort.
  </p>
</app-upsell-card>
