import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-clotrimazole-vaginal-cream',
  templateUrl: './clotrimazole-vaginal-cream.component.html',
  styleUrl: './clotrimazole-vaginal-cream.component.scss',
  standalone: true,
  imports: [UpsellCardComponent],
})
export class ClotrimazoleVaginalCreamComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.ClotrimazoleVaginalCream;

  constructor() {}
}
