import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { CheckboxInputComponent } from '@components/dynamic-forms/checkbox-input/checkbox-input.component';
import { DynamicFormQuestionDescriptionComponent } from '@components/dynamic-forms/dynamic-form-question-description/dynamic-form-question-description.component';
import { FileInputComponent } from '@components/dynamic-forms/file-input/file-input.component';
import { RadioInputComponent } from '@components/dynamic-forms/radio-input/radio-input.component';
import { SelectInputComponent } from '@components/dynamic-forms/select-input/select-input.component';
import { TextInputComponent } from '@components/dynamic-forms/text-input/text-input.component';
import { TextareaInputComponent } from '@components/dynamic-forms/textarea-input/textarea-input.component';
import { InputTypes } from '@enums/input-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { Question } from '@models/dynamic-forms/question';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';

/**
 * Component for rendering a dynamic form question.
 */
@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgForOf,
    NgSwitchCase,
    TextInputComponent,
    RadioInputComponent,
    TextareaInputComponent,
    CheckboxInputComponent,
    SelectInputComponent,
    FileInputComponent,
    DynamicFormQuestionDescriptionComponent,
    NgClass,
  ],
})
export class DynamicFormQuestionComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() form: FormGroup;
  @Input() set externalData(value: ExternalData) {
    if (JSON.stringify(this.externalData) === JSON.stringify(value)) {
      return;
    }

    this._externalData = value;
    this.setHideQuestion();
  }
  @Output() showLabFinderModal: EventEmitter<void> = new EventEmitter();

  InputTypes: typeof InputTypes = InputTypes;

  private subscriptions: Subscription[] = [];
  private _externalData: ExternalData;
  private _hide: boolean = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private dynamicFormsService: DynamicFormsService,
    private sessionStorageService: SessionStorageService
  ) {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setHideQuestion();
    this.initializeVisibilitySubscriptions();
    this.initializeExtraStorageSubscriptions();
    this.control.updateValueAndValidity();
  }

  /**
   * Unsubscribes from all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Gets the form control associated with the question.
   *
   * @returns {AbstractControl} the form control
   */
  get control(): AbstractControl {
    return this.form.get(this.question.id);
  }

  /**
   * Gets the validation errors for the question's form control.
   *
   * @returns {string[]} an array of error messages
   */
  get errors(): string[] {
    if (!this.invalid) {
      return [];
    }

    return this.dynamicFormsService.getQuestionVisibleErrors(this.question, this.form);
  }

  /**
   * Gets the external data that could affect the visibility of the question.
   *
   * @returns {ExternalData} the external data
   */
  get externalData(): ExternalData {
    return this._externalData;
  }

  /**
   * Gets whether the question should be hidden.
   *
   * @returns {boolean} true if the question should be hidden, false otherwise
   */
  get hide(): boolean {
    return this._hide;
  }

  /**
   * Checks if the question's form control is invalid.
   *
   * @returns {boolean} true if the control is invalid, false otherwise
   */
  get invalid(): boolean {
    return this.isInvalid(this.control);
  }

  /**
   * Gets the warning messages for the question based on the current value.
   *
   * @returns {string[]} an array of warning messages
   */
  get warnings(): string[] {
    return this.dynamicFormsService.getQuestionVisibleWarnings(this.question, this.form);
  }

  /**
   * Sets whether the question should be hidden.
   *
   * @param {boolean} value whether the question should be hidden
   */
  set hide(value: boolean) {
    if (value === this._hide) {
      return;
    }

    if (value) {
      this.clearFormArray();
      this.control.clearValidators();
      this.control.reset();
    } else {
      this.control.addValidators(this.dynamicFormsService.getQuestionValidators(this.question));
    }

    this.control.updateValueAndValidity();
    this._hide = value;
  }

  /**
   * Clears the form array if the question is a FormArray.
   */
  private clearFormArray(): void {
    if (!(this.control instanceof FormArray)) {
      return;
    }

    this.control.clear();
  }

  /**
   * Sets the hidden state of the question based on the visibility trigger questions.
   */
  private setHideQuestion(): void {
    setTimeout(
      () =>
        (this.hide = !this.dynamicFormsService.checkQuestionVisibility(this.question, this.form, this.externalData)),
      0
    );
  }

  /**
   * Initializes subscriptions for visibility triggers.
   */
  private initializeVisibilitySubscriptions(): void {
    this.question.visibility.triggerQuestions.questions.forEach((triggerQuestion) =>
      this.subscriptions.push(this.form.get(triggerQuestion.id).valueChanges.subscribe(() => this.setHideQuestion()))
    );
  }

  /**
   * Initializes subscriptions for extra storages.
   */
  private initializeExtraStorageSubscriptions(): void {
    if (!this.question.extraStorageKey) {
      return;
    }

    this.subscriptions.push(
      this.form.get(this.question.id).valueChanges.subscribe((value) => this.defineExtraStorageValue(value))
    );
  }

  /**
   * Define the extra storage value for the question. If the value is 'No', the value will be set to null.
   *
   * @param value the value of the question
   */
  private defineExtraStorageValue(value: string): void {
    this.sessionStorageService[this.question.extraStorageKey] = value === QuestionnaireAnswers.No ? null : value;
  }
}
