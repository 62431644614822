<div class="consultation-request">
  <div class="primary title schedule-consultation-title-container">
    <div class="container">
      <div class="columns mx-0">
        <div class="column">
          <h1 itemprop="headline" class="is-size-4-mobile has-text-centered" data-qa-id="Headline Section">
            {{ config.titles.scheduledConsultationRequest.title }}
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="columns is-centered mx-0">
      <div class="column is-10" *ngIf="errorMessage">
        <div class="my-4">
          <app-error-notification classes="is-fullwidth notification-error p-3 has-text-black mb-5">
            {{ errorMessage }}
          </app-error-notification>
        </div>
      </div>
      <div class="column is-10" id="schedule-consultation-request-container" *ngIf="!errorMessage">
        <div class="my-4">
          <div class="notification is-primary has-text-centered has-text-weight-bold schedule-information">
            {{ config.titles.scheduledConsultationRequest.calendarDetails }}
          </div>
        </div>
        <div class="my-4">
          <div
            class="notification clinician-notification is-orange has-text-white has-text-centered has-text-weight-bold"
            *ngIf="this.patientPhone"
          >
            {{ config.titles.scheduledConsultationRequest.phoneNumberDetails }} {{ patientPhone }}.
            {{ config.titles.scheduledConsultationRequest.availabilityDetails }}
          </div>
        </div>
        <div class="my-4 iframe-container">
          <!-- ScheduleOnce embed START -->
          <iframe
            *ngIf="this.scheduleOnceId"
            data-qa-id="Oncehub Schedule Iframe"
            height="850"
            [src]="this.scheduleUrl"
          ></iframe>
          <!-- ScheduleOnce embed END -->
        </div>
      </div>
    </div>
  </div>
</div>
