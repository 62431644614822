<div class="columns">
  <div class="column">
    <form [formGroup]="consultationForm">
      <div class="field">
        <div class="is-fullwidth">
          <label class="checkbox">
            <input type="checkbox" name="terms" formControlName="terms" />
            I agree to the
          </label>
          <a data-qa-id="termsToggle" class="ml-1" (click)="toggleTerms()"
            >Notice of Privacy Practices, Informed Consent, and the Terms & Conditions</a
          >.
        </div>
        <div data-qa-id="wmgTerms" class="card p-5 mt-4 tos" *ngIf="showTerms">
          <div *ngIf="wmgTermsContent; else termsError" [innerHTML]="wmgTermsContent | safeHtml"></div>
          <ng-template #termsError>
            <p>
              An error occurred while getting the Notice of Privacy Practices, Informed Consent, and the Terms &
              Conditions.
              <a [href]="tosUrl" target="_blank"> Click here </a>
              to read them.
            </p>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</div>
