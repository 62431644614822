<header>
  <div class="is-hidden-mobile">
    <div id="header-bar" class="is-flex is-align-items-center text">
      <div class="container">
        <div class="columns">
          <div class="column">
            <span>Quick &amp; Confidential STD Testing - STDcheck.com!</span>
          </div>
          <div class="column is-flex is-flex-direction-row is-justify-content-end is-align-items-end">
            <a href="tel:18004562323">
              1-800-456-2323
              <span>24/7 Support</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="is-hidden-tablet py-2">
    <div class="container">
      <div class="row has-text-centered">
        <b>Questions?</b> <a href="tel:18004562323" class="ml-1">Call 1-800-456-2323</a>
      </div>
    </div>
  </div>
  <div id="header-logo" class="p-4">
    <div class="container">
      <div class="columns">
        <div class="column logo-container" data-qa-id="STDcheck Header Logo">
          <img
            *ngIf="restrictedNavigation; else logoLink"
            ngSrc="assets/img/stdcheck_logo.svg"
            alt="STDcheck Logo"
            width="250"
            height="50"
            priority
          />
          <ng-template #logoLink>
            <a *ngIf="!restrictedNavigation" href="https://stdcheck.com/">
              <img ngSrc="assets/img/stdcheck_logo.svg" alt="STDcheck Logo" width="250" height="50" priority />
            </a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="main-content">
  <ng-content></ng-content>
</div>
<footer>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-full">
        <img
          ngSrc="assets/img/stdcheck-mute.svg"
          alt="STDcheck Logo"
          class="stdcheck-logo"
          width="245"
          height="50"
          priority
        />
      </div>
      <div class="column is-half pt-0">
        <p>Copyright &copy; {{ today | date: 'y' }}, STDcheck.com, All Rights Reserved</p>
      </div>
      <div class="column is-half pt-0 has-text-right-tablet made-with-love">
        Made with
        <img ngSrc="assets/img/heart.svg" alt="love" width="24" height="18" loading="lazy" class="love" />
        by the people at Analyte Health
      </div>
    </div>
  </div>
</footer>
