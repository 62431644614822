import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { HeightWeightInputComponent } from '@components/consultation-request/height-weight-input/height-weight-input.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { DiagnosisAnswers } from '@enums/diagnosis-answers';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-bmi-calculator',
  templateUrl: './bmi-calculator.component.html',
  standalone: true,
  imports: [NgIf, HeightWeightInputComponent],
})
export class BmiCalculatorComponent implements OnInit {
  @Input() form: FormGroup;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.addHeightAndWeightValidators();
    this.subscribeToHeightAndWeightChanges();
  }

  /**
   * Adds the height and weight validators to the form controls.
   */
  private addHeightAndWeightValidators(): void {
    this.form.get('height_feet').addValidators(Validators.required);
    this.form.get('height_inches').addValidators(Validators.required);
    this.form.get('weight').addValidators(Validators.required);
  }

  /**
   * Subscribes to the height and weight value changes.
   */
  private subscribeToHeightAndWeightChanges(): void {
    this.form.get('height_feet').valueChanges.subscribe(() => this.handleInputChange());
    this.form.get('height_inches').valueChanges.subscribe(() => this.handleInputChange());
    this.form.get('weight').valueChanges.subscribe(() => this.handleInputChange());
  }

  /**
   * Gets the answers to the diagnosis question.
   */
  get questionDiagnosisAnswers() {
    const diagnosisQuestionId = 144;
    const glpAnswers = this.sessionStorageService.getQuestionnaireAnswers(ConsultationTreatmentTypes.Glp1);

    return glpAnswers?.[diagnosisQuestionId]?.[0];
  }

  /**
   * Determines the qualified BMI score based on the answers to the diagnosis question.
   * If the user answers "No, none of these apply to me" to the diagnosis question, the
   * qualified BMI score is 29. Otherwise, the qualified BMI score is 27.
   */
  get minimumQualifyingBmiScore(): number {
    const BMI_SCORE = {
      NO_SYMPTOMS: 29,
      DEFAULT: 27,
    } as const;

    return this.questionDiagnosisAnswers === DiagnosisAnswers.NONE_APPLY ? BMI_SCORE.NO_SYMPTOMS : BMI_SCORE.DEFAULT;
  }

  /**
   * Gets the disqualified status of the user.
   */
  get disqualified(): boolean {
    return this.bmiScore < this.minimumQualifyingBmiScore;
  }

  /**
   * Gets the height in feet from the control.
   */
  get heightFeet(): number {
    return this.form.get('height_feet').value;
  }

  /**
   * Gets the height in inches from the control.
   */
  get heightInches(): number {
    return this.form.get('height_inches').value;
  }

  /**
   * Calculate the height in inches.
   */
  get height(): number {
    return this.heightFeet * 12 + this.heightInches;
  }

  /**
   * Gets the weight in pounds from the control.
   */
  get weight(): number {
    return this.form.get('weight').value;
  }

  /**
   * Calculate the BMI score based on the weight and height.
   */
  get bmiScore(): number {
    return this.calculateBmiScore(this.weight, this.height);
  }

  /**
   * Calculate the ideal weight based on the BMI score and height.
   */
  get idealWeight(): number {
    return this.calculateWeightFromBmi(this.minimumQualifyingBmiScore, this.height);
  }

  /**
   * Handles the change of the weight or height value.
   */
  handleInputChange(): void {
    if (this.weight && this.heightFeet && this.heightInches !== null) {
      this.sessionStorageService.bmiScore = this.bmiScore;
    }
  }

  /**
   * Calculate the BMI score based on the weight and height.
   * @param {number} weight the weight of the patient in pounds
   * @param {number} height the height of the patient in inches
   */
  private calculateBmiScore(weight: number, height: number): number {
    return Math.round((weight / Math.pow(height, 2)) * 703 * 10) / 10;
  }

  /**
   * Calculate the weight based on the BMI score and height.
   * @param {number} bmi    the BMI score
   * @param {number} height the height of the patient in inches
   */
  private calculateWeightFromBmi(bmi: number, height: number): number {
    return Math.round((bmi * Math.pow(height, 2)) / 703);
  }
}
