import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorLoggingService } from '@services/error-logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  /**
   * Handles any uncaught error in the application.
   *
   * Gets an instance of ErrorLoggingService and delegates the error logging.
   *
   * @param {any} error the error object thrown by the application
   */
  handleError(error: any): void {
    const errorLoggingService = this.injector.get(ErrorLoggingService);

    errorLoggingService.logError(error);

    throw error;
  }
}
