import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { PaypalService } from '@services/external-payments/paypal.service';
import { Observable } from 'rxjs';

@Injectable()
export class PaypalMockService extends PaypalService {
  constructor(
    protected braintreeService: BraintreeService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected errorHandlerService: ErrorHandlerService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(braintreeService, config, errorHandlerService, errorLoggingService);
  }

  /**
   * Mock the PayPal usave to charge the user.
   */
  override pay(): Promise<string> {
    return Promise.resolve('fake-valid-visa-nonce');
  }

  /**
   * Renders the PayPal Mock button.
   *
   * @param {string} selector the selector to render the Mock PayPal button
   */
  protected override render(selector: string): Observable<void> {
    return new Observable<void>((observer) => {
      let buttonWrapper = document.querySelector(selector);
      let buttonUpdated = document.createElement('button');

      buttonUpdated.id = selector.replace('#', '');
      buttonUpdated.innerText = 'PayPal Checkout Mock';
      buttonUpdated.addEventListener('click', () => this.paypalApproved.next(true));

      buttonWrapper.parentNode.replaceChild(buttonUpdated, buttonWrapper);

      observer.next();
    });
  }
}
