<ng-container [formGroup]="form">
  <input
    [attr.data-qa-id]="question.label"
    [formControlName]="question.id"
    [id]="question.id"
    [placeholder]="question.placeholder"
    type="text"
    class="input"
  />
</ng-container>
