import { Inject, Injectable } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { MedicalHistoryQuestionIds } from '@enums/medical-history-question-ids';
import { Phq9Answers } from '@enums/phq-9-answers';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { QuestionnaireStorageKeys } from '@enums/questionnaire-storage-keys';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { ConsultationQuestionAnswer } from '@models/consultation-request/consultation-question-answer';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TreatmentConsultationQuestionnaireService {
  /**
   * The upsell fields that are excluded from the "Additional Requests" question
   */
  protected readonly excludedUpsellsFromQuestion = [
    UpsellSlugs.MetronidazoleVaginalGel as string,
    UpsellSlugs.ClindamycinVaginalCream as string,
    UpsellSlugs.MetforminExtendedRelease as string,
  ];

  constructor(
    private sessionStorageService: SessionStorageService,
    private dynamicFormsService: DynamicFormsService,
    @Inject(APP_CONFIG) protected config: AppConfig
  ) {}

  /**
   * Returns a promise with the ConsultationRequestRequest model that represents the backend payload.
   */
  async getConsultationRequestPayload(): Promise<ConsultationRequestRequest> {
    let consultationRequest = new ConsultationRequestRequest();
    consultationRequest = this.setMedicalHistoryToConsultationPayload(
      consultationRequest,
      this.sessionStorageService.medicalHistoryQuestionnaireAnswers
    );
    consultationRequest.pharmacy_id = this.getPharmacy();
    consultationRequest.first_name = this.sessionStorageService.patient.name;
    consultationRequest.last_name = this.sessionStorageService.patient.last_name;
    consultationRequest.consultation_status = this.sessionStorageService.consultationStatus;
    consultationRequest.additional_info = await this.getAdditionalInfo();
    consultationRequest.date_of_birth = this.getDateOfBirth();

    return consultationRequest;
  }

  /**
   * Builds the upsell object.
   */
  getUpsellsWithPrice(): OrderUpsell[] {
    let upsells = [];

    this.sessionStorageService.upsells.forEach((upsellName: string) =>
      upsells.push({
        id: this.config.consultationRequestUpsells[upsellName].id,
        name: upsellName,
        upsell_price: this.config.consultationRequestUpsells[upsellName].price * 100,
        skipOrderAddon: this.config.consultationRequestUpsells[upsellName].skipOrderAddon ?? false,
      })
    );

    return upsells;
  }

  /**
   * Filters upsells that are not included in the list of ordered upsells stored in session storage.
   *
   * @returns {OrderUpsell[]} an array of unordered upsells
   */
  getUnorderedUpsells(): OrderUpsell[] {
    return this.getUpsellsWithPrice().filter(
      (upsell) => !this.sessionStorageService.orderedUpsells.includes(upsell.name)
    );
  }

  /**
   * Sets the medical history information on the provided consultation request payload.
   *
   * @param {ConsultationRequestRequest} payload the consultation request payload to update
   *
   * @returns {ConsultationRequestRequest} the payload with the medical history set
   * @param answers
   */
  setMedicalHistoryToConsultationPayload(
    payload: ConsultationRequestRequest,
    answers: object
  ): ConsultationRequestRequest {
    payload.allergies = answers[MedicalHistoryQuestionIds.AllergiesDetails] ?? QuestionnaireAnswers.No;
    payload.health_info = answers[MedicalHistoryQuestionIds.OtherConditionsDetails] ?? QuestionnaireAnswers.No;
    payload.prescriptions = answers[MedicalHistoryQuestionIds.MedicationDetails] ?? QuestionnaireAnswers.No;

    return payload;
  }

  /**
   * Parse the treatment preferences into the questionnaire
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the treatment preferences into
   */
  private parseTreatmentPreferencesIntoQuestionnaire(questionnaire: ConsultationQuestionAnswer[]): void {
    questionnaire.unshift({
      question: 'Preferred Medication/Treatment',
      answer: this.sessionStorageService.treatmentPreferences,
    });
  }

  /**
   * Parse the upsells into the questionnaire
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the upsells into
   */
  private parseUpsellsIntoQuestionnaire(questionnaire: ConsultationQuestionAnswer[]) {
    const upsells = this.sessionStorageService.upsells.filter(
      (upsell) => !this.excludedUpsellsFromQuestion.includes(upsell)
    );
    const orderedUpsells = [...upsells, ...this.sessionStorageService.orderedUpsells];

    // Remove duplicates with Set, filter out upsells that don't have a price, and join them with a comma
    const upsellsAnswer = [...new Set(orderedUpsells)]
      .filter((upsell) => !!this.config.consultationRequestUpsells[upsell]?.price)
      .join(', ');

    if (upsellsAnswer) {
      questionnaire.unshift({
        question: 'Additional Requests',
        answer: upsellsAnswer,
      });
    }
  }

  /**
   * Get the additional info for the consultation
   *
   * @returns {Promise<string>} promise that resolves to a JSON string with the additional consultation information
   */
  private async getAdditionalInfo(): Promise<string> {
    const patient = this.sessionStorageService.patient;
    const additional_info = {
      questionnaire: [],
      height: {
        feet: patient.height_feet,
        inches: patient.height_inches,
      },
      weight: patient.weight,
    };

    additional_info.questionnaire = await this.getParsedQuestionsAndAnswers();

    return JSON.stringify(additional_info);
  }

  /**
   * Retrieves and parses the questions and answers from the values stored in sessionStorage.
   *
   * @returns {Promise<ConsultationQuestionAnswer[]>} promise that resolves to an array of parsed question-answer pairs
   */
  private async getParsedQuestionsAndAnswers(): Promise<ConsultationQuestionAnswer[]> {
    const questionsAndAnswers = await this.dynamicFormsService.parseQuestionsAndAnswersFromStorage(
      this.sessionStorageService.treatmentType,
      {
        treatmentName: this.sessionStorageService.getTreatmentPreferenceName(),
      }
    );

    if (this.sessionStorageService.treatmentType === ConsultationTreatmentTypes.Glp1) {
      this.parseGlp1TreatmentTypeScores(questionsAndAnswers);
    }

    this.parseUpsellsIntoQuestionnaire(questionsAndAnswers);
    this.parseTreatmentPreferencesIntoQuestionnaire(questionsAndAnswers);

    return questionsAndAnswers;
  }

  /**
   * Parses the GLP-1 treatment type BMI and PHq-9 scores into the questionnaire.
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the GLP-1 treatment type scores into
   */
  private parseGlp1TreatmentTypeScores(questionnaire: ConsultationQuestionAnswer[]): void {
    this.appendBmiScore(questionnaire);
    this.appendPhq9Score(questionnaire);
  }

  /**
   * Appends the BMI score to the questionnaire.
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the BMI score into
   */
  private appendBmiScore(questionnaire: ConsultationQuestionAnswer[]): void {
    questionnaire.push({
      question: 'BMI Score',
      answer: this.sessionStorageService.bmiScore.toString(),
    });
  }

  /**
   * Appends the PHq-9 score to the questionnaire.
   *
   * @param {ConsultationQuestionAnswer[]} questionnaire the questionnaire to push the GLP-1 score into
   */
  private appendPhq9Score(questionnaire: ConsultationQuestionAnswer[]): void {
    questionnaire.push({
      question: 'PHq-9 Score',
      answer: this.calculatePHQ9Score().toString(),
    });
  }

  /**
   * Calculate the PHq-9 score
   */
  private calculatePHQ9Score(): number {
    return Object.values(this.sessionStorageService.getQuestionnaireAnswers(QuestionnaireStorageKeys.MentalQuestions))
      .map((answer) => parseInt(Phq9Answers[answer]))
      .reduce((sum, value) => sum + value, 0);
  }

  /**
   * Get the pharmacy id
   *
   * If the shipping address is set, it will return the delivery pharmacy id
   */
  private getPharmacy(): number {
    let pharmacyId = this.sessionStorageService.pharmacy.id;

    if (this.sessionStorageService.getUpsellAddress(UpsellSlugs.DeliverMedication)) {
      pharmacyId = this.config.deliveryPharmacyId;
    }

    return pharmacyId;
  }

  /**
   * Get the date of birth in the format of YYYY-MM-DD
   */
  private getDateOfBirth(): string {
    const { year, month, day } = this.sessionStorageService.patient.birthday;

    return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
}
