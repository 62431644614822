<div class="card order-summary" data-qa-id="Order Summary">
  <div class="card-header">
    <div class="card-header-title"><i class="fa fa-shopping-cart"></i> Order Summary</div>
    <div class="card-header-icon" *ngIf="shouldDisplayEditButton">
      <a href="{{ testsUrl }}" data-qa-id="Edit Cart Link"> {{ editCartButtonText }} </a>
    </div>
  </div>
  <div class="card-body cart-background" [ngClass]="isLoading ? 'is-loading' : ''">
    <table class="table is-fullwidth mb-0">
      <thead *ngIf="displayCartSummaryTableHeaders" class="cart-background">
        <tr>
          <th class="has-text-left pl-0">Test</th>
          <th class="has-text-right pr-0">Price</th>
        </tr>
      </thead>
      <tbody class="cart-background">
        <tr>
          <td colspan="3" class="px-0">
            <div [ngClass]="{ 'cart-overflow': domainService.isHealthlabsDomain() }">
              <table class="mb-0 cart-products-container-table" data-qa-id="Products Table">
                <tr *ngFor="let test of tests">
                  <td class="has-text-left pl-0 order-summary-test-item" [attr.data-test-slug]="test.slug">
                    {{ testsPrefix || test.name }}
                    <span *ngIf="testsPrefix && !extraCharges.length" class="test-name-small" data-qa-id="Tests Prefix">
                      <br />{{ test.name }}
                    </span>
                    <span
                      (click)="removeTest(test)"
                      class="remove-test-tag"
                      *ngIf="shouldDisplayRemoveTag"
                      data-qa-id="Remove Test Tag"
                    >
                      <br />Remove
                    </span>
                  </td>
                  <td class="has-text-right pr-0 is-vcentered">{{ test.price | centsCurrency }}</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>

        <ng-container *ngIf="hasPartner">
          <tr *ngFor="let test of tests" class="order-summary-partner-item">
            <td class="has-text-left pl-0">
              {{ test.name }} <strong><small>[ Partner Test ]</small></strong>
            </td>
            <td class="has-text-right pr-0">{{ test.price | centsCurrency }}</td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot class="cart-background">
        <tr *ngIf="storage.hasInHomeCollection" class="in-home-collection">
          <td class="has-text-left pl-0">In-Home Collection Fee</td>
          <td class="has-text-right pr-0">{{ inHomeCollectionCostInCents | centsCurrency }}</td>
        </tr>

        <tr *ngIf="coupon" class="coupon order-summary-coupon-item" data-qa-id="Order Summary Coupon Item">
          <td class="has-text-left pl-0">{{ couponItemName }}</td>
          <td class="has-text-right pr-0">-{{ this.getTotalCoupons() | centsCurrency }}</td>
        </tr>

        <tr *ngIf="discount" class="coupon">
          <td class="has-text-left pl-0">{{ discount.name }} Discount</td>
          <td class="has-text-right pr-0">-{{ discount.value * 100 | centsCurrency }}</td>
        </tr>

        <tr *ngIf="hasPartner" class="coupon">
          <td class="has-text-left pl-0">$10 Off - Partner testing</td>
          <td class="has-text-right pr-0">-{{ 1000 | centsCurrency }}</td>
        </tr>

        <tr *ngIf="fee" class="order-summary-fee-item">
          <td class="has-text-left pl-0">{{ fee.name }}</td>
          <td class="has-text-right pr-0">
            {{ fee.amount | centsCurrency }}
          </td>
        </tr>

        <tr *ngIf="giftCardAppliedAmount" class="gift-card" data-qa-id="Order Summary Gift Card">
          <td class="has-text-left pl-0">{{ siteName }} Gift Card</td>
          <td class="has-text-right pr-0">-{{ giftCardAppliedAmount | centsCurrency }}</td>
        </tr>

        <tr *ngFor="let item of cartAdditionalItems" data-qa-id="Cart Additional Items">
          <td class="has-text-left pl-0">
            {{ item.description }}
            <span
              *ngIf="item.tooltip"
              [attr.data-qa-id]="item.description + ' Tooltip'"
              class="has-tooltip-multiline has-tooltip-text-centered has-tooltip-arrow tooltipContainer"
              [attr.data-tooltip]="item.tooltip"
              ><i class="fa fa-info-circle has-text-info is-clickable"></i
            ></span>
          </td>
          <td class="has-text-right pr-0">{{ item.price }}</td>
        </tr>

        <tr *ngIf="extraCharges.length || orderUpsells.length" class="subtotal-row" data-qa-id="Order Subtotal Row">
          <td class="has-text-left pl-0">Subtotal</td>
          <td class="has-text-right pr-0">{{ subtotal | centsCurrency }}</td>
        </tr>

        <tr *ngFor="let charge of extraCharges" [attr.data-qa-id]="charge.name + ' Extra Charge'">
          <td class="has-text-left pl-0">{{ charge.name }}</td>
          <td class="has-text-right pr-0">+{{ charge.amount | centsCurrency }}</td>
        </tr>

        <tr *ngFor="let upsell of orderUpsells" [attr.data-qa-id]="upsell.name + ' Order Upsell'">
          <td class="has-text-left pl-0">{{ upsell.name }}</td>
          <td class="has-text-right pr-0">+{{ upsell.upsell_price | centsCurrency }}</td>
        </tr>

        <tr>
          <th class="has-text-left pl-0">Total</th>
          <th class="has-text-right pr-0" data-qa-id="Order Total">{{ total | centsCurrency }}</th>
        </tr>
      </tfoot>
    </table>
    <div
      *ngIf="shouldDisplayMultipleDiscountAlert"
      data-qa-id="Multiple Tests Discount Alert"
      class="notification my-3 has-background-violet has-text-weight-semibold"
    >
      Add one more test to your order to get
      <span class="has-text-weight-ultrabold">$10 off </span>
      <a (click)="displayFindTestsModal()" data-qa-id="Add more tests btn">Add More Tests</a>
    </div>
  </div>
</div>
