<div [formGroup]="form" class="select is-fullwidth mt-0">
  <select [attr.data-qa-id]="'Select ' + question.label" [formControlName]="question.id" [id]="question.id">
    <option
      [attr.data-qa-id]="question.label + ' ' + option.label"
      *ngFor="let option of question.options"
      [value]="option.value"
      class="select"
      [disabled]="option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
</div>
