import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BmiCalculatorComponent } from '@components/bmi-calculator/bmi-calculator.component';
import { CardComponent } from '@components/card/card.component';
import { BaseDynamicConsultationRequestFormComponent } from '@components/consultation-request/base-dynamic-consultation-request-form/base-dynamic-consultation-request-form.component';
import { ConsultationRequestAddressComponent } from '@components/consultation-request/consultation-request-address/consultation-request-address.component';
import { ConsultationRequestPatientInfoComponent } from '@components/consultation-request/consultation-request-patient-info/consultation-request-patient-info.component';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { ConsultationRequestHeaderComponent } from '@components/consultation-request-header/consultation-request-header.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ErrorNotificationComponent } from '@components/error-notification/error-notification.component';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FormService } from '@services/form.service';
import { GenderService } from '@services/gender.service';
import { LoadingService } from '@services/loading.service';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
@Component({
  selector: 'app-glp1-consultation-request-form',
  templateUrl: 'glp1-consultation-request-form.component.html',
  styleUrls: ['glp1-consultation-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    ErrorNotificationComponent,
    ConsultationRequestHeaderComponent,
    ConsultationRequestPatientInfoComponent,
    CardComponent,
    DynamicQuestionnaireFormComponent,
    MedicalHistoryQuestionsComponent,
    BmiCalculatorComponent,
    ConsultationRequestAddressComponent,
  ],
})
export class Glp1ConsultationRequestFormComponent
  extends BaseDynamicConsultationRequestFormComponent
  implements OnInit
{
  constructor(
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected consultationRequestService: ConsultationRequestService,
    protected formService: FormService,
    protected storageService: StorageService,
    protected sessionStorageService: SessionStorageService,
    protected title: Title,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandlerService: ErrorHandlerService,
    protected navigationService: NavigationService,
    protected domainService: DomainService,
    protected genderService: GenderService,
    protected errorLoggingService: ErrorLoggingService
  ) {
    super(
      loadingService,
      activatedRoute,
      config,
      consultationRequestService,
      formService,
      storageService,
      sessionStorageService,
      title,
      dynamicFormsService,
      errorHandlerService,
      navigationService,
      domainService,
      genderService,
      errorLoggingService
    );
  }

  /**
   * {@inheritDoc}
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.handleGlp1Consultation();
  }

  /**
   * Perform specific actions for the GLP-1 consultation request.
   */
  private handleGlp1Consultation(): void {
    if (this.consultationRequest?.pharmacy) {
      this.hidePharmacyAndSetDefault();
      this.prefillPatientAddress();
    }
  }

  /**
   * {@inheritDoc}
   */
  protected override get consultationRequestAddressTitle(): string {
    return 'Shipping Address';
  }
}
