import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  standalone: true,
  imports: [NgClass],
})
export class ErrorNotificationComponent {
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() classes: string | string[] | Set<string> | { [klass: string]: any } = '';
}
