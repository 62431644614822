<app-upsell-card
  [htmlTitle]="htmlTitle"
  [question]="question"
  [slug]="slug"
  (continueButtonPressed)="onContinueButtonPressed($event)"
  (optionSelected)="onOptionSelected($event)"
  [additionalFormsToValidate]="additionalFormsToValidate"
>
  <ng-container [ngSwitch]="treatmentType">
    <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Uti">
      <p class="mb-4">
        Urinary Tract Infections (UTIs) can be stubborn, and if you've had sexual intercourse during your UTI episode,
        bacteria can transfer to your partner, increasing the risk of reinfection for both of you. This could lead to a
        frustrating cycle of recurring infections and prolonged discomfort. Our specially formulated
        <strong>Penile UTI Wash</strong> is designed to eliminate any residual bacteria and reduce the risk of
        reinfection.
      </p>
      <p class="mb-4">
        By using this wash daily for 5 days, you can
        <strong>help ensure that both you and your partner are clear of bacteria</strong>, supporting a smooth and
        complete recovery and reducing the chances of future UTIs.
      </p>
    </ng-template>
    <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.YeastInfection">
      <p class="mb-4">
        Yeast infections can be persistent, and if you've had sexual intercourse during your episode, there's a chance
        the yeast could transfer to your partner's skin, increasing the risk of reinfection for both of you. To help
        ensure your recovery isn't interrupted, we offer a specially formulated
        <strong>Penile Bacteria Wash</strong> designed to eliminate any residual yeast and reduce the likelihood of
        reinfection.
      </p>
      <p class="mb-4">
        Using this wash daily for 5 days can help <strong>break the cycle of reinfection</strong>, ensuring that both
        you and your partner are clear of yeast, allowing for a smooth and complete recovery.
      </p>
    </ng-template>
    <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.BacterialVaginosis">
      <p class="mb-4">
        If you've had sex while experiencing Bacterial Vaginosis (BV), the bacteria can transfer to your partner's
        penis, creating a risk of reinfection for both of you. This could potentially disrupt your recovery and prolong
        your symptoms. To break this cycle, our specially formulated <strong>Penile Bacteria Wash</strong> is designed
        to effectively eliminate these bacteria and reduce the chance of reinfection.
      </p>
      <p class="mb-4">
        Using this wash daily for 5 days helps ensure that you and your partner are both
        <strong>fully cleansed of any lingering bacteria</strong>, allowing you to complete your treatment with
        confidence and avoid setbacks.
      </p>
    </ng-template>
    <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Metformin">
      <p class="mb-4">
        While metformin is highly effective, maintaining overall hygiene and comfort is important for your well-being.
        For some individuals, changes in health routines or metabolic shifts may increase the importance of proper
        cleansing in sensitive areas to reduce irritation and promote overall comfort.
      </p>
      <p class="mb-4">
        Our specially formulated <strong>Men's Intimate Wash</strong> is pH-balanced, non-irritating, and designed to
        keep you feeling fresh and clean daily. It's an excellent choice for anyone looking to support intimate hygiene
        as part of their overall health routine.
      </p>
    </ng-template>
    <ng-template [ngSwitchCase]="ConsultationTreatmentTypes.Hsv">
      <p class="mb-4">
        Managing HSV-1 or HSV-2 effectively includes proper care for both yourself and your partner. The virus can
        linger on the skin, increasing the risk of transmission or reinfection, even during asymptomatic periods.
      </p>
      <p class="mb-4">
        Our specially formulated <strong>Penile Wash</strong> is designed to be used by either you or your partner to
        cleanse sensitive areas without causing additional irritation.
        <strong
          >Using this wash can help soothe the skin, remove lingering irritants, and create an environment that supports
          the healing process.</strong
        >
      </p>
    </ng-template>
    <ng-template ngSwitchDefault>
      <p class="mb-4">
        If you've had sex while experiencing an STI, the bacteria can transfer to your partner, increasing the risk of
        reinfection for both of you. This could disrupt your recovery, prolong your symptoms, and make treatment less
        effective. To help break this cycle, our specially formulated <strong>Penile Bacteria Wash</strong> is designed
        to target and eliminate these bacteria, reducing the chance of reinfection.
      </p>
      <p class="mb-4">
        Using this wash daily for 5 days helps ensure that
        <strong>you and your partner are both fully cleansed of any lingering bacteria</strong>, allowing you to
        complete your treatment with confidence and avoid setbacks.
      </p>
    </ng-template>
  </ng-container>
  <div bottomContent *ngIf="addressForm">
    <div class="mt-4">
      <app-address-inputs [form]="addressForm" [multiregion]="false"></app-address-inputs>
    </div>
  </div>
</app-upsell-card>
