<div class="steps-containers">
  <div class="container">
    <div class="columns is-fullwidth is-marginless is-paddingless position-relative">
      <div
        class="column is-one-third is-flex is-align-items-center is-justify-content-center is-marginless is-paddingless"
      >
        <div
          class="step-container is-flex is-flex-direction-row is-align-items-center is-justify-content-center step-1"
        >
          <h1 class="step-number">1</h1>
          <div class="desktop-title-container is-hidden-mobile pl-2">
            <p class="title is-size-6 has-text-primary has-text-weight-bold is-uppercase is-marginless">
              Order Your Test
            </p>
            <div class="is-flex is-align-items-center is-justify-content-center">
              <i class="fa fa-shopping-cart steps__icon"></i>
              <p class="subtitle has-text-primary">Online or by phone, no <br />doctors referral required</p>
            </div>
          </div>
          <div
            class="mobile-title-container is-hidden-tablet has-text-centered is-flex is-flex-direction-row is-align-items-center"
          >
            <i class="fa fa-shopping-cart steps__icon"></i>
            <p class="title is-size-6 has-text-primary has-text-weight-bold is-uppercase is-marginless">
              Order Your Test
            </p>
          </div>
        </div>
      </div>
      <div
        class="column is-one-third is-flex is-align-items-center is-justify-content-center is-marginless is-paddingless"
      >
        <div
          class="step-container is-flex is-flex-direction-row is-align-items-center is-justify-content-center step-2"
        >
          <h1 class="step-number">2</h1>
          <div class="desktop-title-container is-hidden-mobile pl-2">
            <p class="title is-size-6 has-text-primary has-text-weight-bold is-uppercase">Visit A Local Lab</p>
            <div class="is-flex is-align-items-center is-justify-content-center">
              <i class="fa fa-map-marker steps__icon"></i>
              <p class="subtitle has-text-primary">Get tested locally at one of <br />our 4,500+ labs</p>
            </div>
          </div>
          <div
            class="mobile-title-container is-hidden-tablet has-text-centered is-flex is-flex-direction-row is-align-items-center"
          >
            <i class="fa fa-map-marker steps__icon"></i>
            <p class="title is-size-6 has-text-primary has-text-weight-bold is-uppercase is-marginless">
              Visit A Local Lab
            </p>
          </div>
        </div>
      </div>
      <div
        class="column is-one-third is-flex is-align-items-center is-justify-content-center is-marginless is-paddingless"
      >
        <div
          class="step-container is-flex is-flex-direction-row is-align-items-center is-justify-content-center step-3"
        >
          <h1 class="step-number">3</h1>
          <div class="desktop-title-container is-hidden-mobile pl-2">
            <p class="title has-text-primary has-text-weight-bold is-uppercase">Get Your Results</p>
            <div class="is-flex is-align-items-center is-justify-content-center">
              <i class="fa fa-pie-chart steps__icon"></i>
              <p class="subtitle has-text-primary">Receive your lab results <br />within 1-3 business days!</p>
            </div>
          </div>
          <div
            class="mobile-title-container is-hidden-tablet has-text-centered is-flex is-flex-direction-row is-align-items-center"
          >
            <i class="fa fa-pie-chart steps__icon"></i>
            <p class="title is-size-6 has-text-primary has-text-weight-bold is-uppercase is-marginless">
              Get Your Results
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
