import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplePayService } from '@services/external-payments/apple-pay.service';

@Component({
  selector: 'app-apple-pay-button',
  templateUrl: './apple-pay-button.component.html',
  styleUrls: ['./apple-pay-button.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ApplePayButtonComponent implements OnInit {
  @Input() processing: boolean = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  applePayFailedToInitialize: boolean = false;
  buttonReady: boolean = false;

  constructor(private applePayService: ApplePayService) {}

  ngOnInit(): void {
    this.initializeApplePay();
  }

  /**
   * Emits the buttonClick event.
   */
  onButtonClicked(): void {
    this.buttonClick.emit();
  }

  /**
   * Ensures there is a valid Apple Pay instance and then displays the Apple Pay button if the initialization was
   * successful, or shows an error message if the initialization failed.
   */
  private initializeApplePay(): void {
    this.applePayService.renderPaymentButton().subscribe({
      next: () => (this.buttonReady = true),
      error: () => (this.applePayFailedToInitialize = true),
    });
  }
}
