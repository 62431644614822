import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { CardComponent } from '@components/card/card.component';
import { HeightWeightInputComponent } from '@components/consultation-request/height-weight-input/height-weight-input.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { DateSelection } from '@models/date-selection';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { GenderService } from '@services/gender.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { dateValidator } from 'src/app/validators/date-validator';

@Component({
  selector: 'app-consultation-request-patient-info',
  templateUrl: './consultation-request-patient-info.component.html',
  styleUrls: ['./consultation-request-patient-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CardComponent, FormsModule, ReactiveFormsModule, NgIf, NgFor, HeightWeightInputComponent],
})
export class ConsultationRequestPatientInfoComponent extends FormHelper implements OnInit {
  @Input() patientInfoForm: FormGroup;
  calendarOptions: { months: Month[]; days: number[]; years: number[] };

  /**
   * Gets the maximum age allowed for the consultation.
   */
  get maxAge(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Gets the minimum age allowed for the consultation.
   */
  get minAge(): number {
    return this.config.order.minAgeAllowed;
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private storageService: StorageService,
    private sessionService: SessionStorageService,
    private genderService: GenderService
  ) {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setCalendarOptions();
    this.initializeForm();
  }

  /**
   * Gets the error to display if gender pattern validation fails.
   */
  get genderInvalidError(): string | null {
    const gender = this.patientInfoForm?.get('gender')?.value;
    return gender ? this.genderService.getGenderInvalidError(this.sessionService, gender) : null;
  }

  /**
   * Returns the birth of date control.
   */
  get birthOfDateControl(): FormGroup {
    return this.patientInfoForm?.get('birthday') as FormGroup;
  }

  /**
   * Returns the gender control.
   */
  get genderControl(): FormControl {
    return this.patientInfoForm?.get('gender') as FormControl;
  }

  /**
   * Returns the treatment type.
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionService.treatmentType;
  }

  /**
   * Checks if the age is valid using the birth of date control.
   */
  get isAgeValid(): boolean {
    const dateSelection = new DateSelection(
      Number(this.birthOfDateControl?.get('month').value),
      Number(this.birthOfDateControl?.get('day').value),
      Number(this.birthOfDateControl?.get('year').value)
    );

    return dateSelection.isAgeValid(this.minAge, this.maxAge);
  }

  /**
   * Gets the date of birth from the form.
   */
  get dob(): Date | null {
    return this.getDob(this.patientInfoForm);
  }

  /**
   * Gets the minimum name length.
   */
  get minimumNameLength(): number {
    return this.config.minimumNameLength;
  }

  /**
   * Gets if the treatment type is GLP1.
   */
  get isGlp1TreatmentType(): boolean {
    return this.treatmentType === ConsultationTreatmentTypes.Glp1;
  }

  /**
   * Checks if a form control is invalid.
   *
   * @param {string} controlName the name of the form control to check for validity
   *
   * @returns {boolean} true if the control is invalid, false otherwise
   */
  isControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.patientInfoForm?.get(controlName));
  }

  /**
   * Checks if the birthday has an error after filled.
   *
   * @returns {boolean} true if the birthday has an error, false otherwise
   */
  hasBirthdayError(): boolean {
    return this.isBirthdayFilledOut() && (this.isControlInvalid('birthday') || !this.isAgeValid);
  }

  /**
   * Checks whether the birthday is filled out.
   *
   * @returns {boolean} true if the birthday is filled out, false otherwise
   */
  private isBirthdayFilledOut(): boolean {
    return ['month', 'day', 'year'].every((controlName) => !this.isControlInvalid(`birthday.${controlName}`));
  }

  /**
   * Initializes the patient information form.
   */
  private initializeForm(): void {
    this.prefillPatientInformation();
    this.setFormValidators();
  }

  /**
   * Adds validators to the patient information form.
   */
  private setFormValidators(): void {
    this.birthOfDateControl?.setValidators([dateValidator()]);
    this.birthOfDateControl?.updateValueAndValidity();
    this.genderControl?.setValidators([Validators.required]);
    this.genderControl?.updateValueAndValidity();
  }

  /**
   * Prefills the patient information form with the order details.
   */
  private prefillPatientInformation(): void {
    const dateOfBirth = this.consultationRequestService.consultationOrderDetail?.date_of_birth;
    this.patientInfoForm?.patchValue({
      ...this.consultationRequestService.consultationOrderDetail,
      first_name: this.consultationRequestService.consultationOrderDetail?.consultationRequest.first_name,
      last_name: this.consultationRequestService.consultationOrderDetail?.consultationRequest.last_name,
      birthday: {
        month: dateOfBirth.getMonth() + 1,
        day: dateOfBirth.getDate(),
        year: dateOfBirth.getFullYear(),
      },
    });
    if (this.consultationRequestService.consultationOrderDetail?.gender) {
      this.patientInfoForm?.get('gender').markAsTouched();
    }
  }

  /**
   * Sets the calendar options.
   */
  private setCalendarOptions(): void {
    const currentYear = new Date().getFullYear();
    this.calendarOptions = {
      months: Months,
      days: this.getListOfNumbers(1, 31),
      years: this.getListOfNumbers(currentYear, currentYear - this.config.maxAgeAllowed),
    };
  }
}
