import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { VenmoService } from '@services/external-payments/venmo.service';

@Component({
  selector: 'app-venmo-button',
  templateUrl: './venmo-button.component.html',
  styleUrls: ['./venmo-button.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class VenmoButtonComponent implements OnDestroy {
  @Input() processing: boolean = false;
  @Output() processingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private venmoService: VenmoService) {}

  /**
   * Cancels any tokenization in progress when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.venmoService.cancelTokenization();
    this.processingChange.emit(false);
  }

  /**
   * Emits the buttonClick event.
   */
  onButtonClicked(): void {
    this.buttonClick.emit();
  }
}
