<app-card
  title="Preferred Medication/Treatment"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <app-dropdown-select-field
    controlName="treatmentPreferences"
    [form]="form"
    invalidErrorMsg="Preferred medication/treatment is required"
    label="Preferred Medication/Treatment"
    [options]="treatmentPreferencesOptions"
  ></app-dropdown-select-field>
</app-card>
