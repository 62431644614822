<app-card
  title="Medical History"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <form [formGroup]="medicalHistoryForm" data-qa-id="medical-history-form">
    <div class="columns">
      <div class="column is-6">
        <label class="label" for="allergies">Known Drug Allergies:</label>
        <div class="control">
          <textarea
            id="allergies"
            class="input medical-text-area"
            placeholder="Ex.: Penicillin"
            formControlName="allergies"
          >
          </textarea>
        </div>
        <div *ngIf="invalidControl('allergies')" class="error">
          <div *ngIf="medicalHistoryForm.get('allergies').errors.required">
            Your allergies are required. Please enter "None" or "NKDA" if there are none.
          </div>
          <div *ngIf="medicalHistoryForm.get('allergies').errors.maxlength">
            Allergies cannot be more than 1000 characters.
          </div>
        </div>
      </div>
      <div class="column is-6">
        <label class="label" for="prescriptions">Current Prescription Medications:</label>
        <div class="control">
          <textarea
            id="prescriptions"
            class="input medical-text-area"
            placeholder="Ex.: Aspirin"
            formControlName="prescriptions"
          >
          </textarea>
        </div>
        <div *ngIf="invalidControl('prescriptions')" class="error">
          <div *ngIf="medicalHistoryForm.get('prescriptions').errors.required">
            Your current prescriptions are required. Please enter "None" if there are none.
          </div>
          <div *ngIf="medicalHistoryForm.get('prescriptions').errors.maxlength">
            Prescription cannot be more than 1000 characters.
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="columns">
      <div class="column is-6">
        <label class="label" for="race">Race:</label>
        <div class="select is-fullwidth">
          <select name="race" id="race" formControlName="race" required="required">
            <option [value]="Races.AfricanAmerican">African American</option>
            <option [value]="Races.AlaskaNative">Alaska Native</option>
            <option [value]="Races.AmericanIndian">American Indian</option>
            <option [value]="Races.Asian">Asian</option>
            <option [value]="Races.Caucasian">Caucasian</option>
            <option [value]="Races.PacificIslander">Pacific Islander</option>
            <option [value]="Races.Decline">Decline to Answer</option>
          </select>
        </div>
        <div *ngIf="invalidControl('race')" class="error">
          <div *ngIf="medicalHistoryForm.get('race').errors.required">Your Race is required.</div>
        </div>
      </div>

      <div class="column is-6">
        <label class="label" for="ethnicity">Ethnicity:</label>
        <div class="select is-fullwidth">
          <select name="ethnicity" id="ethnicity" formControlName="ethnicity" required="required">
            <option [value]="Ethnicities.Hispanic">Hispanic</option>
            <option [value]="Ethnicities.NonHispanic">Non-Hispanic</option>
            <option [value]="Ethnicities.Unknown">Unknown</option>
          </select>
        </div>
        <div *ngIf="invalidControl('ethnicity')" class="error">
          <div *ngIf="medicalHistoryForm.get('ethnicity').errors.required">Your Ethnicity is required.</div>
        </div>
      </div>
    </div>

    <hr />

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label" for="sex_with">I have sex with ...</label>
          <div class="select is-fullwidth">
            <select name="sex_with" id="sex_with" formControlName="sex_with">
              <option [value]="SexWith.Males">Males</option>
              <option [value]="SexWith.Females">Females</option>
              <option [value]="SexWith.Both">Both</option>
            </select>
          </div>
          <div *ngIf="invalidControl('sex_with')" class="error">
            <div *ngIf="medicalHistoryForm.get('sex_with').errors.required">
              An answer for this question is required.
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="columns" *ngIf="isGenderFemale()">
      <div class="column">
        <div class="field">
          <label class="label" for="pregnant">Are you currently pregnant?</label>
          <div class="select is-fullwidth">
            <select name="pregnant" id="pregnant" formControlName="pregnant">
              <option [value]="Pregnant.Yes">Yes</option>
              <option [value]="Pregnant.No">No</option>
              <option [value]="Pregnant.Unsure">Unsure</option>
            </select>
          </div>
          <div *ngIf="invalidControl('pregnant')" class="error">
            <div *ngIf="medicalHistoryForm.get('pregnant').errors.required">
              Pregnancy information must be provided.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" *ngIf="isGenderFemale() && !isPregnant">
      <div class="column" formGroupName="menstrual_d">
        <label class="label has-text-weight-normal">Last Menstrual Cycle:</label>
        <div id="menstrual_d" class="columns is-multiline is-mobile">
          <div class="column is-6-desktop is-12-mobile">
            <div class="select is-fullwidth">
              <select formControlName="month" data-qa-id="menstrual_d-month">
                <option [value]="null" selected disabled>Month</option>
                <option *ngFor="let month of menstrualCalendar.months" [value]="month.number">
                  {{ month.number }} - {{ month.name }}
                </option>
              </select>
            </div>
            <div *ngIf="invalidControl('menstrual_d.month')" class="error">
              The month of your last menstrual cycle is required
            </div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="day" data-qa-id="menstrual_d-day">
                <option [value]="null" selected disabled>Day</option>
                <option *ngFor="let day of menstrualCalendar.days" [value]="day">{{ day }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('menstrual_d.day')" class="error">
              The day of your last menstrual cycle is required
            </div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="year" data-qa-id="menstrual_d-year">
                <option [value]="null" selected disabled>Year</option>
                <option *ngFor="let year of menstrualCalendar.years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('menstrual_d.year')" class="error">
              The year of your last menstrual cycle is required
            </div>
          </div>
        </div>
        <div *ngIf="invalidControl('menstrual_d') && isDateFieldFilled('menstrual_d')" class="error">
          <div *ngIf="medicalHistoryForm.get('menstrual_d')?.errors?.invalidDate">
            An answer for Last Menstrual Cycle is required.
          </div>
        </div>
      </div>
    </div>

    <div class="columns" *ngIf="isPregnant">
      <div class="column" formGroupName="due_d">
        <label class="label has-text-weight-normal">Due date:</label>
        <div id="due_d" class="columns is-multiline is-mobile">
          <div class="column is-6-desktop is-12-mobile">
            <div class="select is-fullwidth">
              <select formControlName="month" data-qa-id="due_d-month">
                <option [value]="null" selected disabled>Month</option>
                <option *ngFor="let month of this.dueCalendar.months" [value]="month.number">
                  {{ month.number }} - {{ month.name }}
                </option>
              </select>
            </div>
            <div *ngIf="invalidControl('due_d.month')" class="error">The month of your due date is required</div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="day" data-qa-id="due_d-day">
                <option [value]="null" selected disabled>Day</option>
                <option *ngFor="let day of this.dueCalendar.days" [value]="day">{{ day }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('due_d.day')" class="error">The day of your due date is required</div>
          </div>
          <div class="column is-3-desktop is-6-mobile">
            <div class="select is-fullwidth">
              <select formControlName="year" data-qa-id="due_d-year">
                <option [value]="null" selected disabled>Year</option>
                <option *ngFor="let year of this.dueCalendar.years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div *ngIf="invalidControl('due_d.year')" class="error">The year of your due date is required</div>
          </div>
        </div>
        <div *ngIf="invalidControl('due_d') && isDateFieldFilled('due_d')" class="error">
          <div *ngIf="medicalHistoryForm.get('due_d')?.errors?.invalidDate">An answer for Due date is required.</div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline pb-4" formGroupName="symptoms">
      <div id="symptoms" class="column is-full has-text-weight-bold is-size-5">
        Which of the following symptoms are you currently experiencing:
      </div>
      <div *ngFor="let symptom of ConsultationRequestService.symptoms" class="column is-half py-1">
        <label [for]="'medical_history_' + symptom.value" class="is-block is-fullwidth is-clickable">
          <input
            [id]="'medical_history_' + symptom.value"
            type="checkbox"
            [formControlName]="symptom.value"
            (change)="onSymptomCheckboxChange($event, symptom.value)"
          />
          <span class="ml-3">{{ symptom.name }}</span>
        </label>
      </div>
      <div *ngIf="invalidControl('symptoms')" class="column is-full error py-0 mt-0">
        <div *ngIf="medicalHistoryForm.get('symptoms').errors.symptomsRequired">
          Please choose an option for your symptoms.
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label for="health_info">List any additional information the doctor may need to know:</label>
          <div class="control">
            <textarea
              class="input medical-text-area"
              name="health_info"
              id="health_info"
              formControlName="health_info"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-card>
