import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fileSizeValidator } from '@common/file-size-validator';
import { fileTypeValidator } from '@common/file-type-validator';
import { FormHelper } from '@common/form-helper';
import { TreatmentConsultationSubmitComponent } from '@components/consultation-request/treatment-consultation-submit/treatment-consultation-submit.component';
import { ConsultationRequestHeaderComponent } from '@components/consultation-request-header/consultation-request-header.component';
import { FileInputComponent } from '@components/dynamic-forms/file-input/file-input.component';
import { InputTypes } from '@enums/input-types';
import { Question } from '@models/dynamic-forms/question';

@Component({
  selector: 'app-consult-attachment',
  templateUrl: './consult-attachment.component.html',
  styleUrls: ['./consult-attachment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ConsultationRequestHeaderComponent, NgIf, FileInputComponent, NgFor, TreatmentConsultationSubmitComponent],
})
export class ConsultAttachmentComponent extends FormHelper implements OnInit {
  form: FormGroup;
  question: Question;

  private readonly controlName = 'governmentIdFile';

  constructor() {
    super();
  }

  /**
   * Gets the form control for the file input.
   */
  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  /**
   * Retrieves validation error messages for the file input.
   */
  get errors(): string[] {
    if (!this.isInvalid(this.control)) {
      return [];
    }

    return Object.keys(this.control.errors || {}).map((key) => {
      switch (key) {
        case 'required':
          return 'Please upload a file';
        case 'fileType':
          return 'Invalid file type, please upload a JPEG, PNG, GIF, HEIC, or PDF file';
        case 'fileSize':
          return 'File size should not exceed 10MB';
        default:
          return 'Invalid file, please try a different one';
      }
    });
  }

  /**
   * Gets the file from the form control.
   */
  get file(): File {
    return this.control.value;
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.question = this.getQuestion();
    this.form = this.getFormGroup();
  }

  /**
   * Creates a form group with validation for the file input.
   *
   * @returns {FormGroup} a form group with required, file type and size validators
   */
  private getFormGroup(): FormGroup {
    return new FormGroup({
      [this.controlName]: new FormControl(null, [
        Validators.required,
        fileTypeValidator(['image/jpeg', 'image/png', 'image/gif', 'image/heic', 'application/pdf']),
        fileSizeValidator(10485760),
      ]),
    });
  }

  /**
   * Generates a question object for the file input.
   *
   * @returns {Question} a Question object
   */
  private getQuestion(): Question {
    return new Question({
      id: this.controlName,
      type: InputTypes.File,
      label: 'To verify your identity, please upload a photo of your government identification card (ID).',
    });
  }
}
