import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { InHomeBookingResult, Result } from '@Medology/ng-findalab';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { PhoneService } from '@services/phone.service';

@Component({
  selector: 'app-selected-center-card',
  templateUrl: './selected-center-card.component.html',
  styleUrls: ['./selected-center-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class SelectedCenterCardComponent {
  @Input() label: string = 'Lab';
  @Input() center: Result;
  @Input() inHomeResult: InHomeBookingResult;
  @Input() displayChangeLocationBtn: boolean = true;
  @Output() changeCenterEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private phoneService: PhoneService) {}

  /**
   * Emit change center event
   */
  changeCenter(): void {
    this.changeCenterEvent.emit();
  }

  /**
   * Format the phone number with hyphens separating each part.
   *
   * @param phoneNumber phone number as string
   * @returns the phone number with each part separated by hyphens
   */
  public formatPhoneNumber(phoneNumber: string): string {
    return this.phoneService.formatPhoneNumber(phoneNumber);
  }

  /**
   * Gets the booking date and time in a format like MM/DD/YYYY
   */
  get bookingDate(): string | null {
    if (!this.inHomeResult) {
      return null;
    }

    const dateSegments = this.inHomeResult.date.split('-');

    return dateSegments[1] + '/' + dateSegments[2] + '/' + dateSegments[0];
  }

  /**
   * Gets the change button text based on environment.
   */
  get changeButtonText(): string {
    return this.config.buttons.selectedCenterChange;
  }
}
