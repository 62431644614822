import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageFallback',
  standalone: true,
})
export class ImageFallback implements PipeTransform {
  /**
   * Transform the image url to a fallback image if the image is not found.
   *
   * @param {string} url The image url
   * @param {string} fallbackPath The fallback image path
   */
  transform(url: string, fallbackPath: string): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => resolve(fallbackPath);
      img.src = url;
    });
  }
}
