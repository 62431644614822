<div class="columns is-mobile is-centered my-4">
  <div
    id="google-pay-button-container"
    class="google-pay-button__container"
    [ngClass]="{ 'is-hidden': !buttonReady || processing }"
    (click)="onButtonClicked()"
  ></div>
  <div *ngIf="!processing && !buttonReady" class="has-text-centered">
    <h3>
      <ng-container *ngIf="googlePayFailedToInitialize; else loadingMessage"> Google Pay failed to load. </ng-container>
      <ng-template #loadingMessage>
        <em class="fa fa-spinner fa-spin"></em> Loading Google Pay, please wait...
      </ng-template>
    </h3>
  </div>
  <div *ngIf="processing" class="has-text-centered">
    <h3><em class="fa fa-spinner fa-spin"></em> Processing Google Pay payment, please wait...</h3>
  </div>
</div>
