<app-card
  title="Order Complete!"
  data-qa-id="Order Complete Instructions"
  [headerClasses]="headerClasses"
  [contentClasses]="'card-body-background p-6 ' + config.styles.orderCompleteInstructions.cardContentClass"
>
  <p
    *ngIf="domainService.isSTDCheckDomain() || (!storageService.isBaOrder && domainService.isHealthlabsDomain())"
    class="mb-4"
  >
    Your order has been placed<span *ngIf="inHomeBookingResult; else orderPlacedSuffix">!</span>
    <ng-template #orderPlacedSuffix>
      {{
        !orderCompleteDetails || isFree || isPayLater
          ? '.'
          : ' and your card has been charged. The charge will appear on your statement as "ANALYTE HEALTH".'
      }}
    </ng-template>
  </p>

  <ng-container *ngIf="!inHomeBookingResult; else inHomeBookingContent">
    <ng-container [ngSwitch]="domainService.getSiteDomain()">
      <div *ngSwitchCase="Sites.Stdcheck">
        <p class="mb-4">
          <strong>Please Note:</strong>
          <span *ngIf="hasEmail || sendSms; then contentIfEmailOrSms; else contentIfNoContact"> </span>
          <ng-template #contentIfEmailOrSms>
            Within 15 minutes, you should receive a message in your online account with your testing instructions. To
            access your
            <a [href]="config.siteUrls.myAccount">online account</a>, please log in with your
            {{ hasEmail ? 'email address' : 'phone number' }}.
          </ng-template>
          <ng-template #contentIfNoContact>
            <ng-container
              *ngIf="orderCompleteDetails; then contentIfNoEmailOrSms; else contentIfNoOrderCompleteDetails"
            ></ng-container>
          </ng-template>
          <ng-template #contentIfNoEmailOrSms>
            Since you chose not to be contacted via text message or email, you will need to call our Patient Services
            Team at 1-800-456-2323 to have your lab form faxed directly to the lab you chose at checkout as well as to
            receive your results. They can provide you with account access if you wish to login to a secure online
            account to view your order and results.
          </ng-template>
          <ng-template #contentIfNoOrderCompleteDetails>
            If you chose to be contacted, within 15 minutes you should receive a message in your online account with
            your testing instructions. Sign in to your
            <a [href]="config.siteUrls.myAccount">online account</a>
            with your email or phone number. Otherwise, you will need to call our Patient Services Team at
            1-800-456-2323 to have your lab form faxed directly to the lab you chose at checkout as well as to receive
            your results. They can provide you with account access if you wish to login to a secure online account to
            view your order and results.
          </ng-template>
        </p>
        <p class="mb-4">
          Inside your account, you will also be able to access your test order form. From your phone, computer, or other
          device, you can either print it or electronically fax it directly to the lab.
          <strong>No appointment is required</strong> for you to visit the lab, so you can walk in any time within the
          lab's business hours.
        </p>
        <p class="mb-4">{{ assistanceDetails }}</p>
      </div>
      <div *ngSwitchCase="Sites.Healthlabs">
        <p class="mb-4">Our business hours are {{ config.businessHours }}.</p>
        <p class="mb-4">
          <strong>Next Steps:</strong> You will receive an email from us within 15 minutes with instructions on how to
          access your secure online portal where your lab order will be available. Please print and bring this lab order
          with you to the laboratory collection site. If you do not have a printer, you may present the lab order to the
          laboratory staff on your phone.
        </p>
      </div>
      <div *ngSwitchCase="Sites.Treatmyuti">
        <p class="mb-4"><strong>Your order has been placed!</strong></p>
        <p class="mb-4">
          Your order has been successfully placed. Please take a moment to check your email at
          {{ orderCompleteDetails.email }}, where you will find a link to complete a medical questionnaire.
        </p>
        <ng-container *ngTemplateOutlet="consultationLink"></ng-container>
        <p class="mb-4">
          This questionnaire is essential as it will enable our team of experienced medical professionals to thoroughly
          evaluate your symptoms and prescribe the most suitable medication to treat your UTI.
        </p>
        <p class="mb-4">
          Thank you for trusting us with your healthcare needs. We take pride in providing you with safe, affordable,
          and convenient UTI treatment. We look forward to helping you feel better soon.
        </p>
        <p class="mb-4">
          Best regards,
          <br />
          The TreatMyUTI.com Team
        </p>
      </div>
      <div *ngSwitchCase="Sites.Starfish">
        <p class="mb-4"><strong>Your order has been placed!</strong></p>
        <p class="mb-4">
          Your order has been successfully placed. Please take a moment to check your email at
          {{ orderCompleteDetails.email }}, where you will find a link to complete a medical questionnaire.
        </p>
        <ng-container *ngTemplateOutlet="consultationLink"></ng-container>
        <p class="mb-4">
          This questionnaire is essential as it will enable our team of experienced medical professionals to thoroughly
          evaluate your symptoms and prescribe the most suitable medication for your treatment.
        </p>
        <p class="mb-4">
          Thank you for trusting us with your healthcare needs. We take pride in providing you with safe, affordable,
          and convenient treatment. We look forward to helping you feel better soon.
        </p>
        <p class="mb-4">
          Best regards,
          <br />
          The Starfish.com Team
        </p>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #inHomeBookingContent>
    <p class="mb-4">
      Your In-Home Collection has been scheduled for {{ inHomeBookingResult.date | date: 'MM/dd/yyyy' }} between
      {{ inHomeBookingResult.bookingSlot.period }}.<br />
      Your specialist will arrive promptly at {{ inHomeBookingResult.address.street }},
      {{ inHomeBookingResult.address.city }}, {{ inHomeBookingResult.address.state }}
      {{ inHomeBookingResult.address.zipCode }} and will already have your paperwork ready.
    </p>
    <p class="mb-4">
      All we ask is before they arrive, please secure any pets you may have so they do not interfere with your
      appointment.
    </p>
    <p class="mb-4">{{ assistanceDetails }}</p>
  </ng-template>

  <ng-template #consultationLink>
    <p *ngIf="treatmentType" class="mb-4">
      <span class="has-background-warning"
        >Or, you can
        <a
          data-qa-id="Consultation Request Btn"
          [href]="getConsultationPageUrl()"
          class="is-clickable has-text-dark has-text-weight-ultrabold"
        >
          CLICK HERE
        </a>
        to take it now.
      </span>
    </p>
  </ng-template>

  <p data-qa-id="Cancellation Policy Section" *ngIf="showCancellationPolicy">
    <strong>Our Cancellation Policy:</strong> You may cancel your order any time before your
    {{ inHomeBookingResult ? 'In-Home Collection appointment' : 'specimen collection' }}.
    <span *ngIf="orderCompleteDetails && !isFree && !isPayLater && !inHomeBookingResult">
      Refunds will be issued within 24-48 hours of cancellation
      <strong>minus a 20% cancellation fee</strong>.
    </span>
    All cancellation requests after 21 days of purchase will be given credit for future testing.
  </p>
</app-card>
