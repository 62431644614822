import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CentsCurrencyPipe } from '@common/centsCurrency.pipe';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { CardComponent } from '@components/card/card.component';
import { HeightWeightInputComponent } from '@components/consultation-request/height-weight-input/height-weight-input.component';
import { Gender } from '@enums/gender';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { Symptom } from '@enums/symptoms';
import { Treatment } from '@enums/treatment';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { Subscription } from 'rxjs';
import { dateValidator } from 'src/app/validators/date-validator';

@Component({
  selector: 'app-consultation-request-prescription-information',
  templateUrl: './prescription-information.component.html',
  styleUrls: ['./prescription-information.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    HeightWeightInputComponent,
    CentsCurrencyPipe,
  ],
})
export class PrescriptionInformationComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() calendarOptions: { months: Month[]; days: number[]; years: number[] };

  ConsultationRequestService: typeof ConsultationRequestService = ConsultationRequestService;
  Gender: typeof Gender = Gender;
  Pregnant: typeof QuestionnaireAnswers = QuestionnaireAnswers;
  Treatment: typeof Treatment = Treatment;

  prescriptionInfoForm: FormGroup;

  private partnerGenderChangesSubscription: Subscription;
  private treatmentChangesSubscription: Subscription;

  /**
   * Gets the date of birth from the form.
   */
  get dob(): Date | null {
    return this.getDob(this.prescriptionInfoForm?.get('partner') as FormGroup);
  }

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private formService: FormService) {
    super();
  }

  /**
   * Get the partner gender form control.
   */
  get partnerGenderControl(): AbstractControl {
    return this.prescriptionInfoForm?.get('partner.gender');
  }

  /**
   * Get the partner birthday form group.
   */
  get partnerBirthdayControl(): FormGroup {
    return this.prescriptionInfoForm?.get('partner.birthday') as FormGroup;
  }

  /**
   * Initialize the form.
   */
  ngOnInit(): void {
    this.prescriptionInfoForm = this.formService.consultationRequest.get('prescription') as FormGroup;
    this.clearFormGroupValidators('partner');
    this.treatmentChangesSubscription = this.listenForTreatmentChanges();
  }

  /**
   * Unsubscribe all current subscriptions.
   */
  ngOnDestroy(): void {
    this.treatmentChangesSubscription.unsubscribe();
    this.partnerGenderChangesSubscription?.unsubscribe();
  }

  /**
   * Determine if a prescription form control is invalid.
   *
   * @param controlName name of the control to check
   * @returns true if invalid, false otherwise
   */
  isControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.prescriptionInfoForm?.get(controlName));
  }

  /**
   * Retrieves the error message for an invalid control.
   *
   * @param {string} controlName the name of the control in the form group
   * @param {string} displayName the display name of the control
   *
   * @returns {string} the error message for the invalid control
   */
  getInvalidControlErrorMessage(controlName: string, displayName: string): string {
    return this.getControlValidationErrorMessage(this.prescriptionInfoForm.get(controlName), displayName);
  }

  /**
   * Return true if the user selected to receive treatment for the partner or
   * for the partner and himself.
   */
  isTreatingPartner(): boolean {
    const treatment = this.prescriptionInfoForm?.get('treatment').value;

    return [Treatment.SelfAndPartner, Treatment.Partner].includes(treatment);
  }

  /**
   * Deselect all symptoms if none is selected, or deselect none if any other
   * symptom is selected.
   *
   * @param event change event on checkbox label
   * @param symptom clicked symptom
   */
  onSymptomCheckboxChange(event: any, symptom: string): void {
    if (!event.target.checked) {
      return;
    }

    if (symptom === Symptom.None) {
      for (let controlKey in this.symptomsFormGroup.controls) {
        this.symptomsFormGroup.get(controlKey).setValue(symptom === controlKey);
      }
    } else {
      this.symptomsFormGroup.get(Symptom.None).setValue(false);
    }
  }

  /**
   * Determines if the date of birth is filled out.
   */
  isDateOfBirthFilled(): boolean {
    return ['month', 'day', 'year'].every((controlName: string) => {
      return !this.isControlInvalid(`partner.birthday.${controlName}`);
    });
  }

  /**
   * Get the partner symptoms form group.
   */
  private get symptomsFormGroup(): FormGroup {
    return this.prescriptionInfoForm?.get('partner.symptoms') as FormGroup;
  }

  /**
   * Get the partner pregnant form control.
   */
  private get partnerPregnantControl(): FormControl {
    return this.prescriptionInfoForm?.get('partner.pregnant') as FormControl;
  }

  /**
   * Clears the form validators for each control in the form group. If a control is
   * a form group, it clears its controls' validators recursively.
   *
   * @param formGroupPath the path of the form group in this.prescriptionInfoForm
   */
  private clearFormGroupValidators(formGroupPath: string): void {
    for (const key in (this.prescriptionInfoForm.get(formGroupPath) as FormGroup).controls) {
      const fullControlPath = `${formGroupPath}.${key}`;
      const abstractControl = this.prescriptionInfoForm.get(fullControlPath);
      if ((abstractControl as any).controls) {
        this.clearFormGroupValidators(fullControlPath);
      }

      abstractControl.clearValidators();
      abstractControl.updateValueAndValidity();
    }
  }

  /**
   * Add the validator functions to the form.
   */
  private addFormValidators(): void {
    [
      'firstName',
      'lastName',
      'gender',
      'birthday.month',
      'birthday.day',
      'birthday.year',
      'allergies',
      'prescriptions',
    ].forEach((control) => this.prescriptionInfoForm.get(`partner.${control}`).addValidators(Validators.required));

    ['firstName', 'lastName'].forEach((control) =>
      this.prescriptionInfoForm
        .get(`partner.${control}`)
        .addValidators(Validators.minLength(this.config.minimumNameLength))
    );

    this.setPartnerPregnantValidators(this.partnerGenderControl.value);
    this.partnerGenderChangesSubscription = this.listenForPartnerGenderChanges();
    this.partnerBirthdayControl.addValidators(dateValidator());
    this.prescriptionInfoForm.get('partner.symptoms').addValidators(this.formService.symptomSelectedValidator());
    this.prescriptionInfoForm.updateValueAndValidity();
  }

  /**
   * Listens for changes in partner gender control and updates partner pregnant validators accordingly.
   *
   * @returns {Subscription} a subscription to the partner gender control value changes
   */
  private listenForPartnerGenderChanges(): Subscription {
    return this.partnerGenderControl.valueChanges.subscribe((value) => this.setPartnerPregnantValidators(value));
  }

  /**
   * Sets validators for partner pregnant control based on gender.
   *
   * @param {Gender} gender the gender of the partner
   */
  private setPartnerPregnantValidators(gender: Gender): void {
    if (gender === Gender.Female) {
      this.partnerPregnantControl.addValidators(Validators.required);
    } else {
      this.partnerPregnantControl.clearValidators();
    }

    this.partnerPregnantControl.updateValueAndValidity();
  }

  /**
   * Listen for changes in the treatment value to determine whether to add or clear the
   * validators.
   *
   * @returns a Subscription to changes in the form's treatment value
   */
  private listenForTreatmentChanges(): Subscription {
    return this.prescriptionInfoForm.get('treatment').valueChanges.subscribe((value) => {
      if (value === Treatment.Self) {
        this.clearFormGroupValidators('partner');
        this.partnerGenderChangesSubscription?.unsubscribe();
        this.partnerGenderChangesSubscription = undefined;

        return;
      }

      this.addFormValidators();
    });
  }
}
