<app-card
  title="Enter Payment Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <app-consultation-order-summary
    [consultationRequestPaymentForm]="consultationRequestPaymentForm"
    [enableDiscountInput]="freeEnabled"
  ></app-consultation-order-summary>
  <app-payment-info
    #paymentInfo
    [paymentForm]="paymentForm"
    [disabledPaymentMethods]="disabledPaymentMethods"
    [recharge]="consultationRequestService.consultationOrderDetail?.recharge"
  ></app-payment-info>
</app-card>
