import { Component } from '@angular/core';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-be-prepared-for-the-unexpected',
  templateUrl: './be-prepared-for-the-unexpected.component.html',
  styleUrls: ['./be-prepared-for-the-unexpected.component.scss'],
  standalone: true,
  imports: [UpsellCardComponent],
})
export class BePreparedForTheUnexpectedComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.Levonorgestrel;

  constructor() {}
}
