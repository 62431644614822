<app-card
  title="Order Summary"
  headerClasses="is-size-6 p-4 card-header-title has-text-weight-normal"
  contentClasses="p-4 card-body-background"
  data-qa-id="Order Summary"
>
  <strong>{{ testsOrdered?.length > 1 ? orderedTestWording.plural : orderedTestWording.single }}:</strong>
  <ul class="mb-4 tests-ordered-list">
    <li *ngFor="let test of testsOrdered">{{ test }}</li>
  </ul>
  <p *ngIf="paymentIds?.length > 0" class="mb-4" data-qa-id="Order Summary Transactions">
    <strong>Transaction {{ paymentIds.length > 1 ? 'IDs' : 'ID' }}:</strong>
    {{ paymentIds.join(', ') }}
  </p>
  <p *ngIf="paymentIds?.length < 1" class="mb-4"><strong>Order ID:</strong> {{ orderId }}</p>
  <p id="order-complete-total"><strong>Total:</strong> {{ orderTotal | centsCurrency }}</p>
</app-card>
