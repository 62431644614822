<form [formGroup]="form" [attr.data-qa-id]="yesNoControlName + ' Form'">
  <div class="columns">
    <div class="column">
      <label class="label has-text-weight-normal">{{ yesNoLabel }}</label>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            [name]="yesNoControlName"
            [value]="questionnaireAnswers.Yes"
            [formControlName]="yesNoControlName"
            [attr.data-qa-id]="yesNoControlName + ' ' + questionnaireAnswers.Yes"
          />
          Yes
        </label>
        <label class="radio">
          <input
            type="radio"
            [name]="yesNoControlName"
            [value]="questionnaireAnswers.No"
            [formControlName]="yesNoControlName"
            [attr.data-qa-id]="yesNoControlName + ' ' + questionnaireAnswers.No"
          />
          No
        </label>
        <div *ngIf="hintText" class="hint" [attr.data-qa-id]="yesNoControlName + ' Hint'">
          {{ hintText }}
        </div>
        <div
          *ngIf="isFormControlInvalid(yesNoControlName)"
          class="error"
          [attr.data-qa-id]="yesNoControlName + ' Error'"
        >
          Please select one of these options
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="form?.get(yesNoControlName).value === questionnaireAnswers.Yes" class="columns">
    <div class="column">
      <div class="control">
        <textarea
          class="input details-text-area"
          [placeholder]="placeholder"
          [formControlName]="detailsControlName"
          [attr.data-qa-id]="detailsControlName + ' Input'"
        >
        </textarea>
        <div
          *ngIf="isFormControlInvalid(detailsControlName)"
          class="error"
          [attr.data-qa-id]="detailsControlName + ' Error'"
        >
          <span *ngIf="form.get(detailsControlName).errors?.required">Please provide details</span>
          <span *ngIf="form.get(detailsControlName).errors?.maxlength"
            >You have exceeded the maximum number of characters</span
          >
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isWarningInducingOptionSelected && warningMessage" class="columns">
    <div class="column">
      <div class="control">
        <div class="error mt-0" [attr.data-qa-id]="yesNoControlName + ' Disqualified Error'">
          {{ warningMessage }}
        </div>
      </div>
    </div>
  </div>
</form>
