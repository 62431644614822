<app-icon-and-button-card cardTitle="Your order is confirmed!" imagePath="assets/img/check-pink.svg">
  <p class="mt-4" data-qa-id="Patient First Name" *ngIf="patientName">{{ patientName | titlecase }},</p>
  <p class="mt-4">
    Thank you for filling out the Medical Questionnaire. Our Physicians will review your responses and contact you with
    your prescription via email ASAP.
  </p>
  <p class="mt-4" *ngIf="transactionId" data-qa-id="Consultation Order Number">
    Your order number is: <strong>{{ transactionId }}</strong
    >.
  </p>

  <div class="has-text-centered mt-4" data-qa-id="Payments History Spinner" *ngIf="payments === null">
    <em class="fa fa-spinner fa-spin"></em>
  </div>
  <section
    class="transaction-history-summary"
    *ngIf="payments && payments.length"
    data-qa-id="Transaction History Summary"
  >
    <p class="mt-5">Here's the transaction summary for your order:</p>
    <div class="tabs mt-3 mb-2">
      <ul class="is-justify-content-center">
        <li class="is-active"><a>Order summary</a></li>
      </ul>
    </div>

    <div class="table-container">
      <table class="table is-fullwidth">
        <tbody>
          <tr *ngFor="let payment of payments">
            <td>{{ payment.processed_at | date: 'MM/dd/yy' }}</td>
            <td class="payment-detail-row">{{ payment.detail }}</td>
            <td>{{ payment.amount / 100 | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <div button-container class="is-flex is-justify-content-center is-flex-direction-column-mobile mt-6">
    <button
      [disabled]="loadingButton"
      (click)="downloadInvoice()"
      *ngIf="payments && payments.length"
      class="card-button button is-rounded is-primary is-primary-outlined mr-2"
      data-qa-id="Secondary Card Button"
    >
      <span *ngIf="!loadingButton">Download Invoice</span>
      <span *ngIf="loadingButton"><em class="fa fa-spinner fa-spin"></em></span>
    </button>
    <button (click)="redirect()" class="card-button button is-rounded is-primary" data-qa-id="Card Button">
      Back to Home
    </button>
  </div>
</app-icon-and-button-card>
