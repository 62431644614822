<div class="main container">
  <app-card
    class="my-4 mycoplasma-genitalium-content"
    data-qa-id="Main Content Mycoplasma Genitalium"
    contentClasses="p-4"
    headerClasses="is-hidden"
    *ngIf="mycoplasmaGenitaliumResponse"
  >
    <div class="columns">
      <div class="column has-text-centered">
        <h3 class="is-size-3 my-4 has-text-weight-semibold has-text-black">
          Add <span class="has-text-orange">Mycoplasma Genitalium</span> Testing to Your Panel
        </h3>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="columns is-multiline">
          <div class="column has-text-centered">
            <img
              class="mycoplasma-genitalium-img"
              *ngIf="
                mycoplasmaGenitaliumResponse.image_url
                  | imageFallback: 'assets/img/mycoplasma-genitalium.png'
                  | async as imageUrl
              "
              [ngSrc]="imageUrl"
              width="352"
              height="347"
            />
          </div>
        </div>
      </div>
      <div class="column px-5 is-6">
        <div class="columns is-multiline is-mobile">
          <ng-container *ngFor="let content of mycoplasmaGenitaliumResponse.content.title_content; let i = index">
            <ng-container *ngIf="i === 0">
              <div class="column is-12">
                <p>{{ content.content }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="i !== 0">
              <div class="column is-12">
                <h3 class="is-size-5 mb-3 has-text-weight-bold has-text-black">{{ content.title }}</h3>
                <p>{{ content.content }}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 has-text-centered py-5" *ngIf="!shouldExpand">
        <a
          class="is-clickable has-text-info has-text-weight-semibold"
          data-qa-id="Read More Mycoplasma Genitalium"
          (click)="toggleContent()"
        >
          Read More
        </a>
      </div>
    </div>

    <div class="columns is-multiline" data-qa-id="FAQ Mycoplasma Genitalium" *ngIf="shouldExpand">
      <div class="column is-12 mb-0 pb-0">
        <hr />
      </div>
      <div class="column is-12 has-text-centered">
        <h3 class="is-size-4 has-text-weight-bold has-text-black">
          {{ mycoplasmaGenitaliumResponse.faq_content.title }}
        </h3>
      </div>
      <div class="column is-offset-2 is-8">
        <div
          class="card is-clickable mt-3"
          (click)="toggleFAQ(faq)"
          [attr.data-qa-id]="'FAQ Question MycoplasmaGenitalium ' + i"
          [ngClass]="faq.isExpanded ? 'is-active' : ''"
          *ngFor="let faq of mycoplasmaGenitaliumResponse.faq_content.faqs; let i = index"
        >
          <header class="card-header has-text-weight-semibold">{{ faq.question }}</header>
          <div class="card-content" [attr.data-qa-id]="'FAQ Answer MycoplasmaGenitalium ' + i" *ngIf="faq.isExpanded">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
        <div class="column mt-2 is-12 has-text-centered" *ngIf="shouldExpand">
          <a
            class="is-clickable has-text-info has-text-weight-semibold"
            data-qa-id="Read Less Mycoplasma Genitalium"
            (click)="toggleContent()"
          >
            Read Less
          </a>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-4" *ngIf="mycoplasmaGenitaliumResponse">
    <app-card class="mt-4" contentClasses="p-4 mb-4" headerClasses="is-hidden">
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12">
          <h4 class="is-size-4 my-4 has-text-weight-semibold has-text-black">
            {{ mycoplasmaGenitaliumResponse?.cta }}
          </h4>
        </div>
        <div class="column is-offset-2 is-4">
          <button
            (click)="continueOrderingWorkflow()"
            class="button is-fullwidth has-text-weight-semibold same-width-btn"
            data-qa-id="Deny MycoplasmaGenitalium"
            [disabled]="processing"
          >
            No, thanks
          </button>
        </div>
        <div class="column is-4">
          <button
            class="button is-fullwidth has-text-white has-text-weight-semibold is-orange same-width-btn mycoplasma-add-to-order-button"
            data-qa-id="Recharge MycoplasmaGenitalium"
            (click)="submit()"
            [disabled]="processing || rechargeAttempt"
          >
            Add this test for {{ mycoplasmaGenitaliumPrice | currency: 'USD':'symbol-narrow':'1.0-0' }}
          </button>
        </div>
      </div>
    </app-card>
  </div>

  <div class="mt-4" *ngIf="showPaymentInformation">
    <app-upsell-payment-info
      class="is-block mt-4"
      [upsellTestsForm]="mycoplasmaGenitaliumForm"
      [selectedUpsell]="[mycoplasmaGenitaliumTest]"
      [enablePayLater]="false"
    ></app-upsell-payment-info>
    <app-place-order
      #placeOrder
      class="is-block mt-4 mb-5"
      [chargeAmount]="mycoplasmaGenitaliumPrice"
      [hideWhatHappensNext]="true"
      [orderForm]="mycoplasmaGenitaliumForm"
      [formGroupsToBeValidated]="[]"
      [placeOrderButtonText]="'Finish Placing My Order'"
      (formSubmitted)="submit()"
    ></app-place-order>
    <app-error-notification
      title="Error:"
      classes="has-text-black mb-5"
      *ngIf="submissionErrors.length > 0"
      data-qa-id="submission errors"
    >
      <div *ngFor="let errorMessage of submissionErrors">
        <p [innerHTML]="errorMessage"></p>
      </div>
    </app-error-notification>
  </div>
</div>
