<p *ngIf="shouldDisplayUrineTestUpsellDescription" class="my-4" data-qa-id="Follow-Up Urine Test Upsell Description">
  After completing your course of antibiotics, our physicians highly recommend a urine culture test to ensure the
  bacteria causing your UTI has been fully eradicated.
  <strong>
    Failing to take this test may result in the recurrence of the UTI, potentially in an antibiotic-resistant form.
  </strong>
  We have partnered with thousands of
  <a data-qa-id="Lab Finder Trigger" (click)="this.showLabFinderModal.emit()"> labs across the country</a>
  that can conduct this test and provide results within 12-24 hours.
</p>

<p class="my-4" *ngIf="shouldDisplayPartnerTreatmentTestUpsellDescription">
  <strong>It's essential to ensure your partner is treated, too</strong>. If you've recently had sexual contact with
  someone, untreated STDs can often be passed back and forth, leading to re-infection. To help you both stay healthy and
  prevent further transmission, we recommend providing treatment for your partner as well.
  <strong
    >This option will notify your partner anonymously and confidentially, providing them with a free order for
    treatment.</strong
  >
</p>

<p class="my-4" *ngIf="shouldDisplayMetforminERTestUpsellDescription">
  Elevate your anti-aging regimen with <strong>Metformin Extended Release (ER)</strong>, the advanced formula designed
  <strong>to reduce common side effects such as nausea, vomiting, bloating, and diarrhea.</strong> By releasing the
  medication gradually throughout the day, Metformin ER supports consistent blood sugar levels while being gentler on
  your digestive system. With once-daily dosing, it's a convenient and effective way to enhance the longevity and
  wellness benefits of metformin.
</p>

<p class="my-4" *ngIf="shouldDisplayHsvBloodTestUpsellDescription">
  We highly recommend getting tested for HSV-1 and HSV-2 to confirm your diagnosis, ensure that the antiviral medication
  prescribed is appropriate, and <strong>confirm the presence of HSV-1 and/or HSV-2</strong>. We have partnered with
  thousands of
  <a data-qa-id="Lab Finder Trigger" (click)="this.showLabFinderModal.emit()">labs across the country</a> that can
  conduct this test and provide results within 12-24 hours.
</p>

<p class="my-4" *ngIf="shouldDisplayWellnessPanelDescription">
  <strong>We highly recommend adding a wellness panel to your GLP-1 treatment plan.</strong> This comprehensive
  <strong>urine and blood test</strong> evaluates important health markers like kidney and liver function, cholesterol,
  blood sugar, and thyroid levels—ensuring your treatment is safe and effective. We have partnered with thousands of
  <a data-qa-id="Lab Finder Trigger" (click)="this.showLabFinderModal.emit()">labs across the country</a> that can
  conduct this test and provide results within 12-24 hours.
</p>
