import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CardComponent } from '@components/card/card.component';
import { BaseDynamicConsultationRequestFormComponent } from '@components/consultation-request/base-dynamic-consultation-request-form/base-dynamic-consultation-request-form.component';
import { ChooseAPharmacyComponent } from '@components/consultation-request/choose-a-pharmacy/choose-a-pharmacy.component';
import { ConsultationRequestAddressComponent } from '@components/consultation-request/consultation-request-address/consultation-request-address.component';
import { ConsultationRequestPatientInfoComponent } from '@components/consultation-request/consultation-request-patient-info/consultation-request-patient-info.component';
import { LabFinderModalComponent } from '@components/consultation-request/lab-finder-modal/lab-finder-modal.component';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { ConsultationRequestHeaderComponent } from '@components/consultation-request-header/consultation-request-header.component';
import { ConsultationRequestTreatmentPreferencesCardComponent } from '@components/consultation-request-treatment-preferences-card/consultation-request-treatment-preferences-card.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ErrorNotificationComponent } from '@components/error-notification/error-notification.component';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ErrorLoggingService } from '@services/error-logging.service';
import { FormService } from '@services/form.service';
import { GenderService } from '@services/gender.service';
import { LoadingService } from '@services/loading.service';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-consultation-request-form',
  templateUrl: 'dynamic-consultation-request-form.component.html',
  styleUrls: ['dynamic-consultation-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    ErrorNotificationComponent,
    ConsultationRequestHeaderComponent,
    ConsultationRequestPatientInfoComponent,
    ChooseAPharmacyComponent,
    ConsultationRequestTreatmentPreferencesCardComponent,
    CardComponent,
    DynamicQuestionnaireFormComponent,
    MedicalHistoryQuestionsComponent,
    LabFinderModalComponent,
    ConsultationRequestAddressComponent,
  ],
})
export class DynamicConsultationRequestFormComponent
  extends BaseDynamicConsultationRequestFormComponent
  implements AfterViewInit, OnDestroy
{
  pharmacyState: string | null = null;

  private subscriptions: Subscription[] = [];

  constructor(
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected consultationRequestService: ConsultationRequestService,
    protected formService: FormService,
    protected storageService: StorageService,
    protected sessionStorageService: SessionStorageService,
    protected title: Title,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandlerService: ErrorHandlerService,
    protected navigationService: NavigationService,
    protected domainService: DomainService,
    protected genderService: GenderService,
    protected errorLoggingService: ErrorLoggingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      loadingService,
      activatedRoute,
      config,
      consultationRequestService,
      formService,
      storageService,
      sessionStorageService,
      title,
      dynamicFormsService,
      errorHandlerService,
      navigationService,
      domainService,
      genderService,
      errorLoggingService
    );
  }

  ngAfterViewInit(): void {
    this.initializePharmacyStateLogic();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the pharmacy state tracking logic.
   */
  private initializePharmacyStateLogic(): void {
    this.pharmacyState = this.pharmacyForm?.value.state;
    this.subscriptions.push(
      this.pharmacyForm?.get('state')?.valueChanges.subscribe((state) => {
        this.pharmacyState = state;
        this.changeDetectorRef.detectChanges();
      })
    );
  }
}
