import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@services/form.service';
import { NgxMaskDirective } from 'ngx-mask';
import { PhoneValidator } from 'src/app/validators/phone-validator';

@Component({
  selector: 'app-order-phone-inputs',
  templateUrl: './order-phone-inputs.component.html',
  styleUrls: ['./order-phone-inputs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgxMaskDirective, NgIf],
})
export class OrderPhoneInputsComponent implements OnInit {
  orderPhoneForm: FormGroup;

  constructor(private formService: FormService, private http: HttpClient) {}

  ngOnInit(): void {
    this.orderPhoneForm = this.formService.addressContactInformation.get('phone') as FormGroup;
    this.orderPhoneForm.get('number').addAsyncValidators(PhoneValidator.createValidator(this.http));
  }

  /**
   * Determines if a form field is invalid.
   *
   * @param field string The name of the field to check vor validity.
   */
  isInvalid(field: string = null): boolean {
    let input = field ? this.orderPhoneForm.get(field) : this.orderPhoneForm;

    return input.invalid && input.touched;
  }

  /**
   * Determines if a form field is failing a specific validation.
   *
   * @param errorName name of the validation that could be giving error
   * @param field the name of the field to check for specific validation error
   *
   * @returns true if the field has the specific error, false otherwise
   */
  hasSpecificError(errorName: string, field: string): boolean {
    const input = this.orderPhoneForm.get(field);

    return !!input?.errors?.[errorName];
  }
}
