<ng-template #payButton>
  <ng-template #buttonText>
    <span>{{ placeOrderButtonText }}</span>
    <span class="icon">
      <i [ngClass]="processing ? 'fa-spinner fa-pulse' : 'fa-arrow-right'" class="fa" aria-hidden="true"></i>
    </span>
  </ng-template>
  <app-error-notification
    id="reviewFormAlert"
    title="Incomplete:"
    classes="has-text-centered mb-5"
    *ngIf="requiredFieldsError"
  >
    {{ getErrorMessage() }}
  </app-error-notification>
  <app-error-notification
    title="Error:"
    classes="has-text-black mb-5"
    *ngIf="submissionErrors.length > 0"
    data-qa-id="submission errors"
  >
    <div *ngFor="let errorMessage of submissionErrors">
      <p [innerHTML]="errorMessage"></p>
    </div>
  </app-error-notification>
  <app-error-notification
    id="giftCardMissing"
    title="Gift Card Missing:"
    classes="has-text-centered mb-5"
    *ngIf="requiredGiftCardError"
  >
    You must apply the gift card to the cart before placing the order.
  </app-error-notification>
  <app-error-notification id="zipCodeFormAlert" classes="has-text-centered mb-5" *ngIf="isBillingCountryOther">
    To place an order and pay using a credit card issued in a country outside of the United States, Canada, or the
    United Kingdom, please call our customer service team at <strong>{{ sitePhone }}</strong
    >. Alternatively, you can place your order online by selecting Apple Pay, Google Pay or PayPal as your payment
    method.
  </app-error-notification>
  <div
    class="place-order-button-container is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
    *ngIf="showCheckoutButton"
    data-qa-id="Place Order Button Container"
  >
    <div
      class="trustedsite-trustmark trustedsite-secure-checkout-badge"
      data-type="212"
      data-width="120"
      data-height="50"
      *ngIf="displayTrustBadge"
    ></div>
    <button
      type="button"
      data-qa-id="Submit"
      class="button is-large is-fullwidth has-text-white has-background-orange action button-place-your-order"
      (click)="submit()"
      [disabled]="processing || isPlaceOrderEnable || isBillingCountryOther"
    >
      <ng-container *ngTemplateOutlet="buttonText"></ng-container>
    </button>
  </div>
  <app-paypal-button
    *ngIf="shouldShowPaymentMethodButton(PaymentTypes.Paypal)"
    [processing]="processing"
    (buttonClick)="payWithPaymentService(PaymentTypes.Paypal)"
  >
  </app-paypal-button>
  <app-bitpay-button
    *ngIf="shouldShowPaymentMethodButton(PaymentTypes.BitPay)"
    [processing]="processing"
    (buttonClick)="payWithPaymentService(PaymentTypes.BitPay)"
  ></app-bitpay-button>
  <app-apple-pay-button
    *ngIf="shouldShowPaymentMethodButton(PaymentTypes.ApplePay)"
    [processing]="processing"
    (buttonClick)="payWithPaymentService(PaymentTypes.ApplePay)"
  >
  </app-apple-pay-button>
  <app-google-pay-button
    *ngIf="shouldShowPaymentMethodButton(PaymentTypes.GooglePay)"
    [processing]="processing"
    (buttonClick)="payWithPaymentService(PaymentTypes.GooglePay)"
  >
  </app-google-pay-button>
  <app-venmo-button
    *ngIf="shouldShowPaymentMethodButton(PaymentTypes.Venmo)"
    [(processing)]="processing"
    (buttonClick)="payWithPaymentService(PaymentTypes.Venmo)"
  >
  </app-venmo-button>
</ng-template>

<div>
  <app-card *ngIf="domainService.isSTDCheckDomain()" contentClasses="p-4" headerClasses="is-hidden">
    <ng-container *ngTemplateOutlet="payButton"></ng-container>
  </app-card>
  <div *ngIf="!domainService.isSTDCheckDomain()">
    <ng-container *ngTemplateOutlet="payButton"></ng-container>
  </div>
  <ng-container *ngIf="!shouldSelectPharmacy && !hideWhatHappensNext">
    <div class="mb-4"></div>
    <app-card class="mt-4 only-stdcheck" contentClasses="p-4" headerClasses="is-hidden">
      <strong class="is-size-4 mb-1 is-block">What happens after you place your order?</strong>
      <ul>
        <li>Access your <strong>lab requisition form and your test code</strong> in your online account.</li>
        <li>
          The <strong>address of the test center</strong> you choose will also
          <strong>be visible in your account.</strong>
        </li>
        <li>Take your <strong>lab requisition form and your test code</strong> to the testing center.</li>
        <li><strong>Get tested.</strong> STD tests usually take 5 minutes or less.</li>
      </ul>
    </app-card>
    <app-card class="mt-4 only-healthlabs what-happens-next" contentClasses="p-5 is-relative" headerClasses="is-hidden">
      <strong class="is-size-4 mb-1 is-block has-text-weight-ultrabold has-text-dark"
        >What happens after you click “Place My Order”?</strong
      >
      <ul class="has-text-dark has-text-weight-semibold">
        <li>
          You'll receive an email containing a "Lab Requisition Form" with a test code. The address of the test center
          you chose is also included in the email.
        </li>
        <li>Take your Lab Requisition Form or your test code to the testing center.</li>
        <li>Get Tested. HealthLabs.com’s tests usually take 10 minutes or less.</li>
      </ul>
      <div class="mcafee-container">
        <img
          class="mcafee-logo"
          ngSrc="assets/img/logos/mcafee-logo.png"
          alt="McAfee Logo"
          width="160"
          height="75"
          priority
        />
      </div>
    </app-card>
  </ng-container>
</div>
