<app-card
  title="Additional Recommended Testing"
  headerClasses="p-2 is-size-3 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-0 card-body-background"
>
  <form [formGroup]="upsellTestsForm">
    <label
      formArrayName="tests"
      *ngFor="let upsellTest of upsellTests"
      [ngClass]="{
        'upsell-label-hover': !isAnyTestSelected(),
        'upsell-label-faded': !isTestSelected(upsellTest.slug) && isAnyTestSelected(),
        'upsell-label-selected': isTestSelected(upsellTest.slug)
      }"
      class="is-block p-4 upsell-label"
    >
      <div class="is-block mb-2">
        <input
          type="checkbox"
          class="is-hidden"
          [formControlName]="upsellTest.slug"
          (change)="onCheckboxChange($event, upsellTest)"
        />
        <span
          [ngClass]="{
            'has-text-success': isTestSelected(upsellTest.slug)
          }"
          class="upsell-test-name"
        >
          <i [ngClass]="!isTestSelected(upsellTest.slug) ? 'far fa-square' : 'fa fa-check-square'"> </i>
          Add a <span data-qa-id="Upsell Test Name">{{ upsellTest.name }}</span>
          {{
            upsellTest.name?.toLowerCase().includes('test') || upsellTest.name?.toLowerCase().includes('panel')
              ? ''
              : 'Test'
          }}
        </span>
      </div>
      <div class="is-block is-clipped">
        <div
          [ngClass]="{
            'has-text-success': isTestSelected(upsellTest.slug)
          }"
          class="upsell-price has-text-centered ml-4 py-4 px-2"
        >
          <span *ngIf="upsellTest.price && upsellTest.upsell_price < upsellTest.price" class="is-block old-price mb-2">
            {{ upsellTest.price | centsCurrency: false }}
          </span>
          {{ upsellTest.upsell_price | centsCurrency }}
        </div>
        <div
          class="upsell-test-description"
          [ngClass]="{ expanded: isExpanded(upsellTest.slug) }"
          [innerHTML]="upsellTest.description"
        ></div>
      </div>
      <div *ngIf="!isExpanded(upsellTest.slug)" class="upsell-read-more" (click)="expandUpsellCard($event, upsellTest)">
        Read More
      </div>
      <span
        class="is-block is-size-5 is-size-6-mobile recommended-upsell-test mt-3 mb-2"
        *ngIf="upsellTest.recommended"
      >
        Most of our patients choose this option.
      </span>
    </label>
  </form>
</app-card>
