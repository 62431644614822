<ng-container *ngIf="inHomeResult; else centerDetail">
  <div class="p-3" data-qa-id="selected-lab">
    <h1 class="has-text-weight-bold">Selected Address</h1>
    <p>
      <i class="fa fa-map-marker"></i>
      {{ inHomeResult.address.street }}, {{ inHomeResult.address.city }}, {{ inHomeResult.address.state }}
      {{ inHomeResult.address.zipCode }}
    </p>
    <div class="p-2"></div>
    <div class="columns">
      <div class="column">
        <p class="has-text-weight-bold">Selected Date</p>
        <p>{{ bookingDate }}</p>
      </div>
      <div class="column">
        <p class="has-text-weight-bold">Selected Time</p>
        <p>{{ inHomeResult.bookingSlot.period }}</p>
      </div>
    </div>
    <button class="button is-primary mt-2" (click)="changeCenter()" data-qa-id="change-psc">Change</button>
  </div>
</ng-container>
<ng-template #centerDetail>
  <div class="columns p-0 m-0" data-qa-id="selected-lab">
    <div class="column has-text-weight-bold">Your Selected {{ label }}:</div>
    <div class="column" [ngClass]="{ 'is-two-thirds': !displayChangeLocationBtn || !center }">
      <ng-container *ngIf="center; else noLocation">
        <p>{{ center.title }}</p>
        <p *ngIf="center.phone">{{ formatPhoneNumber(center.phone) }}</p>
        <p>{{ center.address }} {{ center.address2 }}</p>
        <p>{{ center.city }}, {{ center.state }} {{ center.zip_code }}</p>
      </ng-container>

      <ng-template #noLocation>
        <p>No location has been set.</p>
      </ng-template>
    </div>
    <div *ngIf="displayChangeLocationBtn && center" class="column has-text-right change-location-button-column">
      <button class="button is-primary is-fullwidth-mobile" (click)="changeCenter()" data-qa-id="change-psc">
        {{ changeButtonText }}
      </button>
    </div>
  </div>
</ng-template>
