<app-upsell-card
  htmlTitle="<strong>Important: Be Prepared</strong> for the Unexpected"
  question="Would you like to add a single dose of Levonorgestrel 1.5mg to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    Unprotected sex or contraceptive failures, such as a broken condom or missed day of oral birth control, can happen
    unexpectedly. Being prepared in advance can make all the difference in preventing an unplanned pregnancy.
  </p>
  <p class="mb-4">
    <strong>Levonorgestrel</strong>, the trusted <strong>"day after pill"</strong> is a generic form of the active
    ingredient in <strong>Plan B&#174;</strong>, works effectively by preventing pregnancy before it begins, and can be
    taken up to 3 days following unprotected sex or contraceptive failure.
  </p>
  <p class="mb-4">
    Having a dose of <strong>Levonorgestrel 1.5mg</strong> on hand ensures you're prepared for any unexpected
    situations. Pick up today, ensuring the medication is conveniently available in your medicine cabinet when you need
    it most.
  </p>
</app-upsell-card>
