<header>
  <div
    class="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center treatmyuti-header"
  >
    <img
      *ngIf="restrictedNavigation"
      class="logo-img"
      ngSrc="assets/img/treatmyuti-logo.svg"
      alt="TreatMyUTI.com Logo"
      data-qa-id="non-clickable-logo"
      width="235"
      height="45"
      priority
    />
    <a *ngIf="!restrictedNavigation" class="is-flex" href="https://www.treatmyuti.com/" data-qa-id="clickable-logo">
      <img
        class="logo-img"
        ngSrc="assets/img/treatmyuti-logo.svg"
        alt="TreatMyUTI.com Logo"
        width="235"
        height="45"
        priority
      />
    </a>
    <div class="is-flex is-flex-direction-row is-flex-wrap-nowrap is-align-items-center phone-number-container">
      <img ngSrc="assets/img/phone-black.svg" alt="Phone Icon" width="18" height="19" />
      <a class="phone-number" href="tel:+{{ config.phone }}">{{ config.phone }}</a>
    </div>
  </div>
</header>
<div class="main-content">
  <ng-content></ng-content>
</div>
<footer>
  <div class="treatmyuti-footer">
    <div class="is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-space-between is-align-items-center">
      <img
        class="logo-img"
        ngSrc="assets/img/treatmyuti-logo-pink.svg"
        alt="TreatMyUTI.com Logo"
        width="405"
        height="45"
        priority
      />
      <div>
        <div>Copyright &copy; {{ today | date: 'y' }} TreatMyUTI.com</div>
        <div>
          Made with <img ngSrc="assets/img/ah_heart.svg" alt="Heart Icon" width="17" height="16" /> by the people at
          Analyte Health
        </div>
      </div>
    </div>
  </div>
</footer>
