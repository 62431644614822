<div class="main container">
  <app-card
    class="my-4 doxypep-content"
    data-qa-id="Main Content DoxyPEP"
    contentClasses="p-4"
    headerClasses="is-hidden"
    *ngIf="doxyPepContent"
  >
    <div class="columns">
      <div class="column has-text-centered">
        <h3 class="is-size-3 my-4 has-text-weight-semibold has-text-black">
          Have you had <span class="has-text-info">unprotected sex</span> in the last three days?
        </h3>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="columns is-multiline">
          <div class="column has-text-centered">
            <img
              class="doxypep-img"
              [src]="doxyPepContent.image_url | imageFallback: 'assets/img/doxy-pep.png' | async"
            />
          </div>
        </div>
        <div class="column is-12" *ngIf="shouldExpand">
          <h3 class="is-size-5 mb-3 has-text-weight-bold has-text-info">
            {{ doxyPepContent.content.medication_title }}
          </h3>
          <ul>
            <li *ngFor="let item of doxyPepContent.content.medication_details">{{ item.detail }}</li>
          </ul>
        </div>
      </div>

      <div class="column px-5 is-6">
        <div class="columns is-multiline is-mobile">
          <ng-container *ngFor="let content of doxyPepContent.content.title_content; let i = index">
            <ng-container *ngIf="i === 0">
              <div class="column is-6-mobile">
                <h3 class="is-size-3 has-text-weight-bold has-text-black">{{ content.title }}</h3>
              </div>
              <div class="column is-6 has-text-right">
                <p class="is-size-3 has-text-weight-semibold has-text-black">{{ doxyPepContent.price | currency }}</p>
              </div>
              <div class="column is-12">
                <p>{{ content.content }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="i !== 0">
              <div class="column is-12">
                <h3 class="is-size-5 mb-3 has-text-weight-bold has-text-black">{{ content.title }}</h3>
                <p [ngClass]="!shouldExpand ? 'limit-lines' : ''">
                  {{ content.content }}
                </p>
              </div>
            </ng-container>
          </ng-container>

          <div class="column is-12" data-qa-id="Medication DoxyPEP" *ngIf="shouldExpand">
            <h3 class="is-size-5 mb-3 has-text-weight-bold has-text-black">
              {{ doxyPepContent.content.indications_title }}
            </h3>
            <ul>
              <li *ngFor="let item of doxyPepContent.content.indications">{{ item.indication }}</li>
            </ul>
          </div>

          <div class="column is-12 has-text-centered" *ngIf="!shouldExpand">
            <a
              class="is-clickable has-text-info has-text-weight-semibold"
              data-qa-id="Read More DoxyPEP"
              (click)="toggleContent()"
              >Read More</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline" data-qa-id="FAQ DoxyPEP" *ngIf="shouldExpand">
      <div class="column is-12 mb-0 pb-0">
        <hr />
      </div>
      <div class="column is-12 has-text-centered">
        <h3 class="is-size-4 has-text-weight-bold has-text-black">{{ doxyPepContent.faq_content.title }}</h3>
      </div>
      <div class="column is-offset-2 is-8">
        <div
          class="card is-clickable mt-3"
          (click)="toggleFAQ(faq)"
          [attr.data-qa-id]="'FAQ Question DoxyPEP ' + i"
          [ngClass]="faq.isExpanded ? 'is-active' : ''"
          *ngFor="let faq of doxyPepContent.faq_content.faqs; let i = index"
        >
          <header class="card-header has-text-weight-semibold">{{ faq.question }}</header>
          <div class="card-content" [attr.data-qa-id]="'FAQ Answer DoxyPEP ' + i" *ngIf="faq.isExpanded">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
        <div class="column mt-2 is-12 has-text-centered" *ngIf="shouldExpand">
          <a
            class="is-clickable has-text-info has-text-weight-semibold"
            data-qa-id="Read Less DoxyPEP"
            (click)="toggleContent()"
            >Read Less</a
          >
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-4">
    <app-card class="mt-4" contentClasses="p-4 mb-4" headerClasses="is-hidden">
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12">
          <h4 class="is-size-4 my-4 has-text-weight-semibold has-text-black">{{ doxyPepContent?.cta }}</h4>
        </div>
        <div class="column is-offset-2 is-4">
          <button
            class="button is-fullwidth has-text-weight-semibold same-width-btn"
            (click)="redirectOrderComplete()"
            data-qa-id="Deny DoxyPEP"
            [disabled]="processing"
          >
            No, thanks
          </button>
        </div>
        <div class="column is-4">
          <button
            class="button is-fullwidth has-text-white has-text-weight-semibold is-orange same-width-btn doxy-pep-add-to-order-button"
            data-qa-id="Recharge DoxyPEP"
            (click)="submit()"
            [disabled]="processing || rechargeAttempt"
          >
            Add To My Order
          </button>
        </div>
      </div>
    </app-card>
  </div>

  <div class="mt-4" *ngIf="showPaymentInformation">
    <app-upsell-payment-info
      class="is-block mt-4"
      [upsellTestsForm]="doxyPepForm"
      [selectedUpsell]="[doxyPepTest]"
      [enablePayLater]="false"
    ></app-upsell-payment-info>
    <app-place-order
      #placeOrder
      class="is-block mt-4 mb-5"
      [chargeAmount]="doxyPepPrice"
      [hideWhatHappensNext]="true"
      [orderForm]="doxyPepForm"
      [formGroupsToBeValidated]="[]"
      [placeOrderButtonText]="'Finish Placing My Order'"
      (formSubmitted)="submit()"
    ></app-place-order>
    <app-error-notification
      title="Error:"
      classes="has-text-black mb-5"
      *ngIf="submissionErrors.length > 0"
      data-qa-id="submission errors"
    >
      <div *ngFor="let errorMessage of submissionErrors">
        <p [innerHTML]="errorMessage"></p>
      </div>
    </app-error-notification>
  </div>
</div>
