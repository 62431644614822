<app-upsell-card
  htmlTitle="<strong>Boost Your Health</strong> with Vitamin D"
  question="We strongly recommend including Vitamin D your prescription. Would you like to add it to your treatment plan?"
  [slug]="slug"
>
  <p class="mb-4">
    Many patients on metformin choose to add Vitamin D to their regimen to
    <strong>support energy, mood, and long-term wellness</strong>. Vitamin D is essential for strong bones, a resilient
    immune system, and youthful vitality. Don’t let a common deficiency hold you back—optimize your health and enhance
    your results today.
  </p>
</app-upsell-card>
