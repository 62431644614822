import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { LabFinderModalComponent } from '@components/consultation-request/lab-finder-modal/lab-finder-modal.component';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-follow-up-std-test',
  templateUrl: './follow-up-std-test.component.html',
  styleUrl: './follow-up-std-test.component.scss',
  standalone: true,
  imports: [UpsellCardComponent, NgIf, LabFinderModalComponent],
})
export class FollowUpStdTestComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.FollowUpStdTest;
  showLabFinderModal = false;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Gets the upsell card title.
   */
  get htmlTitle(): string {
    const title =
      this.sessionStorageService.treatmentType === ConsultationTreatmentTypes.StdPrevention
        ? 'Follow-Up STI Test'
        : `${this.sessionStorageService.getTreatmentPreferenceName()} Follow-Up Urine Test`;

    return `<strong>Important:</strong> ${title}`;
  }

  /**
   * Toggle the find a lab modal.
   */
  toggleLabFinderModal() {
    this.showLabFinderModal = !this.showLabFinderModal;
  }
}
