import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CardComponent } from '@components/card/card.component';
import { ConsultationOrderSummaryComponent } from '@components/consultation-request/consultation-request-payment-info/consultation-order-summary/consultation-order-summary.component';
import { PaymentInfoComponent } from '@components/payment-info/payment-info.component';
import { PaymentTabsCardComponent } from '@components/payment-tabs-card/payment-tabs-card.component';
import { PaymentTypes } from '@enums/payment-types';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-consultation-request-payment-info',
  templateUrl: './consultation-request-payment-info.component.html',
  styleUrls: ['./consultation-request-payment-info.component.scss'],
  standalone: true,
  imports: [CardComponent, ConsultationOrderSummaryComponent, PaymentInfoComponent],
})
export class ConsultationRequestPaymentInfoComponent extends PaymentTabsCardComponent implements OnInit {
  @Input() freeEnabled: boolean = false;

  consultationRequestPaymentForm: FormGroup;

  constructor(
    public consultationRequestService: ConsultationRequestService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    private formService: FormService
  ) {
    super(config, storageService);
  }

  /**
   * Listen for query params changes and initialize form.
   */
  ngOnInit(): void {
    this.consultationRequestPaymentForm = this.formService.consultationRequest.get('payment') as FormGroup;
  }

  /** @inheritdoc */
  get disabledPaymentMethods(): PaymentTypes[] {
    return [PaymentTypes.GiftCard, PaymentTypes.BitPay, ...(!this.freeEnabled ? [PaymentTypes.Free] : [])];
  }

  /** @inheritdoc */
  get paymentForm(): FormGroup {
    return this.consultationRequestPaymentForm?.get('payment') as FormGroup;
  }
}
