<app-card
  title="Patient Information"
  headerClasses="p-4 has-text-weight-bold is-size-4-mobile card-header-title"
  contentClasses="p-4"
>
  <form [formGroup]="patientInfoForm">
    <div class="columns is-multiline is-mobile">
      <div class="column is-6-desktop is-12-mobile">
        <div class="field">
          <label class="label has-text-weight-normal">Legal First Name:</label>
          <div class="control" data-qa-id="Patient First Name">
            <input class="input" type="text" formControlName="first_name" data-qa-id="Patient First Name Input" />
          </div>
          <div *ngIf="isControlInvalid('first_name')" class="error" data-qa-id="Legal First Name Errors">
            <div *ngIf="patientInfoForm.get('first_name')?.errors?.required">The Legal First Name is required</div>
            <div *ngIf="patientInfoForm.get('first_name')?.errors?.minlength">
              The Legal First Name should be {{ minimumNameLength }} digits
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6-desktop is-12-mobile">
        <div class="field">
          <label class="label has-text-weight-normal">Legal Last Name:</label>
          <div class="control" data-qa-id="Patient Last Name">
            <input class="input" type="text" formControlName="last_name" data-qa-id="Patient Last Name Input" />
          </div>
          <div *ngIf="isControlInvalid('last_name')" class="error" data-qa-id="Legal Last Name Errors">
            <div *ngIf="patientInfoForm.get('last_name')?.errors?.required">The Legal Last Name is required</div>
            <div *ngIf="patientInfoForm.get('last_name')?.errors?.minlength">
              The Legal Last Name should be {{ minimumNameLength }} digits
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12 pt-0">
        <div class="hint" data-qa-id="Patient First And Last Name Hint">
          Please make sure this is the first and last name of the patient who is seeking treatment.
        </div>
      </div>
    </div>
    <div class="columns is-multiline is-mobile" formGroupName="birthday">
      <div class="column is-6-tablet is-12-mobile">
        <div class="field">
          <label class="label has-text-weight-normal">Date of Birth:</label>
          <div class="control">
            <div class="select is-fullwidth mt-0">
              <select formControlName="month" class="select" data-qa-id="Patient Birth Month">
                <option [value]="null" selected disabled>Month</option>
                <option *ngFor="let month of calendarOptions?.months" [value]="month.number">
                  {{ month.number }} - {{ month.name }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="isControlInvalid('birthday.month')" class="error">Your birth month is required</div>
        </div>
      </div>
      <div class="column is-3-tablet is-6-mobile">
        <div class="field">
          <label class="label has-text-weight-norma is-hidden-mobile">&nbsp;</label>
          <div class="control">
            <div class="select is-fullwidth mt-0">
              <select formControlName="day" class="select" data-qa-id="Patient Birth Day">
                <option [value]="null" selected disabled>Day</option>
                <option *ngFor="let day of calendarOptions?.days" [value]="day">{{ day }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="isControlInvalid('birthday.day')" class="error">Your day of birth is required</div>
        </div>
      </div>
      <div class="column is-3-tablet is-6-mobile">
        <div class="field">
          <label class="label has-text-weight-normal is-hidden-mobile">&nbsp;</label>
          <div class="control">
            <div class="select is-fullwidth mt-0">
              <select formControlName="year" class="select" data-qa-id="Patient Birth Year">
                <option [value]="null" selected disabled>Year</option>
                <option *ngFor="let year of calendarOptions?.years" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="isControlInvalid('birthday.year')" class="error">Your year of birth is required</div>
        </div>
      </div>
      <div *ngIf="hasBirthdayError()" class="error invalid-date-message" data-qa-id="Date of Birth Error">
        <div *ngIf="patientInfoForm.get('birthday')?.errors?.invalidDate; else ageError">
          {{ patientInfoForm.get('birthday')?.errors?.invalidDate }}
        </div>
        <ng-template #ageError>
          <div *ngIf="!isAgeValid">We only treat patients between the ages of {{ minAge }}-{{ maxAge - 1 }}</div>
        </ng-template>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label has-text-weight-normal">Gender at Birth</label>
          <div class="control">
            <div class="select is-fullwidth mt-0">
              <select formControlName="gender" class="select" data-qa-id="Patient Gender">
                <option [value]="null" selected disabled>Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
          </div>
          <div
            *ngIf="isControlInvalid('gender') || genderInvalidError !== null"
            class="error"
            data-qa-id="Gender Error"
          >
            <span *ngIf="patientInfoForm?.get('gender').errors?.required">Your gender is required</span>
            <span *ngIf="genderInvalidError !== null">{{ genderInvalidError }}</span>
          </div>
        </div>
      </div>
    </div>
    <app-height-weight-input
      *ngIf="!isGlp1TreatmentType"
      [dob]="this.dob"
      [form]="patientInfoForm"
    ></app-height-weight-input>
  </form>
</app-card>
